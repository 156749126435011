export const GRACE_PERIOD_OPTIONS = [
  { value: 0, label: 'No Grace Period' },
  { value: 5, label: '5 Minutes' },
  { value: 15, label: '15 Minutes' },
  { value: 30, label: '30 Minutes' },
  { value: 60, label: '1 Hour' },
  { value: 300, label: '5 Hours' },
  { value: 600, label: '10 Hours' },
  { value: 1440, label: '24 Hours' }
];
