import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext.js';
import DueTimeDisplay from '../DueTimeDisplay.js';
import AddStudentsToSubmission from './AddStudentsToSubmission';

function SUnpublishedEssay({assignment, class_id}) {
    const {user} = useAuth();
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [submissionData, setSubmissionData] = useState({});
    const [feedbackItems, setFeedbackItems] = useState({});
    const [update, setUpdate] = useState(0);
    const [essayText, setEssayText] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isPolling, setIsPolling] = useState(false);
    const pollingIntervalRef = useRef(null);
    const [isGroupAdmin, setIsGroupAdmin] = useState(false);
    const [allSubmissionsData, setAllSubmissionsData] = useState([]);
    const [showSubmissionLog, setShowSubmissionLog] = useState(false);
    const [hoveredUser, setHoveredUser] = useState(null);

    const startPolling = submissionId => {
        if (isPolling) {
            return; // Prevent multiple polling intervals
        }
        setIsPolling(true);
        pollingIntervalRef.current = setInterval(() => {
            checkGradingStatus(submissionId);
        }, 5000);
    };

    const stopPolling = () => {
        setIsPolling(false);
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
        }
    };

    const checkGradingStatus = async submissionId => {
        try {
            const response = await gradeApi.get({
                path: `/api/submission/${submissionId}/grading-status/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            if (response.data.is_grading_done) {
                stopPolling();
                setMessage('Grading completed. Refreshing submission data...');
                getAssignmentSubmission();
            }
        } catch (error) {
            console.error('Error checking grading status:', error);
            stopPolling();
            setMessage('Error checking grading status. Please refresh the page.');
        }
    };

    const submitOrResubmit = async isResubmission => {
        const formData = new FormData();
        formData.append('essay_text', essayText);
        formData.append('user_id', user.user.id);
        formData.append('submitter_id', user.user.id);

        try {
            setMessage(
                isResubmission
                    ? 'Resubmission in progress... please wait'
                    : 'Submission in progress... please wait'
            );
            setIsButtonDisabled(true);

            const response = await gradeApi.post({
                path: `/api/assignment/${assignment.id}/submit-or-resubmit/`,
                body: formData,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            });

            if (response.status !== 200) {
                throw new Error('Error while submitting. Please try again.');
            }

            const submissionId = response.data.id;
            setMessage(
                isResubmission
                    ? 'Assignment resubmitted successfully! Please wait, grading...'
                    : 'Assignment submitted successfully! Please wait, grading...'
            );

            const gradingResponse = await gradeApi.get({
                path: `/api/assignment/${assignment.id}/ai-grade/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            if (gradingResponse.status === 200) {
                setMessage('Assignment is being graded... Please wait.');
                startPolling(submissionId);
            } else {
                throw new Error('Grading failed. Please try again later.');
            }
        } catch (error) {
            console.error(
                isResubmission ? 'Error resubmitting assignment:' : 'Error submitting assignment:',
                error
            );
            setIsButtonDisabled(false);
            setMessage(error.response?.data?.error || 'An unknown error occurred');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        await submitOrResubmit(false);
    };

    const handleResubmit = async e => {
        e.preventDefault();
        await submitOrResubmit(true);
    };

    const handleCancel = () => {
        setIsButtonDisabled(false);
        setMessage('Submission cancelled.');
        stopPolling();
    };

    const getAssignmentSubmission = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/course/${class_id}/assignment/${assignment.id}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const data = response.data;
            console.log("THIS IS THE DATA");
            console.log(data);
            if (data.is_grade_in_progres) {
                setMessage('Assignment is being graded... Please wait.');
                setIsButtonDisabled(true);
                if (data.id) {
                    startPolling(data.id);
                }
            } else {
                setIsButtonDisabled(false);
                setMessage('');
            }
            if (response.status !== 200) {
                setMessage('Failed to verify session. Please log out and back in.');
                setIsButtonDisabled(true);
            }
            const latestSubmission = data.submissions?.find(sub => sub.most_recent);
            setSubmitted(latestSubmission ? latestSubmission.submitted : false);
            setSubmissionData(latestSubmission && latestSubmission.submitted ? data.submissions[0] : {});
            console.log("SUBMISSION DATA");
            console.log(latestSubmission && latestSubmission.submitted ? data : {});
            setEssayText(latestSubmission && latestSubmission.submitted ? latestSubmission.essay_text : '');
            setIsGroupAdmin(latestSubmission ? latestSubmission.is_group_admin : false);
            setFeedbackItems(latestSubmission && latestSubmission.submitted ? latestSubmission.feedback : {});
            setAllSubmissionsData(data.submissions);
        } catch (error) {
            setIsButtonDisabled(false);
            setMessage(
                error.response?.data?.error || 'An error occurred while fetching submission data.'
            );
        }
    };

    const handleLeaveGroup = async () => {
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment.id}/leave-group/`,
                body: {},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setMessage('You have left the group. Refreshing...');
            getAssignmentSubmission();
        } catch (error) {
            setMessage('Error leaving group. Please try again.');
        }
    };

    useEffect(() => {
        getAssignmentSubmission();
        return () => {
            stopPolling();
        };
    }, []);

    return (
        <div className="flex flex-col bg-white px-5 py-7">
            <div className="flex flex-col space-y-4 w-full">
                <div className="flex flex-row items-center space-x-2 mb-4">
                    <Link to="/sclasses">
                        <div className="size-10 flex items-center justify-center bg-gray-50 rounded-full">
                            <img src="/assets/sidebar/menu-home.png" className="size-5" alt="Home"/>
                        </div>
                    </Link>
                    <Link to="/sclasses">
                        <div className="text-black text-lg font-medium ml-4">My Classes</div>
                    </Link>
                    <Link to="/sclasses">
                        <div className="size-6 flex items-center justify-center">
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back Arrow"/>
                        </div>
                    </Link>
                    <Link to={`/sclasses/${class_id}/sassignments`}>
                        <div className="text-black text-lg font-medium ml-4">{assignment.course.name}</div>
                    </Link>
                    <Link to={`/sclasses/${class_id}/sassignments`}>
                        <div className="size-6 flex items-center justify-center">
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back Arrow"/>
                        </div>
                    </Link>
                    <h1 className="text-black text-lg font-medium ml-4">{assignment.title}</h1>
                </div>

                <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6">
                    {assignment.instructions && (
                        <p className="text-gray-600 mb-4">{assignment.instructions}</p>
                    )}
                    <div className="flex">
                        <div className="text-gray-900 text-smallish truncate mr-1">Due:</div>
                        <DueTimeDisplay
                            dueTime={assignment.due_time}
                            dueDate={assignment.due_date}
                            timezone={assignment.timezone}
                        />
                    </div>

                    {assignment.is_group_task && submitted ? (
                        isGroupAdmin ? (
                            <>
                                <div className="mb-6">
                  <textarea
                      value={essayText}
                      placeholder="Paste your essay here"
                      className="border border-gray-300 rounded-lg px-4 py-3 w-full h-40 focus:outline-none focus:ring-2 focus:ring-orange-200"
                      onChange={e => setEssayText(e.target.value)}
                  />
                                </div>
                                <form className="flex items-center space-x-4" onSubmit={handleResubmit}>
                                    <button
                                        type="submit"
                                        disabled={isButtonDisabled}
                                        className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative ${
                                            isButtonDisabled
                                                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                : 'bg-orange-200 hover:bg-orange-300 text-black'
                                        }`}
                                    >
                                        {isButtonDisabled ? (
                                            <>
                                                <span className="opacity-0">Resubmit</span>
                                                <div className="absolute inset-0 flex items-center justify-center">
                                                    <div
                                                        className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                                                </div>
                                            </>
                                        ) : (
                                            'Resubmit'
                                        )}
                                    </button>
                                    {isButtonDisabled && (
                                        <button
                                            type="button"
                                            className="px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                    )}
                                </form>
                                {submissionData.is_grade_in_progres === false && !isButtonDisabled && (
                                    <p className="text-orange-400 font-medium mt-4">
                                        Your assignment has been graded, but you can still resubmit! You will see your
                                        grade once professor publishes grades.
                                    </p>
                                )}
                            </>
                        ) : (
                            <div className="mb-6">
                                <p className="text-gray-600">
                                    You are not the group admin. Only the group admin can submit assignments.
                                </p>
                                <button
                                    className="mt-4 px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                                    onClick={handleLeaveGroup}
                                >
                                    Leave Group
                                </button>
                                <div className="mt-4">
                                    <h4 className="text-lg font-medium mb-2">Submitted Essay:</h4>
                                    <pre
                                        className="border border-gray-300 rounded-lg px-4 py-3 w-full min-h-[10rem] bg-gray-50 whitespace-pre-wrap word-break-break-word font-sans text-sm">
                    {essayText}
                  </pre>
                                </div>
                            </div>
                        )
                    ) : (
                        <>
                            <div className="mb-6">
                <textarea
                    value={essayText}
                    placeholder="Paste your essay here"
                    className="border border-gray-300 rounded-lg px-4 py-3 w-full h-40 focus:outline-none focus:ring-2 focus:ring-orange-200"
                    onChange={e => setEssayText(e.target.value)}
                />
                            </div>
                            <form className="flex items-center space-x-4" onSubmit={handleSubmit}>
                                <button
                                    type="submit"
                                    disabled={isButtonDisabled}
                                    className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative ${
                                        isButtonDisabled
                                            ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                            : 'bg-orange-200 hover:bg-orange-300 text-black'
                                    }`}
                                >
                                    {isButtonDisabled ? (
                                        <>
                                            <span className="opacity-0">Submit</span>
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <div
                                                    className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                                            </div>
                                        </>
                                    ) : (
                                        'Submit'
                                    )}
                                </button>
                                {isButtonDisabled && (
                                    <button
                                        type="button"
                                        className="px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                )}
                            </form>
                            {submitted && submissionData.is_grade_in_progres === false && !isButtonDisabled && (
                                <p className="text-orange-400 font-medium mt-4">
                                    Your assignment has been graded, but you can still resubmit! You will see your
                                    grade once professor publishes grades.
                                </p>
                            )}
                        </>
                    )}

                    {message && <p className="text-red-500 mt-4">{message}</p>}
                </div>
                {/* Submission Log Section */}
                <div className="flex flex-col w-full mb-5">
                    <button
                        type="button"
                        className="flex items-center text-sm mb-2"
                        onClick={() => setShowSubmissionLog(!showSubmissionLog)}
                    >
                        <img
                            src="/assets/back_arrow.png"
                            className={`size-5 opacity-60 transform ${showSubmissionLog ? 'rotate-90' : '-rotate-90'} mr-2`}
                            alt="Toggle submission log"
                        />
                        Submission Log
                    </button>
                </div>

                {showSubmissionLog && (
                    <div className="flex flex-col w-full">
                        {allSubmissionsData?.length > 0 ? (
                            allSubmissionsData.slice().reverse().map((submission, index) => (
                                <div key={index} className="mb-2 ml-4">
                                    <p className="text-sm text-gray-700">
                                        {allSubmissionsData.length - index}. Submitted at{' '}
                                        {(new Date(submission.submission_date)).toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true,
                                            timeZone: user.user.timezone,
                                            timeZoneName: 'short'
                                        })}{' '}
                                        by{' '}
                                        <span
                                            className="relative cursor-pointer hover:text-gray-900"
                                            onMouseEnter={() => setHoveredUser(submission)}
                                            onMouseLeave={() => setHoveredUser(null)}
                                        >
  <strong>{submission.submitter.is_teacher ? 'teacher' : 'student'}</strong>
                                            {hoveredUser === submission && (
                                                <div
                                                    className="absolute z-10 bg-black text-white p-2 rounded text-sm"
                                                    style={{
                                                        top: '100%',
                                                        left: '0',
                                                        marginTop: '5px',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    <div>ID: {submission.submitter.id}</div>
                                                    <div>Email: {submission.submitter.email}</div>
                                                </div>
                                            )}
                                </span>
                                    </p>
                                </div>
                            ))
                        ) : (
                            <p className="text-sm text-gray-700 ml-4">No submissions made yet.</p>
                        )}
                    </div>
                )}

                {submitted && assignment.is_group_task && isGroupAdmin && (
                    <AddStudentsToSubmission aid={assignment.id}/>
                )}

                {assignment.show_rubric && (
                    <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6 mt-8">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Grading Criteria</h3>
                        <table className="min-w-full bg-white">
                            <thead>
                            <tr className="bg-gray-50 border-b border-gray-200">
                                <th className="w-1/3 px-6 py-3 text-left text-gray-600 font-medium">Criterion</th>
                                <th className="w-1/3 px-6 py-3 text-left text-gray-600 font-medium">
                                    Rubric Descriptor
                                </th>
                                <th className="w-1/3 px-6 py-3 text-left text-gray-600 font-medium">
                                    Point Value
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {assignment.questions.flatMap(question =>
                                question.subQuestions.map((subQuestion, criterionIndex) => (
                                    <React.Fragment key={criterionIndex}>
                                        <tr className="border-b border-gray-200">
                                            <td
                                                className="px-6 py-4 border-t border-gray-200"
                                                rowSpan={subQuestion.rubricItems.length + 1}
                                            >
                                                {subQuestion.question}
                                            </td>
                                        </tr>
                                        {subQuestion.rubricItems.map((rubricItem, rubricIndex) => (
                                            <tr key={rubricIndex} className="border-b border-gray-200">
                                                <td className="px-6 py-4">{rubricItem.descriptor}</td>
                                                <td className="px-6 py-4">{rubricItem.value}</td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SUnpublishedEssay;
