import {ArrowLeftRight, ChevronDown, ChevronUp} from 'lucide-react';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useAuth} from '../../auth/AuthContext.js';
import {useQueryParams} from '../../shared/hooks/use-query-params.tsx';
import {addTeachers, getTeachers, removeTeacher} from '../../store/teacherSlice.js';
import {DEFAULT_PAGE_NUMBER, ITEMS_PER_PAGE} from '../../utils/pagination.ts';
import ConfirmationModal from '../ConfirmationModal.js';
import Table from '../Table.js';

const AddDropBigTeachers = props => {
    const dispatch = useDispatch();
    const {user} = useAuth();
    const [error, setError] = useState('');
    const [selectedTeacherId, setSelectedTeacherId] = useState(null);
    const {data, isLoading, errorMessage} = useSelector(state => state?.teacherReducer?.teachers);
    const [teacherErrors, setTeacherErrors] = useState(null);

    const [isEmailAddOpen, setIsEmailAddOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    const isOpenToManage = props.course?.isOpenToManage;

    const {
        params: {search, pageNumber, itemsPerPage},
        updateSearchParams
    } = useQueryParams({
        search: '',
        pageNumber: DEFAULT_PAGE_NUMBER,
        itemsPerPage: ITEMS_PER_PAGE.default
    });

    useEffect(() => {
        getAllTeachers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, pageNumber, itemsPerPage]);

    useEffect(() => {
        if (errorMessage) {
            setError(errorMessage);
        }
    }, [errorMessage]);

    const getAllTeachers = () =>
        dispatch(
            getTeachers({
                classId: props.class_id,
                token: user.session_token,
                search,
                pageNumber: Number(pageNumber),
                itemsPerPage: Number(itemsPerPage)
            })
        );

    const handleRemove = async id => {
        setSelectedTeacherId(id);
        setIsConfirmationModalOpen(true);
    };

    const addTeacherByEmail = async emails => {
        try {
            const response = await dispatch(
                addTeachers({
                    classId: props.class_id,
                    token: user.session_token,
                    emails
                })
            );
            setTeacherErrors(response.payload);
            getAllTeachers();
        } catch (error) {
            console.log(error);
            setError('Failed to add teachers. Please check the email and try again.');
        }
    };

    const confirmRemoveTeacher = async () => {
        const deleteUser = await dispatch(
            removeTeacher({
                classId: props.class_id,
                token: user.session_token,
                teacherId: selectedTeacherId
            })
        );

        if (deleteUser.error) {
            setError(deleteUser.payload);
        } else {
            getAllTeachers();
        }

        setIsConfirmationModalOpen(false);
    };

    const columnNames = ['Name', 'Email', 'Role', 'Actions'];

    return (
        <div className="add-drop-container">
            <div className="mt-4">
                {isOpenToManage && (
                    <button
                        className="w-full flex items-center ml-5 gap-2 py-2 text-left hover:bg-gray-50 transition-all duration-200 border-b border-gray-200"
                        onClick={() => setIsEmailAddOpen(!isEmailAddOpen)}
                    >
                        {isEmailAddOpen ? (
                            <ChevronUp className="h-5 w-5 text-gray-500"/>
                        ) : (
                            <ChevronDown className="h-5 w-5 text-gray-500"/>
                        )}
                        <span className="text-lg font-medium">Add Teacher by Email</span>
                    </button>
                )}

                {isEmailAddOpen && (
                    <div className="mt-4 p-4 bg-white rounded-lg border border-gray-200">
                        <div className="space-y-4">
                            <div className="flex gap-3">
                                <div className="relative flex-1">
                                    <input
                                        type="email"
                                        placeholder="Enter teacher email and press 'enter'"
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        onKeyDown={event => {
                                            const selected = event.target.value;

                                            if (event.key === 'Enter' && !!selected) {
                                                addTeacherByEmail([selected]);
                                                event.target.value = '';
                                            }
                                        }}
                                    />
                                    <ArrowLeftRight
                                        className="absolute right-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400 transition-colors duration-200 peer-focus:text-orange-500"/>
                                </div>
                            </div>
                            {/* <SelectedOptionsSection
                isVisible={teacherEmails.length > 0}
                onRemoveAll={() => {
                  setTeacherEmails([]);
                }}
              >
                {teacherEmails.map((option, index) => (
                  <CustomChip
                    key={`${option}-${index}`}
                    label={option}
                    onRemove={() => {
                      setTeacherEmails(prev => prev.filter((_, i) => i !== index));
                    }}
                  />
                ))}
              </SelectedOptionsSection> */}
                            {error && <p className="text-red-500 text-sm">{error}</p>}
                            {teacherErrors?.already_added_emails.length > 0 && (
                                <>
                                    <p className="text-gray-900 text-m">Emails have already been added:</p>
                                    {teacherErrors.already_added_emails.map(error => (
                                        <span key={Math.random()} className="text-red-500 text-sm">
                      {error}
                    </span>
                                    ))}
                                </>
                            )}
                            {teacherErrors?.not_a_teacher_emails.length > 0 && (
                                <>
                                    <p className="text-gray-900 text-m">You cannot add a student as a teacher:</p>
                                    {teacherErrors.not_a_teacher_emails.map(error => (
                                        <span key={Math.random()} className="text-red-500 text-sm">
                      {error}
                    </span>
                                    ))}
                                </>
                            )}
                            {teacherErrors?.teacher_not_found_emails.length > 0 && (
                                <>
                                    <p className="text-gray-900 text-m">Emails not found:</p>
                                    {teacherErrors.teacher_not_found_emails.map(error => (
                                        <span key={Math.random()} className="text-red-500 text-sm">
                      {error}
                    </span>
                                    ))}
                                </>
                            )}
                            {teacherErrors?.invalid_emails.length > 0 && (
                                <>
                                    <p className="text-gray-900 text-m">Emails not found:</p>
                                    {teacherErrors.invalid_emails.map(error => (
                                        <span key={Math.random()} className="text-red-500 text-sm">
                      {error}
                    </span>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="p-6">
                <div className="mb-4">
                    <Table
                        isLoading={isLoading}
                        totalCount={data?.totalCount}
                        offset={data?.offset}
                        nextOffset={data?.nextOffset}
                        pageNumber={Number(pageNumber)}
                        itemsPerPage={Number(itemsPerPage)}
                        columnNames={columnNames}
                        noDataTitle="No students found"
                        columnBodies={(data?.entities ?? []).map(teacher => (
                            <tr key={`${teacher.fullName + ' ' + teacher.id}`} className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    <div className="truncate max-w-[30ch]" title={teacher.fullName}>
                                        {teacher.fullName}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    <div className="truncate max-w-[30ch]" title={teacher.email}>
                                        {teacher.email}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {teacher.role === 'owner' ? (
                                        <span
                                            className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-red-100 text-red-800">
                      Lead Instructor
                    </span>
                                    ) : (
                                        <span
                                            className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-green-100 text-green-800">
                      Co-Instructor
                    </span>
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                    {teacher.role !== 'owner' && props.course.isOpenToManage && (
                                        <button
                                            className="text-red-600 hover:text-red-900 focus:outline-none"
                                            onClick={() => handleRemove(teacher.id)}
                                        >
                                            <img
                                                src="/assets/trash.png"
                                                alt="Remove"
                                                className="w-5 h-5 z-5 opacity-70 hover:opacity-100 inline"
                                            />
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                        updateSearchParams={updateSearchParams}
                    />
                </div>
            </div>

            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                message="Are you sure you want to remove this teacher? This action cannot be undone."
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={confirmRemoveTeacher}
            />
        </div>
    );
};

export default AddDropBigTeachers;
