import React, { useEffect, useState } from 'react';
import { gradeApi } from '../../../api.js';
import { useAuth } from '../../../auth/AuthContext.js';
import AssMeanMedian from './AssMeanMedian.js';
import SubQuestionTable from './SubQuestionTable.js';
import TScoreFrequency from './TScoreFrequency.js';

const TAssignmentStats = ({ class_id, assignment_id }) => {
  const [scores, setScores] = useState([]);
  const [stdDeviation, setStdDeviation] = useState(0.0);
  const [assignment, setAssignment] = useState();
  const [mean, setMean] = useState(0.0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [maxP, setMaxP] = useState(0);
  const [median, setMedian] = useState(0);
  const [submissions, setSubmissions] = useState([]);
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const getScores = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/assignment/${assignment_id}/submissions-stats/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      const newScores = response.data.submissions.map(submission => submission.grade);
      const maxPossibleScore = response.data.assignment.questions.reduce((total, question) => {
        return (
          total +
          question.subQuestions.reduce((subTotal, subQuestion) => {
            const maxRubricValue = Math.max(...subQuestion.rubricItems.map(item => item.value));
            return subTotal + maxRubricValue;
          }, 0)
        );
      }, 0);
      setMaxP(maxPossibleScore);
      setAssignment(response.data.assignment);
      setSubmissions(response.data.submissions);
      setScores(newScores);
      get_min_max_median(newScores);
    } catch (error) {
      setErrorMessage('Failed to fetch scores. Please try again.');
      setIsErrorVisible(true);
    }
  };

  const get_min_max_median = scores => {
    const sortedArr = [...scores].sort((a, b) => a - b);
    setMin(sortedArr[0]);
    setMax(sortedArr[sortedArr.length - 1]);
    const median_to_set =
      sortedArr.length % 2 === 0
        ? (sortedArr[sortedArr.length / 2 - 1] + sortedArr[sortedArr.length / 2]) / 2
        : sortedArr[Math.floor(sortedArr.length / 2)];
    setMedian(median_to_set);
    const total = scores.reduce((acc, num) => acc + num, 0);
    setMean(total / scores.length);
  };

  useEffect(() => {
    getScores();
  }, []);

  useEffect(() => {
    if (scores.length > 0) {
      const variance =
        scores.reduce((acc, num) => acc + Math.pow(num - mean, 2), 0) / scores.length;
      setStdDeviation(Math.sqrt(variance));
    }
  }, [scores]);

  return (
    <div className="mt-5 p-6 border border-gray-200 rounded-lg shadow-sm">
      {isErrorVisible && (
        <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {errorMessage}
        </div>
      )}
      <AssMeanMedian
        mean={mean}
        stdDeviation={stdDeviation}
        min={min}
        max={max}
        median={median}
        title={assignment?.title + ' statistics'}
      />
      <div className="mt-6">
        <TScoreFrequency scores={scores} max={maxP} />
      </div>
      {assignment?.type === 'essay' ? (
        assignment?.questions?.length > 0 && (
          <div className="mt-6">
            <h2 className="text-xl font-bold mb-2">AI Analysis ✨</h2>
            <div className="p-4 border border-gray-200 rounded bg-white">
              {assignment.questions[0].image_s3_key
                ? assignment.questions[0].image_s3_key
                : 'AI Analysis unavailable until grades published.'}
            </div>
          </div>
        )
      ) : (
        <div className="mt-6">
          <SubQuestionTable
            submissions={submissions}
            assignment_id={assignment_id}
            questions={assignment?.questions || []}
          />
        </div>
      )}
    </div>
  );
};

export default TAssignmentStats;
