import React, { useState } from 'react';
import { Info } from 'lucide-react';

const Switch = ({ checked, onChange, label }) => (
  <label className="relative inline-flex items-center cursor-pointer">
    <div className="relative">
      <input 
        type="checkbox" 
        className="sr-only" 
        checked={checked} 
        onChange={onChange}
      />
      <div className={`w-10 h-6 rounded-full transition-all duration-200 ${
        checked ? 'bg-orange-200' : 'bg-gray-200'
      }`}>
        <div className={`absolute w-4 h-4 bg-white rounded-full transition-all duration-200 transform ${
          checked ? 'translate-x-5' : 'translate-x-1'
        } top-1`} />
      </div>
    </div>
    <span className="ml-3">{label}</span>
  </label>
);

const ColumnMappingModal = ({ isOpen, onClose, onConfirm, csvColumns = [] }) => {
  const [emailColumn, setEmailColumn] = useState('');
  const [hasNameColumns, setHasNameColumns] = useState(false);
  const [firstNameColumn, setFirstNameColumn] = useState('');
  const [lastNameColumn, setLastNameColumn] = useState('');
  const [hasStudentId, setHasStudentId] = useState(false);
  const [studentIdColumn, setStudentIdColumn] = useState('');

  const isValid = emailColumn && 
    (!hasNameColumns || (hasNameColumns && firstNameColumn && lastNameColumn)) &&
    (!hasStudentId || (hasStudentId && studentIdColumn));

  const handleConfirm = () => {
    if (!isValid) {return};
    
    onConfirm({
      emailColumn,
      hasNameColumns,
      firstNameColumn: hasNameColumns ? firstNameColumn : '',
      lastNameColumn: hasNameColumns ? lastNameColumn : '',
      hasStudentId,
      studentIdColumn: hasStudentId ? studentIdColumn : ''
    });
  };

  return isOpen && (
    <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50 flex justify-center items-center">
      <div className="relative mx-auto p-6 bg-white w-full max-w-lg rounded-lg">
        <div className="space-y-6">
          {/* Header */}
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-bold">Column Mapping</h2>
            <button
              type="button"
              className="hover:opacity-50 transition-all duration-300"
              onClick={onClose}
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Email Column Section */}
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <label className="block font-medium">Email Column Name</label>
              <div className="group relative">
                <Info className="h-4 w-4 text-gray-500" />
                <div className="absolute w-64 p-2 bg-gray-800 text-white text-xs rounded-md opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50 right-0 top-6">
                  Select the column containing student email addresses
                </div>
              </div>
            </div>
            <select
              value={emailColumn}
              onChange={(e) => setEmailColumn(e.target.value)}
              className="w-full px-3 py-2 border rounded-md bg-white"
              required
            >
              <option value="">Select email column...</option>
              {csvColumns.map((column) => (
                <option key={column} value={column}>{column}</option>
              ))}
            </select>
          </div>

          {/* Name Columns Section */}
          <div className="space-y-4 py-2">
            <Switch
              checked={hasNameColumns}
              onChange={(e) => {
                setHasNameColumns(e.target.checked);
                if (!e.target.checked) {
                  setFirstNameColumn('');
                  setLastNameColumn('');
                }
              }}
              label="Include first and last name columns?"
            />

            {hasNameColumns && (
              <div className="space-y-4 mt-4">
                <div>
                  <label className="block text-sm font-medium mb-1">First Name Column</label>
                  <select
                    value={firstNameColumn}
                    onChange={(e) => setFirstNameColumn(e.target.value)}
                    className="w-full px-3 py-2 border rounded-md bg-white"
                  >
                    <option value="">Select first name column...</option>
                    {csvColumns.map((column) => (
                      <option key={column} value={column}>{column}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Last Name Column</label>
                  <select
                    value={lastNameColumn}
                    onChange={(e) => setLastNameColumn(e.target.value)}
                    className="w-full px-3 py-2 border rounded-md bg-white"
                  >
                    <option value="">Select last name column...</option>
                    {csvColumns.map((column) => (
                      <option key={column} value={column}>{column}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>

          {/* Student ID Section */}
          <div className="space-y-4 py-2">
            <Switch
              checked={hasStudentId}
              onChange={(e) => {
                setHasStudentId(e.target.checked);
                if (!e.target.checked) {
                  setStudentIdColumn('');
                }
              }}
              label="Include student ID column?"
            />

            {hasStudentId && (
              <div className="mt-4">
                <label className="block text-sm font-medium mb-1">Student ID Column</label>
                <select
                  value={studentIdColumn}
                  onChange={(e) => setStudentIdColumn(e.target.value)}
                  className="w-full px-3 py-2 border rounded-md bg-white"
                >
                  <option value="">Select student ID column...</option>
                  {csvColumns.map((column) => (
                    <option key={column} value={column}>{column}</option>
                  ))}
                </select>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-3 pt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-3xl hover:bg-gray-50 transition-all duration-300"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              disabled={!isValid}
              className={`px-4 py-2 border border-black rounded-3xl transition-all duration-300 ${
                isValid 
                  ? 'bg-orange-200 hover:bg-orange-300' 
                  : 'bg-gray-100 cursor-not-allowed'
              }`}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnMappingModal;