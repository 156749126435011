import React from 'react';

const formatDateTime = (datetimeString, timezone) => {
    const date = new Date(datetimeString);
    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZone: timezone,
        timeZoneName: 'short'
    });
};

const DueTimeDisplayTable = ({datetimeString, timezone}) => {
    // console.log(datetimeString);
    const formattedDateTime = formatDateTime(datetimeString, timezone);

    return (
        <div>
            {formattedDateTime}
        </div>
    );
};

export default DueTimeDisplayTable;