import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext.js';

const TStatistics = () => {
    const {user} = useAuth();
    const [classes, setClasses] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);

    // another vercel commit
    const getClasses = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/users/${user.user.id}/courses-lite/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setClasses(response.data.courses);
        } catch (error) {
            setErrorMessage('Failed to fetch classes. Please try again.');
            setIsErrorVisible(true);
        }
    };

    useEffect(() => {
        getClasses();
    }, []);

    // veercel commit

    return (
        <div id="Statistics" className="flex flex-col space-y-4 w-full p-8">
            <div id="Overview" className="flex flex-row space-x-full w-full justify-between">
                <h1 className="text-black text-xl font-medium">Statistics</h1>
            </div>
            <div className="flex flex-row space-x-4">
                <button
                    className="group flex items-center gap-2 px-4 py-2 bg-white rounded-full
            text-sm text-gray-700 border border-gray-200 hover:border-orange-200
            hover:bg-orange-50 transition-all duration-200"
                    onClick={() => window.open('https://www.loom.com/share/bbb335a7e4ef4ea4be0967d6b4665b03?sid=5bb1d8ae-dd01-4a0a-adc0-c0ea5c8528ba', '_blank')}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4 h-4 text-gray-500 group-hover:text-orange-500 transition-colors"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M10 9v6l5-3-5-3z"/>
                        <circle cx="12" cy="12" r="10"/>
                    </svg>
                    Tutorial
                </button>
            </div>
            <div className="bg-white sexy-border p-6">
                {isErrorVisible && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {errorMessage}
                    </div>
                )}
                <table className="w-full border-collapse">
                    <thead>
                    <tr className="border-b border-border">
                        <th className="py-4 text-left text-gray custom-16">Class</th>
                        <th className="py-4 text-left text-gray custom-16">Class Code</th>
                        <th className="py-4 text-left text-gray custom-16">Number of Students</th>
                    </tr>
                    </thead>
                    {classes.length > 0 ? (
                        <tbody>
                        {classes.map(value => (
                            <tr key={value.id} className="border-b border-border">
                                <td className="text-left text-black custom-16-semi py-4">
                                    <Link to={`/statistics/${value.id}`} className="hover:underline">
                                        {value.name}
                                    </Link>
                                </td>
                                <td className="text-left text-black custom-16-med py-4">{value.code}</td>
                                <td className="text-left text-black custom-16-med py-4">{value.num_students}</td>
                            </tr>
                        ))}
                        </tbody>
                    ) : (
                        <tbody>
                        <tr>
                            <td colSpan="3" className="text-center py-4 text-gray-500">
                                No classes available
                            </td>
                        </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
};

export default TStatistics;
