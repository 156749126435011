import React, {useState} from 'react';
import {Calendar} from 'lucide-react';
import SideBar from '../components/SideBar';
import {useAuth} from '../auth/AuthContext';

const SupportPage = () => {
    const {user} = useAuth();
    const [formStatus, setFormStatus] = useState({success: false, error: false});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());

        try {
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...data,
                    access_key: '9932eb13-3d42-4b3f-80f8-91dbe1b390f3',
                    from_name: 'GradeWiz Website Contact',
                }),
            });

            if (response.ok) {
                setFormStatus({success: true, error: false});
                e.target.reset();
            } else {
                setFormStatus({success: false, error: true});
            }
        } catch (error) {
            setFormStatus({success: false, error: true});
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="HomePageHeader h-screen flex flex-col">
            <div className="flex flex-row flex-grow overflow-hidden">
                <div className="flex-shrink-0">
                    <SideBar activePage="Support"/>
                </div>

                <div className="flex-grow overflow-y-auto p-8">
                    <div className="text-center mb-6">
                        <h1 className="text-black text-2xl font-medium">Support and Feedback</h1>
                        <a
                            href="https://calendly.com/maxbohun/gradewiz"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center px-4 py-2 mt-4 rounded-3xl border-[1px] border-black
                                     text-smallish font-medium transition-all duration-300
                                     desktop:hover:-translate-y-[0.5px] desktop:hover:shadow
                                     bg-orange-200 hover:bg-orange-300 text-black"
                        >
                            <Calendar className="w-5 h-5 mr-2"/>
                            Let's Talk
                        </a>
                    </div>

                    <div className="bg-white sexy-border p-6">
                        <div className="max-w-3xl mx-auto">
                            <div className="mb-8">
                                <h2 className="text-2xl font-bold">Contact Us</h2>
                            </div>

                            <form className="space-y-6" onSubmit={handleSubmit}>
                                <input type="hidden" name="access_key" value="9932eb13-3d42-4b3f-80f8-91dbe1b390f3"/>
                                <input type="hidden" name="from_name" value="GradeWiz Website Contact"/>

                                <div>
                                    <label htmlFor="title" className="block text-sm font-medium mb-2">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        value={user?.user?.is_teacher ? "Teacher" : "Student"}
                                        readOnly
                                        className="w-full px-4 py-2 border rounded-md bg-gray-100"
                                    />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor="firstName" className="block text-sm font-medium mb-2">
                                            First name
                                        </label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            value={user?.user?.first_name || ""}
                                            readOnly
                                            className="w-full px-4 py-2 border rounded-md bg-gray-100"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="lastName" className="block text-sm font-medium mb-2">
                                            Last name
                                        </label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            value={user?.user?.last_name || ""}
                                            readOnly
                                            className="w-full px-4 py-2 border rounded-md bg-gray-100"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium mb-2">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={user?.user?.email || ""}
                                        readOnly
                                        className="w-full px-4 py-2 border rounded-md bg-gray-100"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="message" className="block text-sm font-medium mb-2">
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        required
                                        rows={5}
                                        className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                                        placeholder="Your message here"
                                    />
                                </div>

                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="w-full px-4 py-2 rounded-3xl border-[1px] border-black
                                             text-smallish font-medium transition-all duration-300
                                             desktop:hover:-translate-y-[0.5px] desktop:hover:shadow
                                             bg-orange-200 hover:bg-orange-300 text-black
                                             disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-500"
                                >
                                    {isSubmitting ? 'Sending...' : 'Submit'}
                                </button>
                            </form>

                            {formStatus.success && (
                                <div className="mt-4 p-4 bg-green-50 border border-green-200 rounded-md text-green-800">
                                    Thank you! Your submission has been received!
                                </div>
                            )}

                            {formStatus.error && (
                                <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-md text-red-800">
                                    Oops! Something went wrong while submitting the form.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupportPage;