import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import SideBar from '../../components/SideBar';
import BigStepper from '../../components/Stepper.js';
import BigStepperLine from '../../components/StepperLine.js';
import AnswerUpload from '../../components/Teacher/AnswerUpload';
import ExamUpload from '../../components/Teacher/ExamUpload';
import RenderBatch from '../../components/Teacher/RenderBatch';
import TGrade from '../../components/Teacher/TGrade';
import ParsePDF from '../../components/Teacher/TParsePDF'; // New import for PdfUpload component
import TPset from '../../components/Teacher/TPset/TPset';
import TQuestionGrade from '../../components/Teacher/TQuestionGrade.js';
import TopBar from '../../components/TopBar';

const TPdfSubmissions = ({
                             assignment_id,
                             assignment,
                             submissions,
                             pullQuestions,
                             updateTrigger,
                             section,
                             reviewMode,
                             setUpdateTrigger,
                             setPullQuestions,
                             setEdited,
                             edited,
                             solRecent,
                             setSection,
                             setSolRecent,
                             isSubmissionLoading,
                             pageNumber,
                             itemsPerPage,
                             submissionsError,
                             setReviewMode,
                             updateSearchParams
                         }) => {
    const {user} = useAuth();

    const [generated, setGenerated] = useState(false);
    const [topErrorMessages, setTopErrorMessages] = useState('');

    const publishAssignment = async () => {
        if (assignment.is_assignment_published) {
            setTopErrorMessages(["You can't unpublish the assignment once it is published."]);
            return;
        }

        if (!generated) {
            setTopErrorMessages(['Please generate the assignment PDF before publishing the assignment.']);
            return;
        }
        if (edited) {
            setTopErrorMessages([
                'You have made changes to the assignment without regenerating your PDF. Please regenerate PDF.'
            ]);
            return;
        }

        await gradeApi.get({
            path: `/api/assignment/${assignment.id}/build/`,
            config: {headers: {Authorization: `Bearer ${user.session_token}`}}
        });

        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment.id}/publish-assignment/`,
                body: {
                    is_assignment_published: true
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            setTopErrorMessages(['']);
            setUpdateTrigger(prev => !prev);
            window.location.reload();
        } catch (error) {
            // console.log(error);
            if (error.response && error.response.data && error.response.data.error) {
                // Display the specific error message from the backend
                setTopErrorMessages([error.response.data.error]);
            } else {
                // Fallback to a generic error message if no specific message is available
                setTopErrorMessages(['Failed to publish assignment. Please try again.']);
            }
            console.error('Error publishing assignment:', error);
        }
    };

    return (
        <div className="HomePageHeader">
            <div id="Main-container" className="flex flex-row">
                {section !== 'create' && (
                    <div id="Left-Sidebar" className="fixed">
                        <SideBar activePage="Home"/>
                    </div>
                )}
                <div
                    id="Right-content"
                    className={
                        section === 'create' ? 'flex w-full' : 'flex ml-side-bar-size pr-[30px] w-full'
                    }
                >
                    <TopBar
                        assignment_in={assignment}
                        submissions_in={submissions}
                        topErrorMessages={topErrorMessages}
                        setTopErrorMessages={setTopErrorMessages}
                        section={section}
                        setSection={setSection}
                        publishAssignment={publishAssignment}
                    />
                    {section === 'create' ? (
                        <div
                            className={`flex flex-col mb-32  w-[55%] ml-[15px] mr-0 ${topErrorMessages ? 'mt-34' : 'mt-44'}`}
                        >
                            <div className="flex gap-3 mb-6">
                                <button
                                    className="group flex items-center gap-2 px-4 py-2 bg-white rounded-full
                                                text-sm text-gray-700 border border-gray-200 hover:border-orange-200 
                                                hover:bg-orange-50 transition-all duration-200"
                                    onClick={() => window.open('https://youtu.be/kPP4Rbn2YCE', '_blank')}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        className="w-4 h-4 text-gray-500 group-hover:text-orange-500 transition-colors"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M10 9v6l5-3-5-3z"/>
                                        <circle cx="12" cy="12" r="10"/>
                                    </svg>
                                    Tutorial
                                </button>
                                <Link
                                    to="/support"
                                    className="group flex items-center gap-2 px-4 py-2 bg-white rounded-full
                                                text-sm text-gray-700 border border-gray-200 hover:border-orange-200 
                                                hover:bg-orange-50 transition-all duration-200"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        className="w-4 h-4 text-gray-500 group-hover:text-orange-500 transition-colors"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <circle cx="12" cy="12" r="10"/>
                                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/>
                                        <path d="M12 17h.01"/>
                                    </svg>
                                    Help
                                </Link>
                            </div>

                            <div className="grid grid-cols-[84px_auto]">
                                <BigStepper stepNumber={0} top={5}/>
                                <ParsePDF
                                    assignment_id={assignment_id}
                                    setUpdateTrigger={setUpdateTrigger}
                                    isPublished={assignment.is_assignment_published}
                                    setPullQuestions={setPullQuestions}
                                    setEdited={setEdited}
                                    setSolRecent={setSolRecent}
                                />
                            </div>
                            <BigStepperLine/>

                            <div className="grid grid-cols-[84px_auto]">
                                <BigStepper stepNumber={1} top={5}/>
                                <TPset
                                    assignment_id={assignment_id}
                                    updateTrigger={updateTrigger}
                                    setUpdateTrigger={setUpdateTrigger}
                                    pullQuestions={pullQuestions}
                                    setPullQuestions={setPullQuestions}
                                    isPublished={assignment.is_assignment_published}
                                    setEdited={setEdited}
                                    edited={edited}
                                    setSolRecent={setSolRecent}
                                    solRecent={solRecent}
                                    isExam={assignment.is_exam}
                                    setGenerated={setGenerated}
                                    setTopErrorMessages={setTopErrorMessages}
                                />
                            </div>
                            <BigStepperLine/>
                            {/*<div className="grid grid-cols-[84px_auto] gap-4">*/}
                            {/*  <BigStepper stepNumber={2} top={0} />*/}
                            {/*  <AnswerUpload*/}
                            {/*    assignment_id={assignment_id}*/}
                            {/*    setUpdateTrigger={setUpdateTrigger}*/}
                            {/*    isPublished={assignment.is_assignment_published}*/}
                            {/*    setPullQuestions={setPullQuestions}*/}
                            {/*    setEdited={setEdited}*/}
                            {/*    setSolRecent={setSolRecent}*/}
                            {/*  />*/}
                            {/*</div>*/}
                        </div>
                    ) : (
                        <div
                            className={`flex flex-col mb-32 w-full ml-[30px] ${topErrorMessages ? 'mt-44' : 'mt-44'}`}
                        >
                            {assignment.is_exam && (
                                <>
                                    <div className="grid grid-cols-[84px_auto]">
                                        <BigStepper stepNumber={2} top={0}/>
                                        <RenderBatch assignment_id={assignment_id} updateTrigger={updateTrigger}/>
                                    </div>
                                    <BigStepperLine/>
                                    <div className="grid grid-cols-[84px_auto]">
                                        <BigStepper stepNumber={3} top={0}/>
                                        <ExamUpload assignment_id={assignment_id} setUpdateTrigger={setUpdateTrigger}/>
                                    </div>
                                    <BigStepperLine/>
                                </>
                            )}

                            <div className="grid grid-cols-[84px_auto]">
                                <BigStepper stepNumber={assignment.is_exam ? 4 : 2} top={0} ht={'[50px]'}/>
                                {reviewMode === 'Question' ? (
                                    <TQuestionGrade
                                        assignment_in={assignment}
                                        submissions_in={submissions}
                                        setUpdateTrigger={setUpdateTrigger}
                                        reviewMode={reviewMode}
                                        setReviewMode={setReviewMode}
                                    />
                                ) : (
                                    <TGrade
                                        reviewMode={reviewMode}
                                        isLoading={isSubmissionLoading}
                                        itemsPerPage={itemsPerPage}
                                        pageNumber={pageNumber}
                                        submissionsError={submissionsError}
                                        assignment={assignment}
                                        submissions={submissions}
                                        setUpdateTrigger={setUpdateTrigger}
                                        setReviewMode={setReviewMode}
                                        updateSearchParams={updateSearchParams}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TPdfSubmissions;
