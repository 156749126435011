import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { gradeApi } from '../api.js';

export const loadDraftData = createAsyncThunk(
  'studentSlice/loadDraftData',
  async ({ assignment_id, token, studentId }) => {
    try {
      const response = await gradeApi.get({
        path: `/api/v2/assignments/${assignment_id}/submission/flexible-problem-set/draft`,

        config: {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            studentId
          }
        }
      });

      return await response.data;
    } catch (error) {
      console.log(error);
      return await response.data;
    }
  }
);

export const updateDraftData = createAsyncThunk(
  'studentSlice/updateDraftData',
  async ({ dataToSave, token, assignment_id }, { rejectWithValue }) => {
    try {
      console.log(dataToSave);
      const response = await gradeApi.post({
        path: `/api/v2/assignments/${assignment_id}/submission/flexible-problem-set/draft`,
        body: dataToSave,
        config: { headers: { Authorization: `Bearer ${token}` } }
      });
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const publishData = createAsyncThunk(
  'studentSlice/publishData',
  async ({ token, assignment_id }, { rejectWithValue }) => {
    try {
      const response = await gradeApi.post({
        path: `/api/v2/assignments/${assignment_id}/submission/flexible-problem-set`,
        config: { headers: { Authorization: `Bearer ${token}` } }
      });
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadPublishedData = createAsyncThunk(
  'studentSlice/loadPublishedData',
  async ({ token, assignment_id, studentId }, { rejectWithValue }) => {
    try {
      const response = await gradeApi.get({
        path: `/api/v2/assignments/${assignment_id}/submission/flexible-problem-set`,
        config: {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            studentId
          }
        }
      });
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadPublishedQuestionData = createAsyncThunk(
  'studentSlice/loadPublishedQuestionData',
  async ({ token, assignment_id, questionId, studentId }, { rejectWithValue }) => {
    try {
      const response = await gradeApi.get({
        path: `/api/v2/assignments/${assignment_id}/submission/${questionId}/flexible-problem-set`,
        config: {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            studentId
          }
        }
      });
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addStudents = createAsyncThunk(
  'teacher/addStudents',
  async ({ classId, emails, token }, { rejectWithValue }) => {
    try {
      const response = await gradeApi.post({
        path: `/api/v2/courses/${classId}/students`,
        body: { emails },
        config: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to add teachers. Please try again.');
    }
  }
);

const studentSlice = createSlice({
  name: 'studentSlice',
  initialState: {
    draftData: {
      data: {},
      isLoading: false,
      errorMessage: ''
    }
  },
  reducers: {
    clearSubmissionData: state => {
      state.draftData.data = {};
      state.draftData.errorMessage = '';
    }
  },
  extraReducers: builder => {
    builder.addCase(loadDraftData.pending, state => {
      state.draftData.isLoading = true;
    });
    builder.addCase(loadDraftData.rejected, state => {
      console.log(state);
      state.draftData.isLoading = false;
    });
    builder.addCase(loadDraftData.fulfilled, (state, action) => {
      state.draftData.isLoading = false;
      state.draftData.data = action.payload;
    });
    builder.addCase(updateDraftData.pending, state => {
      state.draftData.isLoading = true;
    });
    builder.addCase(updateDraftData.rejected, (state, action) => {
      state.draftData.isLoading = false;
      state.draftData.errorMessage = action.payload.message;
    });
    builder.addCase(updateDraftData.fulfilled, (state, action) => {
      state.draftData.isLoading = false;
      state.draftData.data = action.payload;
    });
    builder.addCase(publishData.pending, state => {
      state.draftData.isLoading = true;
    });
    builder.addCase(publishData.rejected, (state, action) => {
      state.draftData.isLoading = false;
      state.draftData.errorMessage = action.payload.message;
    });
    builder.addCase(publishData.fulfilled, (state, action) => {
      state.draftData.isLoading = false;
    });
    builder.addCase(loadPublishedData.pending, state => {
      state.draftData.isLoading = true;
    });
    builder.addCase(loadPublishedData.rejected, state => {
      console.log(state);
      state.draftData.isLoading = false;
    });
    builder.addCase(loadPublishedData.fulfilled, (state, action) => {
      state.draftData.isLoading = false;
      state.draftData.data = action.payload;
    });
    builder.addCase(loadPublishedQuestionData.pending, state => {
      state.draftData.isLoading = true;
    });
    builder.addCase(loadPublishedQuestionData.rejected, state => {
      console.log(state);
      state.draftData.isLoading = false;
    });
    builder.addCase(loadPublishedQuestionData.fulfilled, (state, action) => {
      state.draftData.isLoading = false;
      state.draftData.data = action.payload;
    });
  }
});

export const { clearSubmissionData } = studentSlice.actions;

export default studentSlice.reducer;
