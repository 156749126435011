import { Field, getIn } from 'formik';
import React from 'react';

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      return error ? <span className="text-red-800 text-xs">{error}</span> : null;
    }}
  />
);

export default ErrorMessage;
