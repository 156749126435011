import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { gradeApi } from '../api.js';

const cookiesManager = res => {
  Cookies.set('session_token', res.session_token, { expires: 1 });
  Cookies.set('user_id', res.user.id, { expires: 1 });
  Cookies.set('user_email', res.user.email, { expires: 1 });
  Cookies.set('is_teacher', String(res.user.is_teacher), { expires: 1 });
  Cookies.set('first_name', res.user.first_name, { expires: 1 });
  Cookies.set('last_name', res.user.last_name, { expires: 1 });
  Cookies.set('is_verified', String(res.user.is_verified), { expires: 1 });
  Cookies.set('timezone', String(res.user.timezone), { expires: 1});
};

export const login = createAsyncThunk(
  'user/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await gradeApi.post({
        path: '/api/login/',
        body: {
          email,
          password: password
        }
      });
      if (response.data && !('error' in response.data)) {
        cookiesManager(response.data);
      }
      return response.data;
    } catch (error) {
      rejectWithValue(error.response?.data?.error || 'Error in backend');
      return { error: error.response?.data?.error || 'Error in backend' };
    }
  }
);

export const logout = createAsyncThunk('user/logout', async ({ token }, { rejectWithValue }) => {
  try {
    const response = await gradeApi.post({
      path: '/api/logout/',
      body: {},
      config: {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    });

    Cookies.remove('session_token');
    Cookies.remove('user_id');
    Cookies.remove('user_email');
    Cookies.remove('is_teacher');
    Cookies.remove('first_name');
    Cookies.remove('last_name');
    Cookies.remove('is_verified');
    Cookies.remove('timezone');

    return response.data;
  } catch (error) {
    rejectWithValue(error.response?.data?.error || 'Error in backend');
    return { error: 'Error in backend' };
  }
});

export const getUser = createAsyncThunk(
  'user/getUser',
  async ({ userId, token }, { rejectWithValue }) => {
    try {
      const response = await gradeApi.get({
        path: `/api/users/${userId}/`,
        config: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue('Error in backend');
    }
  }
);

export const signUp = createAsyncThunk(
  'user/signUp',
  async ({ firstName, lastName, email, password, is_teacher }, { rejectWithValue }) => {
    try {
      const response = await gradeApi.post({
        path: '/api/register/',
        body: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          is_teacher: is_teacher
        }
      });
      return { message: response.data.message, type: response.data.type };
    } catch (error) {
      console.error('Sign up error:', error);
      const errorData = error?.response?.data;
      if (errorData && errorData.type) {
        rejectWithValue(errorData.error || 'Error in backend');
        return {
          message: errorData.error || 'Error in backend',
          type: errorData.type
        };
      } else {
        rejectWithValue(error || 'Error in backend');
        return { message: error || 'Error in backend', type: 'error' };
      }
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {},
    isLoading: false
  },
  reducers: {
    setExistingUser: (state, action) => {
      state.user = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(login.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = {
        ...action.payload,
        isAuthenticated: true,
        loginRecent: true
      };
    });
    builder.addCase(logout.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(logout.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = {
        ...action.payload,
        isAuthenticated: false,
        loginRecent: false
      };
    });
    builder.addCase(getUser.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getUser.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = {
        user: action.payload,
        isAuthenticated: true,
        loginRecent: false
      };
    });
  }
});

export const { setExistingUser } = userSlice.actions;

export default userSlice.reducer;

// export const checkSession = async (sessionToken) => {
//     try {
//         const response = await gradeApi.post({
//             path: `/api/check-session/`,
//             body: {},
//                 config: {headers: {
//                     Authorization: `Bearer ${sessionToken}`,
//                 }},
//             }
//         );
//         return response.data;
//     } catch (error) {
//         console.error("Check session error:", error);
//         return {
//             error: error.response?.data?.error || "Error checking session",
//             status: error.response?.status,
//         };
//     }
// };

// export const createTestUser = async (userId, token) => {
//   try {
//     const response = await gradeApi.get({path: '/create_test_user',
//       config: {headers: {
//         Authorization: `Bearer ${token}`,
//       }},
//     });
//     return response.data;
//   } catch (error) {
//     return { error: "Error in Backend" };
//   }
// };
