import {default as React, useRef, useState} from 'react';
import useOnClickOutside from '../../../shared/hooks/use-on-click-outside';

export function ImageWithPopup({image}) {
    const ref = useRef();
    const containerRef = useRef();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({x: 0, y: 0});
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({x: 0, y: 0});

    useOnClickOutside(ref, () => {
        setIsDialogOpen(false);
        setScale(1);
        setPosition({x: 0, y: 0});
    });

    // Handle two-finger scroll/pinch
    const handleTouchStart = (e) => {
        if (e.touches.length === 2) {
            e.preventDefault();
        }
    };

    const handleWheel = (e) => {
        if (e.ctrlKey) {
            e.preventDefault();
            const delta = e.deltaY * -0.01;
            const newScale = Math.min(Math.max(0.5, scale + delta), 4);
            setScale(newScale);
        } else {
            // Allow normal scrolling when not zooming
            containerRef.current?.scrollBy({
                top: e.deltaY,
                behavior: 'smooth'
            });
        }
    };

    const handleMouseDown = (e) => {
        if (e.button === 0) {
            setIsDragging(true);
            setDragStart({
                x: e.clientX - position.x,
                y: e.clientY - position.y
            });
        }
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            // Get container and image dimensions
            const container = ref.current;
            const image = container.querySelector('img');
            if (!container || !image) {
                return;
            }

            const containerRect = container.getBoundingClientRect();
            const imageRect = image.getBoundingClientRect();

            // Calculate bounds
            const maxX = Math.max(0, (imageRect.width * scale - containerRect.width) / 2);
            const maxY = Math.max(0, (imageRect.height * scale - containerRect.height) / 2);

            // Calculate new position
            const newX = e.clientX - dragStart.x;
            const newY = e.clientY - dragStart.y;

            // Constrain position within bounds
            setPosition({
                x: Math.min(Math.max(newX, -maxX), maxX),
                y: Math.min(Math.max(newY, -maxY), maxY)
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleZoomIn = () => {
        setScale(prev => Math.min(prev + 0.5, 4));
    };

    const handleZoomOut = () => {
        setScale(prev => Math.max(prev - 0.5, 0.5));
    };

    const handleReset = () => {
        setScale(1);
        setPosition({x: 0, y: 0});
    };

    const moveLeft = () => {
        setPosition(prev => ({
            ...prev,
            x: prev.x + 100
        }));
    };

    const moveRight = () => {
        setPosition(prev => ({
            ...prev,
            x: prev.x - 100
        }));
    };

    return (
        <div className="relative mb-4 mt-2 flex items-center">
            <img
                src={image}
                alt="Image"
                className="w-20 h-20 object-cover hover:cursor-pointer hover:opacity-90 transition-opacity"
                onClick={() => setIsDialogOpen(true)}
            />

            {isDialogOpen && (
                <div
                    ref={containerRef}
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    onTouchStart={handleTouchStart}
                >
                    <div ref={ref}
                         className="bg-white p-6 rounded-xl shadow-lg relative max-w-[90vw] max-h-[90vh] overflow-hidden">
                        <div
                            className="overflow-hidden"
                            onWheel={handleWheel}
                        >
                            <img
                                src={image}
                                alt="Image"
                                className="max-w-full max-h-[80vh] object-contain cursor-grab select-none"
                                style={{
                                    transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
                                    transition: isDragging ? 'none' : 'transform 0.1s',
                                }}
                                draggable={false}
                                onMouseDown={handleMouseDown}
                            />
                        </div>

                        {/* Navigation arrows */}
                        <button
                            className="absolute left-4 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
                            style={{display: scale > 1 ? 'block' : 'none'}}
                            onClick={moveLeft}
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"/>
                            </svg>
                        </button>

                        <button
                            className="absolute right-4 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
                            style={{display: scale > 1 ? 'block' : 'none'}}
                            onClick={moveRight}
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                            </svg>
                        </button>

                        {/* Zoom controls */}
                        <div
                            className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex gap-2 bg-white/30 backdrop-blur-sm rounded-full shadow-lg p-2">
                            <button
                                className="p-2 hover:bg-white/40 rounded-full"
                                title="Zoom out"
                                onClick={handleZoomOut}
                            >
                                <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4"/>
                                </svg>
                            </button>
                            <button
                                className="p-2 hover:bg-white/40 rounded-full"
                                title="Reset zoom"
                                onClick={handleReset}
                            >
                                <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v16h16"/>
                                </svg>
                            </button>
                            <button
                                className="p-2 hover:bg-white/40 rounded-full"
                                title="Zoom in"
                                onClick={handleZoomIn}
                            >
                                <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M12 4v16M4 12h16"/>
                                </svg>
                            </button>
                        </div>

                        {/* Close button */}
                        <button
                            className="absolute top-2 right-2 p-2 hover:bg-gray-100 rounded-full"
                            onClick={() => setIsDialogOpen(false)}
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}