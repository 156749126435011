import {Settings} from 'lucide-react';
import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import {getSubmissionTypeText} from '../../utils/get-submission-test.js';
import ConfirmationModal from '../ConfirmationModal';
import DueTimeDisplay from '../DueTimeDisplay.js';
import CloneModal from './TCloneModal';
import Modal from './TModal';
import MoreButton from './TMore';

function TAssignments(props) {
    const [assignmentName, setAssignmentName] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [dueTime, setDueTime] = useState('');
    const [timezone, setTimezone] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState();
    const [studentSubmitted, setStudentSubmitted] = useState('');
    const [format, setFormat] = useState(null); // New state for essay/problem set
    const [className, setClassName] = useState('');
    const [assignments, setAssignments] = useState([]);
    const {user} = useAuth();
    const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
    const [cloneAssignmentId, setCloneAssignmentId] = useState(null);
    const [cloneAssignmentName, setCloneAssignmentName] = useState('');
    const [originalCloneName, setOriginalCloneName] = useState('');
    const [cloneDueDate, setCloneDueDate] = useState('');
    const [cloneDueTime, setCloneDueTime] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);
    const [cloneSubmissionType, setCloneSubmissionType] = useState('');
    const [classOpen, setClassOpen] = useState(false);
    const [code, setCode] = useState('');
    const [cloneClassId, setCloneClassId] = useState(props.class_id);
    const [userClasses, setUserClasses] = useState([]);
    const [isGroupSubmission, setIsGroupSubmission] = useState(false);
    const [shareCode, setShareCode] = useState('');
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [cloneAssignmentType, setCloneAssignmentType] = useState(null);
    const [isCloneEssay, setIsCloneEssay] = useState(false);
    const [isCloneFlexible, setIsCloneFlexible] = useState(false);
    const [isOpenToManage, setIsOpenToManage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleShareAssignment = async assignmentId => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignmentId}/share-code/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setShareCode(response.data.share_code);
            setIsShareModalOpen(true);
        } catch (error) {
            setErrorMessage(
                error.response?.data?.error || 'An error occurred while getting the share code.'
            );
        }
    };

    const handleRenameSubmit = async (assignmentId, newName) => {
        // console.log('Renaming assignment:', assignmentId, 'to:', newName);

        if (newName.trim() === '') {
            setErrorMessage('Assignment name cannot be empty.');
            return;
        }

        if (newName.length > 70) {
            setErrorMessage('Assignment name must not exceed 70 characters.');
            return;
        }

        try {
            await gradeApi.patch({
                path: `/api/assignment/${assignmentId}/rename/`,
                body: {title: newName},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            getAssignments(); // Refresh the assignments list
            setErrorMessage(''); // Clear any existing error messages
        } catch (error) {
            setErrorMessage(
                error.response?.data?.error || 'An error occurred while renaming the assignment.'
            );
        }
    };
    const getUserClasses = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/users/${user.user.id}/courses-lite/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setUserClasses(response.data.courses);
            // console.log(response);
        } catch (error) {
            console.error('Error fetching user classes:', error);
        }
    };

    const handleOutsideClick = (e, ref) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setIsModalOpen(false);
        }
    };

    const confirmCloneAssignment = async () => {
        try {
            if (cloneAssignmentName.length > 70) {
                setErrorMessage('Assignment name must not exceed 70 characters.');
                return;
            }

            if (cloneSubmissionType == 'student' && (!cloneDueDate || !cloneDueTime)) {
                setErrorMessage('Please set both due date and time for student submissions');
                return;
            }

            // Get the original assignment details first
            const detailsResponse = await gradeApi.get({
                path: `/api/assignment/${cloneAssignmentId}/share-details/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const {type} = detailsResponse.data;

            // Create new assignment
            const response = await gradeApi.post({
                path: `/api/v2/courses/${cloneClassId}/assignment`,
                body: {
                    title: cloneAssignmentName,
                    is_exam: cloneSubmissionType === 'teacher',
                    is_group_task: isGroupSubmission,
                    type: type,
                    due_date: cloneSubmissionType == 'teacher' ? '9999-9-20' : cloneDueDate.trim(),
                    due_time: cloneSubmissionType == 'teacher' ? '09:09' : cloneDueTime.trim()
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            // Get and save draft data based on type
            let draftResponse;
            if (type === 'flexible_problem_set') {
                draftResponse = await gradeApi.get({
                    path: `/api/v2/assignments/${cloneAssignmentId}/flexible-problem-set/draft`,
                    config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                });

                await gradeApi.post({
                    path: `/api/v2/assignments/${response.data.id}/flexible-problem-set/draft`,
                    body: draftResponse.data,
                    config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                });
            } else {
                const draftPath = type === 'essay' ? 'essay' : 'problem-set';
                draftResponse = await gradeApi.get({
                    path: `/api/assignment/${cloneAssignmentId}/${draftPath}/load-draft/`,
                    config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                });

                if (type === 'essay') {
                    const {criteria, instructions, gradingInstructions, showRubric} = JSON.parse(
                        draftResponse.data.draft_data
                    );
                    await gradeApi.post({
                        path: `/api/assignment/${response.data.id}/essay/save-draft/`,
                        body: {
                            criteria,
                            instructions,
                            editableInstructions: instructions,
                            gradingInstructions,
                            showRubric
                        },
                        config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                    });
                } else {
                    const draft = draftResponse.data;
                    console.log(draftResponse);
                    await gradeApi.post({
                        path: `/api/assignment/${response.data.id}/problem-set/save-draft/`,
                        body: {
                            examPaper: draft.examPaper,
                            instructions: draft.instructions,
                            editableInstructions: draft.instructions,
                            gradingInstructions: draft.gradingInstructions,
                            feedbackInstructions: draft.feedbackInstructions || 'detailed'
                        },
                        config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                    });
                }
            }

            getAssignments();
            setIsCloneModalOpen(false);
        } catch (error) {
            setErrorMessage(error.response?.data?.error || 'An error occurred. Please try again.');
        }
    };

    // Update handleCloneAssignment to properly initialize date/time
    const handleCloneAssignment = async assignmentId => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignmentId}/share-details/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            const assignmentDetails = response.data;
            const type = assignmentDetails.type;

            setCloneAssignmentId(assignmentId);
            setCloneAssignmentName(`Copy of ${assignmentDetails.title}`);
            setFormat(type);
            setCloneAssignmentType(type);
            setIsCloneEssay(type === 'essay');
            setIsCloneFlexible(type === 'flexible_problem_set');

            const truncatedTitle =
                assignmentDetails.title.length > 20
                    ? assignmentDetails.title.substring(0, 20) + '...'
                    : assignmentDetails.title;
            setOriginalCloneName(truncatedTitle);

            setCloneDueDate('');
            setCloneDueTime('');

            const submissionType =
                type !== 'pdf_problem_set' ? '' : assignmentDetails.is_exam ? 'teacher' : 'student';

            setCloneSubmissionType(submissionType);
            setCloneClassId(props.class_id);
            setErrorMessage('');
            setIsCloneModalOpen(true);
        } catch (error) {
            setErrorMessage(error.response?.data?.error || 'Failed to fetch assignment details');
        }
    };
    // create a ref for the modal
    const modalRef = useRef();

    // dismissal tracking
    useEffect(() => {
        const handleClickOutside = event => {
            handleOutsideClick(event, modalRef);
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef]);

    const getAssignments = async () => {
        setIsLoading(true);
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${props.class_id}/teacher-assignments-lite/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log(response);
            setAssignments(response.data.assignments);
            setClassName(response.data.class_name);
            setClassOpen(response.data.class_open);
            setCode(response.data.class_code);
            setIsOpenToManage(response.data.isOpenToManage);
            // console.log(response.data.class_open);
            setTimezone(response.data.timezone);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.response.data.error || 'An error occurred. Please try again.');
        }
    };

    useEffect(() => {
        getAssignments();
        getUserClasses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async e => {
        e.preventDefault();

        // console.log(assignmentName.length)
        // Check assignment name length
        if (assignmentName.length > 70) {
            setErrorMessage('Assignment name must not exceed 70 characters.');
            return;
        }

        try {
            await gradeApi.post({
                path: `/api/v2/courses/${props.class_id}/assignment`,
                body: {
                    title: assignmentName,
                    is_exam: studentSubmitted === 'teacher',
                    is_group_task: isGroupSubmission,
                    type: format,
                    due_date: studentSubmitted == 'student' ? dueDate : '9999-9-20',
                    due_time: studentSubmitted == 'student' ? dueTime : '16:13'
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log(response);
            getAssignments();
            setIsModalOpen(false);
        } catch (error) {
            // console.log(error);
            setErrorMessage(error.response?.data?.error || 'An error occurred. Please try again.');
        }
    };

    const handleDeleteAssignment = assignmentId => {
        setSelectedAssignmentId(assignmentId);
        setIsConfirmationModalOpen(true);
    };

    const confirmDeleteAssignment = async () => {
        try {
            await gradeApi.delete({
                path: `/api/courses/${props.class_id}/assignment/${selectedAssignmentId}/`,
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                }
            });
            getAssignments();
            setIsConfirmationModalOpen(false);
        } catch (error) {
            setErrorMessage(error.response.data.error);
        }
    };

    const actuallyChangeClassOpen = async value => {
        // console.log(value);
        try {
            await gradeApi.post({
                path: `/api/courses/${props.class_id}/open/`,
                body: {openValue: value},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
        } catch (error) {
            setErrorMessage(error);
        }
    };

    const handleChangeClassOpen = event => {
        const newValue = event.target.checked;
        setClassOpen(newValue);
        actuallyChangeClassOpen(newValue);
    };
    const AssignmentCard = ({
                                id,
                                title,
                                num_submissions,
                                num_students,
                                due_date,
                                due_time,
                                timezone,
                                is_exam,
                                type,
                                is_group_task,
                                is_assignment_published,
                                is_grades_published,
                                handleRenameSubmit,
                                setErrorMessage,
                                isOpenToManage
                            }) => {
        const [myBool, setmyBool] = useState(true);
        const [isEditing, setIsEditing] = useState(false);
        const [newName, setNewName] = useState(title);

        const handleCardClick = (e) => {
            // If clicking the more options area or its children, prevent navigation
            if (e.target.closest('.more-options-area')) {
                e.preventDefault();
                return;
            }

            // If editing, prevent navigation
            if (isEditing) {
                e.preventDefault();
                return;
            }
        };

        function toggleBool() {
            setmyBool(!myBool);
        }

        const handleRenameClick = () => {
            setIsEditing(true);
            setNewName(title);
        };

        const handleSaveClick = async () => {
            try {
                await handleRenameSubmit(id, newName);
                setIsEditing(false);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };

        const handleCancelClick = () => {
            setIsEditing(false);
            setNewName(title);
        };

        const getPublicationStatusClass = () => {
            if (is_grades_published) {
                return 'bg-orange-200';
            }
            if (is_assignment_published) {
                return 'bg-gray-300';
            }
            return 'bg-transparent';
        };

        return (
            <Link to={`/tclasses/${props.class_id}/tassignments/${id}/tsubmissions`} onClick={handleCardClick}>
                <div
                    className="flex flex-col justify-between p-4 h-40 bg-white sexy-border relative transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow overflow-hidden">
                    {isEditing ? (
                        <div className="more-options-area flex items-center">
                            <input
                                type="text"
                                value={newName}
                                className="text-base font-medium text-black w-full mr-2 p-1 border rounded focus:outline-none focus:ring-2 focus:ring-orange-200 focus:border-orange-200"
                                autoFocus
                                onChange={e => setNewName(e.target.value)}
                            />
                            <button
                                className="rounded-3xl border-[1px] border-black transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black px-2 py-1 mr-2 text-sm"
                                onClick={handleSaveClick}
                            >
                                Save
                            </button>
                            <button
                                className="rounded-3xl border-[1px] border-black transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white-200 hover:bg-gray-300 text-black px-2 py-1 mr-2 text-sm"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </button>
                        </div>
                    ) : (
                        <div className="text-base font-medium text-black line-clamp-2">{title}</div>
                    )}

                    <div className={`flex flex-col ${!is_exam ? 'space-y-1' : 'space-y-0'}`}>
                        <div className="flex">
                            {!is_exam ? (
                                <div className="flex">
                                    <div className="text-gray-900 text-smallish truncate mr-1">Due:</div>
                                    <DueTimeDisplay dueTime={due_time} dueDate={due_date} timezone={timezone}/>
                                </div>
                            ) : (
                                <div className="flex">
                                    <div className="text-gray-900 text-smallish truncate mr-1">Due:</div>
                                    <div className="text-gray-900 text-smallish truncate mr-1">No due date</div>
                                </div>
                            )}
                        </div>
                        <div className={`text-gray-900 text-smallish truncate ${is_exam ? 'mt-1' : ''}`}>
                            {getSubmissionTypeText(is_exam, type, is_group_task)}
                        </div>
                    </div>

                    <div className="flex justify-between items-center space-x-4">
                        <div className="flex items-center space-x-1 truncate">
                            <div className="flex items-center space-x-1 truncate">
                                {is_group_task ? (
                                    <div className="text-black font-semibold text-sm">{num_submissions} </div>
                                ) : (
                                    <div className="text-black font-semibold text-sm">
                                        {num_submissions}/{num_students}
                                    </div>
                                )}
                                {is_group_task ? (
                                    <div className="text-gray-900 text-xs">groups submitted</div>
                                ) : (
                                    <div className="text-gray-900 text-xs">submitted</div>
                                )}
                            </div>
                        </div>
                        <div className="more-options-area">
                            <MoreButton
                                isOpenToManage={isOpenToManage}
                                myBool={myBool}
                                toggleBool={toggleBool}
                                id={id}
                                handleCloneAssignment={handleCloneAssignment}
                                handleDeleteAssignment={handleDeleteAssignment}
                                handleRenameAssignment={handleRenameClick}
                                handleShareAssignment={handleShareAssignment}
                            />
                        </div>
                    </div>

                    <div
                        className={`absolute bottom-0 right-0 w-8 h-8 rounded-tl-full ${getPublicationStatusClass()}`}
                    ></div>
                </div>
            </Link>
        );
    };

    const AssignmentList = ({assignments, isOpenToManage}) => {
        const sortedAssignments = assignments?.sort((a, b) => b.id - a.id);
        return (
            <div className="container mx-auto mt-6 relative pb-16">
                {' '}
                {/* Added relative and pb-16 */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 min-w-20">
                    {sortedAssignments && sortedAssignments.length > 0 ? (
                        sortedAssignments.map(value => (
                            <AssignmentCard
                                key={value.id}
                                id={value.id}
                                isOpenToManage={isOpenToManage}
                                title={value.title}
                                num_submissions={value.num_submissions}
                                num_students={value.course.num_students}
                                due_date={value.due_date}
                                due_time={value.due_time}
                                timezone={value.timezone}
                                is_exam={value.is_exam}
                                type={value.type}
                                is_group_task={value.is_group_task}
                                is_assignment_published={value.is_assignment_published}
                                is_grades_published={value.is_grades_published}
                                setErrorMessage={setErrorMessage}
                                handleRenameSubmit={handleRenameSubmit}
                            />
                        ))
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>
        );
    };

    const AssignmentRow = ({assignmentInfo, isOpenToManage}) => {
        const [myBool, setmyBool] = React.useState(true);

        function toggleBool() {
            setmyBool(!myBool);
        }

        return (
            <div className="hover:border rounded-full py-0.5 px-3 transition-all duration-100 desktop:hover:shadow-sm">
                <div className="grid grid-cols-5 align-middle">
                    <div className="classes-table-content">
                        <Link
                            to={`/tclasses/${props.class_id}/tassignments/${assignmentInfo.id}/tsubmissions`}
                            className="classes-table-content"
                        >
                            {assignmentInfo.title}
                        </Link>
                    </div>
                    <div className="classes-table-content">
                        {assignmentInfo.type === 'essay'
                            ? 'Essay'
                            : assignmentInfo.is_exam
                                ? 'Teacher'
                                : 'Student'}
                    </div>
                    <div className="classes-table-content">
                        {assignmentInfo.is_exam ? 'Teacher' : 'Student'}
                    </div>
                    <DueTimeDisplay
                        dueTime={assignmentInfo.due_time}
                        dueDate={assignmentInfo.due_date}
                        timezone={assignmentInfo.timezone}
                    />
                    <div className="classes-table-content text-right">
                        {assignmentInfo.num_submissions}/{assignmentInfo.course.num_students}
                    </div>
                    <div className="classes-table-content text-right flex justify-end p-2">
                        <MoreButton
                            isOpenToManage={isOpenToManage}
                            myBool={myBool}
                            toggleBool={toggleBool}
                            id={assignmentInfo.id}
                        />
                    </div>
                </div>
            </div>
        );
    };

    // ClassTable component
    const AssignmentTable = ({assignments, isOpenToManage}) => {
        const sortedAssignments = assignments?.sort((a, b) => b.id - a.id);
        return (
            <div className="min-w-full">
                <div className="grid grid-cols-5 gap-4 mb-6 p-2 font-bold border-b border-black">
                    <div className="classes-column-header">Assignment</div>
                    <div className="classes-column-header">Submission Type</div>
                    <div className="classes-column-header">Due Date</div>
                    <div className="classes-column-header text-right">Submissions</div>
                    <div className="classes-column-header text-right">Actions</div>
                </div>
                {sortedAssignments ? (
                    <div>
                        {sortedAssignments.map(value, i => (
                            <AssignmentRow
                                key={`${i} + ${Math.random()}`}
                                assignmentInfo={value}
                                isOpenToManage={isOpenToManage}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="text-center">No Assignments available</div>
                )}
            </div>
        );
    };

    const [cardView, setCardView] = useState(true); // Changed to camelCase to follow convention

    // Corrected typo in function name
    function toggleCardView() {
        setCardView(!cardView);
    }

    // Your changeView function remains unchanged
    const changeView = ({assignments, isOpenToManage}) => {
        // console.log(assignments);
        return cardView ? (
            <AssignmentList assignments={assignments} isOpenToManage={isOpenToManage}/>
        ) : (
            <AssignmentTable assignments={assignments} isOpenToManage={isOpenToManage}/>
        );
    };

    const [dateFocused, setDateFocused] = useState(false);
    const [timeFocused, setTimeFocused] = useState(false);
    return (
        <div id="HomeTeacher" className="flex flex-row p-8 justify-start h-screen">
            <div id="Main-Content-container" className="flex flex-col w-full relative">
                {/* Sticky header - now matching content width */}
                <div className="fixed top-8 bg-white z-50" style={{width: 'calc(56%)'}}>
                    <div id="Overview" className="flex flex-col space-y-2">
                        <div className="flex flex-row justify-between items-center">
                            <div className="flex flex-row items-center">
                                <Link to="/tclasses">
                                    <span className="text-black text-xl font-medium">My Classes</span>
                                </Link>
                                <Link to="/tclasses">
                                    <div className="size-6 flex items-center justify-center">
                                        <button>
                                            <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                                        </button>
                                    </div>
                                </Link>
                                <h1 className="text-black text-xl font-medium ml-4">
                                    {className ? (
                                        className.length > 15
                                            ? `${className.substring(0, 15)}...`
                                            : className
                                    ) : ''}
                                </h1>
                            </div>

                            <div className="flex items-center space-x-4">
                                {!isLoading &&
                                    (isOpenToManage ? (
                                        <span
                                            className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-red-100 text-red-800">
                      Lead Instructor
                    </span>
                                    ) : (
                                        <span
                                            className="px-4 inline-flex text-xs leading-4 font-semibold rounded-full bg-green-100 text-green-800">
                      Co-Instructor
                    </span>
                                    ))}
                                <button
                                    className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                                    onClick={() => {
                                        /* Add your settings handler here */
                                    }}
                                >
                                    <Link to={`/csettings/${props.class_id}`}>
                                        <Settings className="w-5 h-5 text-gray-600"/>
                                    </Link>
                                </button>
                                {isOpenToManage && (
                                    <button
                                        className="sexy-button transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow h-9 w-36 px-4 py-2 flex items-center justify-center"
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        New Assignment
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className="flex justify-between">
                            <button
                                className="group flex items-center gap-2 px-4 py-2 bg-white rounded-full
                                                text-sm text-gray-700 border border-gray-200 hover:border-orange-200
                                                hover:bg-orange-50 transition-all duration-200"
                                onClick={() => window.open('https://www.loom.com/share/126e504fc9fd4c41a5fc64b40e80ee93?sid=c64acace-dd39-4ce9-8424-6292369242c3', '_blank')}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    className="w-4 h-4 text-gray-500 group-hover:text-orange-500 transition-colors"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M10 9v6l5-3-5-3z"/>
                                    <circle cx="12" cy="12" r="10"/>
                                </svg>
                                Tutorial
                            </button>
                            <div className="flex items-center bg-grey-700 rounded-full p-2 shadow-md">
                                <input
                                    type="checkbox"
                                    id="openClassCheckBox"
                                    checked={classOpen}
                                    className="sr-only peer"
                                    onChange={handleChangeClassOpen}
                                />
                                <label
                                    htmlFor="openClassCheckBox"
                                    className={`
                  relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out mr-2 cursor-pointer
                  ${classOpen ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}
                `}
                                >
                  <span
                      className={`
                    inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
                    ${classOpen ? 'translate-x-6' : 'translate-x-1'}
                  `}
                  />
                                </label>
                                <label htmlFor="openClassCheckBox" className="text-sm font-medium text-black">
                                    Join by Class Code ({code})
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Scrollable content */}
                <div className="mt-28 overflow-y-auto hide-scrollbar">
                    {changeView({assignments, isOpenToManage})}
                </div>

                {isCloneModalOpen && (
                    <CloneModal
                        isOpen={isCloneModalOpen}
                        assignmentName={cloneAssignmentName}
                        setAssignmentName={setCloneAssignmentName}
                        dueDate={cloneDueDate}
                        dueTime={cloneDueTime}
                        setDueTime={setCloneDueTime}
                        timezone={timezone}
                        setDueDate={setCloneDueDate}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        originalCloneName={originalCloneName}
                        submissionType={cloneSubmissionType}
                        setSubmissionType={setCloneSubmissionType}
                        isEssay={isCloneEssay} // Use the new state variable
                        isFlexible={isCloneFlexible} // Use the new state variable
                        cloneClassId={cloneClassId}
                        setCloneClassId={setCloneClassId}
                        userClasses={userClasses}
                        isGroupSubmission={isGroupSubmission}
                        setIsGroupSubmission={setIsGroupSubmission}
                        onClose={() => setIsCloneModalOpen(false)}
                        onConfirm={confirmCloneAssignment}
                    />
                )}
                {isModalOpen && (
                    <Modal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        handleSubmit={handleSubmit}
                        assignmentName={assignmentName}
                        setAssignmentName={setAssignmentName}
                        format={format}
                        setFormat={setFormat}
                        dueDate={dueDate}
                        setDueDate={setDueDate}
                        dueTime={dueTime}
                        timezone={timezone}
                        setDueTime={setDueTime}
                        studentSubmitted={studentSubmitted}
                        setStudentSubmitted={setStudentSubmitted}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        dateFocused={dateFocused}
                        setDateFocused={setDateFocused}
                        timeFocused={timeFocused}
                        setTimeFocused={setTimeFocused}
                        modalRef={modalRef}
                        isGroupSubmission={isGroupSubmission}
                        setIsGroupSubmission={setIsGroupSubmission}
                        handleCloneAssignment={handleCloneAssignment}
                    />
                )}
                <ConfirmationModal
                    isOpen={isConfirmationModalOpen}
                    message="Are you sure you want to delete this assignment? This action cannot be undone."
                    onClose={() => setIsConfirmationModalOpen(false)}
                    onConfirm={confirmDeleteAssignment}
                />
                <ConfirmationModal
                    isOpen={isShareModalOpen}
                    message={
                        <div className="space-y-4">
                            <h2 className="text-lg font-semibold">Assignment Sharing</h2>
                            <p>
                                Share this assignment with a peer. To access this assignment, they should open
                                GradeWiz on their end, click "New Assignment," select "Import Shared Assignment" and
                                enter the code below:
                            </p>
                            <div className="bg-gray-100 p-4 rounded-md text-center">
                                <span className="text-xl font-mono font-semibold">{shareCode}</span>
                            </div>
                        </div>
                    }
                    showCancelButton={false}
                    onClose={() => setIsShareModalOpen(false)}
                    onConfirm={() => setIsShareModalOpen(false)}
                />
            </div>
        </div>
    );
}

export default TAssignments;
