import {FieldArray, Form, Formik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';

import {isEqual} from 'lodash';
import {default as React, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import * as Yup from 'yup';
import {gradeApi} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext.js';
import {SQuestionType} from './SQuestionType.js';
import {SubQuestion} from './SubQuestion.js';

import {FormikSubmitEffect} from '../../../components/Teacher/TPset/FormikSubmitEffect';
import {getFieldName} from '../../../components/Teacher/TPset/utils.js';
import {
    clearSubmissionData,
    loadDraftData,
    loadPublishedData,
    loadPublishedQuestionData,
    publishData,
    updateDraftData
} from '../../../store/studentSlice.js';
import ConfirmationModal from '../../ConfirmationModal.js';

import DueTimeDisplay from '../../DueTimeDisplay.js';
import AddStudentsToSubmission from '../AddStudentsToSubmission';

function SUnpublishedFlexibleAssignment({
                                            assignment_id,
                                            assignment,
                                            class_id,
                                            isReadMode = false,
                                            isPregradeMode = false,
                                            studentId,
                                            questionId
                                        }) {
    const {user} = useAuth();
    const dispatch = useDispatch();
    const [showSavedPopup, setShowSavedPopup] = useState(false);
    const [showErrorMessagePopup, setShowErrorMessagePopup] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isPolling, setIsPolling] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isGroupAdmin, setIsGroupAdmin] = useState(false);
    const [submissionData, setSubmissionData] = useState({});
    const [allSubmissionsData, setAllSubmissionsData] = useState([]);
    const [showSubmissionLog, setShowSubmissionLog] = useState(false);
    const [hoveredUser, setHoveredUser] = useState(null);

    const pollingIntervalRef = useRef(null);

    const {
        draftData: {data: draftData, isLoading: isDraftDataLoading, errorMessage}
    } = useSelector(state => state?.studentReducer);

    useEffect(() => {
        if (isReadMode) {
            if (questionId) {
                dispatch(
                    loadPublishedQuestionData({
                        assignment_id,
                        token: user.session_token,
                        questionId,
                        studentId: studentId || user.user.id
                    })
                );
            } else {
                dispatch(
                    loadPublishedData({
                        assignment_id,
                        token: user.session_token,
                        studentId: studentId || user.user.id
                    })
                );
            }
        } else {
            dispatch(
                loadDraftData({
                    assignment_id,
                    token: user.session_token,
                    studentId: studentId || user.user.id
                })
            );
        }

        return () => dispatch(clearSubmissionData());
    }, [isReadMode]);

    useEffect(() => {
        if (errorMessage) {
            setShowErrorMessagePopup(true);
            setTimeout(() => setShowErrorMessagePopup(false), 2000);
        }
    }, [errorMessage]);

    useEffect(() => {
        getAssignmentSubmission();
        return () => {
            stopPolling();
        };
    }, []);

    const startPolling = submissionId => {
        if (isPolling) {
            return; // Prevent multiple polling intervals
        }
        setIsPolling(true);
        pollingIntervalRef.current = setInterval(() => {
            checkGradingStatus(submissionId);
        }, 5000);
    };

    const stopPolling = () => {
        setIsPolling(false);
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
        }
    };

    const checkGradingStatus = async submissionId => {
        try {
            const response = await gradeApi.get({
                path: `/api/submission/${submissionId}/grading-status/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            if (response.data.is_grading_done) {
                stopPolling();
                setMessage('Grading completed. Refreshing submission data...');
                window.location.reload();
                getAssignmentSubmission();
            }
        } catch (error) {
            console.error('Error checking grading status:', error);
            stopPolling();
            setMessage('Error checking grading status. Please refresh the page.');
        }
    };

    const handleChange = async ({fieldName, value}) => {
        console.log(value);
        console.log(fieldName);
        if (!isEqual(draftData[fieldName], value)) {
            console.log(draftData);
            const result = await dispatch(
                updateDraftData({
                    token: user.session_token,
                    assignment_id,
                    dataToSave: {
                        [fieldName]: value,
                        instructions: draftData.instructions
                    }
                })
            );

            if (!result.error) {
                setShowSavedPopup(true);
                setTimeout(() => setShowSavedPopup(false), 2000);
                return true;
            } else {
                return false;
            }
        }
    };

    const publishSubmission = async (isResubmission = false) => {
        await handleChange({
            fieldName: 'examPaper',
            value: draftData.examPaper
        });

        try {
            setMessage(
                isResubmission
                    ? 'Resubmission in progress... please wait'
                    : 'Submission in progress... please wait'
            );
            setIsButtonDisabled(true);

            const response = await dispatch(
                publishData({
                    assignment_id,
                    token: user.session_token
                })
            );

            if (response.type === 'studentSlice/publishData/fulfilled') {
                startPolling(response.payload.id);
            }

        } catch (error) {
            console.error(
                isResubmission ? 'Error resubmitting assignment:' : 'Error submitting assignment:',
                error
            );
            setIsButtonDisabled(false);
            setMessage(error.response?.data?.error || 'An unknown error occurred');
        }
    };

    const validationSchema = Yup.object().shape({
        examPaper: Yup.array().of(
            Yup.object().shape({
                // answerImageS3Key: Yup.string()
                //   .nullable(true)
                //   .when('questionType', {
                //     is: val => val === 'image',
                //     then: () => Yup.string().nullable(false).required('This field is required!')
                //   }),
                answerText: Yup.string()
                    .nullable(true)
                    .when('questionType', {
                        is: val => val === 'text',
                        then: () => Yup.string().nullable(false).required('This field is required!')
                    }),
                answerMcqOptionId: Yup.string()
                    .nullable(true)
                    .when('questionType', {
                        is: val => val === 'mcq',
                        then: () => Yup.string().nullable(false).required('This field is required!')
                    }),

                subQuestions: Yup.array().of(
                    Yup.object().shape({
                        // answerImageS3Key: Yup.string()
                        //   .nullable(true)
                        //   .when('questionType', {
                        //     is: val => val === 'image',
                        //     then: () => Yup.string().nullable(false).required('This field is required!')
                        //   }),
                        answerText: Yup.string()
                            .nullable(true)
                            .when('questionType', {
                                is: val => val === 'text',
                                then: () => Yup.string().nullable(false).required('This field is required!')
                            }),
                        answerMcqOptionId: Yup.string()
                            .nullable(true)
                            .when('questionType', {
                                is: val => val === 'mcq',
                                then: () => Yup.string().nullable(false).required('This field is required!')
                            })
                    })
                )
            })
        )
    });

    const getAssignmentSubmission = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/course/${class_id}/assignment/${assignment.id}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            const data = response.data;
            if (data.is_grade_in_progres) {
                setMessage('Assignment is being graded... Please wait.');
                setIsButtonDisabled(true);
                if (data.id) {
                    startPolling(data.id);
                }
            } else {
                setIsButtonDisabled(false);
                setMessage('');
            }
            if (response.status !== 200) {
                setMessage('Failed to verify session. Please log out and back in.');
                setIsButtonDisabled(true);
            }

            // Set submissions array
            setAllSubmissionsData(data.submissions);

            // Find the latest submission using most_recent flag
            const latestSubmission = data.submissions.find(sub => sub.most_recent);
            setIsSubmitted(latestSubmission?.submitted ?? false);
            setSubmissionData(latestSubmission ?? {});
            setIsGroupAdmin(latestSubmission?.is_group_admin ?? false);

        } catch (error) {
            setIsButtonDisabled(false);
            setMessage(
                error.response?.data?.error || 'An error occurred while fetching submission data.'
            );
        }
    };

    const handleLeaveGroup = async () => {
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment.id}/leave-group/`,
                body: {},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setMessage('You have left the group. Refreshing...');
            getAssignmentSubmission();
        } catch (error) {
            setMessage('Error leaving group. Please try again.');
        }
    };

    const disabledStyle = isDraftDataLoading || isButtonDisabled ? '!opacity-65' : '';

    return (
        <div className="flex flex-col bg-white px-5 py-7">
            <div className="flex flex-col space-y-4 w-full">
                {!isReadMode && !isPregradeMode && (
                    <div className="flex flex-row items-center space-x-2 mb-4">
                        <Link to="/sclasses">
                            <div className="size-10 flex items-center justify-center bg-gray-50 rounded-full">
                                <img src="/assets/sidebar/menu-home.png" className="size-5" alt="Home"/>
                            </div>
                        </Link>
                        <Link to="/sclasses">
                            <div className="text-black text-lg font-medium ml-4">My Classes</div>
                        </Link>
                        <Link to="/sclasses">
                            <div className="size-6 flex items-center justify-center">
                                <img src="/assets/back_arrow.png" className="size-6" alt="Back Arrow"/>
                            </div>
                        </Link>
                        <Link to={`/sclasses/${class_id}/sassignments`}>
                            <div className="text-black text-lg font-medium ml-4">{assignment.course.name}</div>
                        </Link>
                        <Link to={`/sclasses/${class_id}/sassignments`}>
                            <div className="size-6 flex items-center justify-center">
                                <img src="/assets/back_arrow.png" className="size-6" alt="Back Arrow"/>
                            </div>
                        </Link>
                        <h1 className="text-black text-lg font-medium ml-4">{assignment.title}</h1>
                    </div>
                )}
                {/* Submission Log Section */}
                {!isReadMode && !isPregradeMode && (
                    <div className="flex flex-col w-full mb-5">
                        <button
                            type="button"
                            className="flex items-center text-sm mb-2"
                            onClick={() => {
                                setShowSubmissionLog(!showSubmissionLog);
                                console.log("ASSIGNMENT DETAILS BELOW");
                                console.log(assignment);
                            }}
                        >
                            <img
                                src="/assets/back_arrow.png"
                                className={`size-5 opacity-60 transform ${showSubmissionLog ? 'rotate-90' : '-rotate-90'} mr-2`}
                                alt="Toggle submission log"
                            />
                            Submission Log
                        </button>

                        {showSubmissionLog && (
                            <div className="flex flex-col w-full">
                                {allSubmissionsData?.length > 0 ? (
                                    allSubmissionsData.slice().reverse().map((submission, index) => (
                                        <div key={index} className="mb-2 ml-4">
                                            <p className="text-sm text-gray-700">
                                                {allSubmissionsData.length - index}. Submitted at{' '}
                                                {(new Date(submission.submission_date)).toLocaleString('en-US', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: true,
                                                    timeZone: user.user.timezone,
                                                    timeZoneName: 'short'
                                                })}{' '}
                                                by{' '}
                                                <span
                                                    className="relative cursor-pointer hover:text-gray-900"
                                                    onMouseEnter={() => setHoveredUser(submission)}
                                                    onMouseLeave={() => setHoveredUser(null)}
                                                >
                                                    <strong>{submission.submitter.is_teacher ? 'teacher' : 'student'}</strong>
                                                    {hoveredUser === submission && (
                                                        <div
                                                            className="absolute z-10 bg-black text-white p-2 rounded text-sm"
                                                            style={{
                                                                top: '100%',
                                                                left: '0',
                                                                marginTop: '5px',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            <div>ID: {submission.submitter.id}</div>
                                                            <div>Email: {submission.submitter.email}</div>
                                                        </div>
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-sm text-gray-700 ml-4">No submissions made yet.</p>
                                )}
                            </div>
                        )}
                    </div>
                )}

                <div
                    className={`bg-white border border-gray-200 rounded-lg shadow-sm p-6 ${disabledStyle}`}
                >
                    {draftData?.instructions && (
                        <p className="text-gray-600 mb-4 whitespace-pre-wrap">
                            {draftData?.instructions}
                        </p>
                    )}
                    <div className="flex">
                        <div className="text-gray-900 text-smallish truncate mr-1">Due:</div>
                        <DueTimeDisplay
                            dueTime={assignment.due_time}
                            dueDate={assignment.due_date}
                            timezone={assignment.timezone}
                        />
                    </div>
                </div>

                <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6">
                    <div className={`flex-grow container ${disabledStyle}`}>
                        <Formik
                            enableReinitialize
                            initialValues={{examPaper: draftData.examPaper ?? []}}
                            validationSchema={validationSchema}
                            validateOnChange={true}
                            validateOnBlur={true}
                            validateOnMount={true}
                            onSubmit={async (values, {setSubmitting}) => {
                                setSubmitting(true);
                                await handleChange({fieldName: 'examPaper', value: values.examPaper});
                                setSubmitting(false);
                            }}
                        >
                            {({values, isValid, errors}) => (
                                <Form>
                                    <FormikSubmitEffect
                                        onSubmitError={async () => {
                                            await handleChange({fieldName: 'examPaper', value: values.examPaper});
                                        }}
                                    />
                                    <div className="relative">
                                        <FieldArray name={'examPaper'}>
                                            {() => (
                                                <div>
                                                    {values.examPaper.map((question, index) => (
                                                        <>
                                                            <SQuestionType
                                                                key={`${question.id}-${index}`}
                                                                questionPoint={`${index + 1}. ${question.question}`}
                                                                disabledStyle={disabledStyle}
                                                                index={index}
                                                                question={question}
                                                                fieldName={'examPaper'}
                                                                isDisabled={isReadMode || isButtonDisabled}
                                                            />
                                                            {question.subQuestions.length > 0 && (
                                                                <div
                                                                    className="bg-white border border-gray-200 rounded-lg shadow-sm p-6 m-6">
                                                                    <SubQuestion
                                                                        subQuestions={question.subQuestions}
                                                                        disabledStyle={disabledStyle}
                                                                        isDisabled={isReadMode || isButtonDisabled}
                                                                        fieldName={getFieldName(index, 'subQuestions', 'examPaper')}
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            )}
                                        </FieldArray>
                                        {showSavedPopup && (
                                            <div
                                                className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded z-10">
                                                Progress saved
                                            </div>
                                        )}
                                        {showErrorMessagePopup && (
                                            <div
                                                className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded z-10">
                                                {errorMessage}
                                            </div>
                                        )}

                                        {message && <p className="text-red-500 mt-4">{message}</p>}
                                    </div>

                                    {assignment.allow_pregrade && (
                                        <div className="mt-4 mb-4">
                                            {!submissionData.submission_count && (
                                                <p className="text-green-400">
                                                    Professor enabled pre-grading, so you will be able to see the AI
                                                    feedback for your first submission.
                                                </p>
                                            )}
                                            {submissionData.submission_count > 1 && (
                                                <p className="text-red-400">
                                                    You have already used your pre-grading attempt. You will see
                                                    feedback for future submissions after the professor publishes
                                                    grades.
                                                </p>
                                            )}
                                        </div>
                                    )}

                                    {!isReadMode &&
                                        (assignment.is_group_task && isSubmitted ? (
                                            isGroupAdmin ? (
                                                <button
                                                    disabled={isReadMode || isButtonDisabled}
                                                    className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative float-right ${
                                                        isButtonDisabled
                                                            ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                            : 'bg-orange-200 hover:bg-orange-300 text-black'
                                                    }`}
                                                    onClick={() => {
                                                        console.log(isValid, errors);
                                                        return !isValid
                                                            ? setIsConfirmationModalOpen(true)
                                                            : publishSubmission(true);
                                                    }}
                                                >
                                                    Resubmit
                                                </button>
                                            ) : (
                                                <div className="mt-6">
                                                    <p className="text-gray-600">
                                                        You are not the group admin. Only the group admin can submit
                                                        assignments.
                                                    </p>
                                                    <button
                                                        className="mt-4 px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                                                        onClick={handleLeaveGroup}
                                                    >
                                                        Leave Group
                                                    </button>
                                                </div>
                                            )
                                        ) : (
                                            <>
                                                <button
                                                    disabled={isReadMode || isButtonDisabled}
                                                    className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative float-right ${
                                                        isButtonDisabled
                                                            ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                            : 'bg-orange-200 hover:bg-orange-300 text-black'
                                                    }`}
                                                    onClick={() => {
                                                        console.log(isValid, errors);
                                                        return !isValid
                                                            ? setIsConfirmationModalOpen(true)
                                                            : publishSubmission();
                                                    }}
                                                >
                                                    Submit
                                                </button>
                                                {isSubmitted &&
                                                    submissionData.is_grade_in_progres === false &&
                                                    !isButtonDisabled && (
                                                        <p className="text-orange-400 font-medium mt-4">
                                                            Your assignment has been graded, but you can still resubmit!
                                                            You will
                                                            see your grade once professor publishes grades.
                                                        </p>
                                                    )}
                                            </>
                                        ))}

                                    {isSubmitted && submissionData.is_group_task && isGroupAdmin && (
                                        <AddStudentsToSubmission aid={assignment.id}/>
                                    )}

                                    <ConfirmationModal
                                        isOpen={isConfirmationModalOpen}
                                        message="You have not answered all questions yet. Are you sure you’d like to submit?"
                                        onClose={() => setIsConfirmationModalOpen(false)}
                                        onConfirm={() => publishSubmission()}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SUnpublishedFlexibleAssignment;
