import { useEffect } from 'react';

import { useFormikContext } from 'formik';

export function FormikSubmitEffect({ onSubmitError }) {
  const { submitCount, isValid, isSubmitting } = useFormikContext();
  useEffect(() => {
    if (submitCount > 0 && !isSubmitting && !isValid) {
      onSubmitError();
    }
  }, [submitCount, isSubmitting]);
  return null;
}
