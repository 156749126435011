import { configureStore } from '@reduxjs/toolkit';
import flexibleAssignmentSlice from './flexibleAssignmentSlice';
import studentSlice from './studentSlice';
import teacherSlice from './teacherSlice';
import tpsetSlice from './tpsetSlice';
import userSlice from './userSlice';

const store = configureStore({
  reducer: {
    userReducer: userSlice,
    tpsetReducer: tpsetSlice,
    teacherReducer: teacherSlice,
    flexibleAssignmentReducer: flexibleAssignmentSlice,
    studentReducer: studentSlice
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(),
  devTools: process.env.NODE_ENV !== 'production'
});

export default store;
