import axios from 'axios';
import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect, useRef } from 'react';
import {
  getUser as getUserAction,
  login as loginAction,
  logout as logoutAction,
  setExistingUser,
  signUp as signUpAction
} from '../store/userSlice.js';

import { useDispatch, useSelector } from 'react-redux';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const latestSessionToken = useRef(null);
  const dispatch = useDispatch();

  const { user, isLoading } = useSelector(state => state?.userReducer);

  useEffect(() => {
    const checkCookiesAndUpdateSession = async () => {
      const sessionToken = Cookies.get('session_token');
      const userId = Cookies.get('user_id');
      const userEmail = Cookies.get('user_email');
      const isTeacher = Cookies.get('is_teacher');
      const firstName = Cookies.get('first_name');
      const lastName = Cookies.get('last_name');
      const isVerified = Cookies.get('is_verified');
      const timezone = Cookies.get('timezone');

      if (sessionToken && userId) {
        dispatch(
          setExistingUser({
            user: {
              id: userId,
              email: userEmail,
              first_name: firstName,
              last_name: lastName,
              is_verified: isVerified === 'true',
              is_teacher: isTeacher === 'true',
              timezone: timezone
            },
            isAuthenticated: true,
            loginRecent: false,
            session_token: sessionToken
          })
        );
        latestSessionToken.current = sessionToken;
      }
    };
    checkCookiesAndUpdateSession();
  }, []);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      async config => {
        // Skip session check for login and logout endpoints
        if (config.url.includes('/courses-lite/')) {
          await waitForSession();
        }
        return config;
      },
      error => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  const login = async (email, password) => {
    const result = await dispatch(loginAction({ email, password })).unwrap();
    if ('error' in result) {
      return result.error;
    }
    latestSessionToken.current = result.session_token;
    return result;
  };

  const logout = async () => {
    const sessionToken = Cookies.get('session_token');
    if (sessionToken) {
      dispatch(logoutAction({ token: sessionToken }));
    }

    latestSessionToken.current = null;
  };

  const signUp = async (firstName, lastName, email, password, is_teacher) => {
    const result = await dispatch(
      signUpAction({ firstName, lastName, email, password, is_teacher })
    );
    if ('error' in result) {
      return { error: result.payload.error, type: result.payload.type };
    }
    return { message: result.payload.message, type: result.payload.type };
  };

  const waitForSession = () => {
    return new Promise(resolve => {
      const checkSession = () => {
        if (latestSessionToken.current) {
          resolve();
        } else {
          setTimeout(checkSession, 10);
        }
      };
      checkSession();
    });
  };

  const updateUser = async (userId, token) => {
    const result = await dispatch(getUserAction({ userId, token })).unwrap();
    latestSessionToken.current = result.session_token;
  };

  return (
    <AuthContext.Provider value={{ user, isLoading, login, signUp, logout, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
