import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import {getSubmissionTypeText} from '../../utils/get-submission-test.js';
import DueTimeDisplay from '../DueTimeDisplay.js';

function SAssignments(props) {
    const [className, setClassName] = useState('');
    const [assignments, setAssignments] = useState([]);
    const [error, setError] = useState('');
    const {user} = useAuth();
    const [cardView, setCardView] = useState(true);
    const [paymentRequired, setPaymentRequired] = useState(false);

    const getAssignments = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${props.class_id}/student-assignments/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setAssignments(response.data.assignments);
            setClassName(response.data.class_name);
        } catch (error) {
            setError('Failed to fetch assignments. Please try again.');
            console.error('Error fetching assignments:', error);
        }
    };

    const handlePaymentClick = async () => {
        try {
            const response = await gradeApi.post({
                path: `/api/payment/create-checkout/${props.class_id}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            if (response.data.url) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };
    // In your SAssignments component:
    const checkPaymentStatus = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${props.class_id}/payment-check/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            if (response.data.needs_payment) {
                setPaymentRequired(true);
            }
        } catch (error) {
            console.error('Error checking payment status:', error);
        }
    };
    useEffect(() => {
        getAssignments();
        checkPaymentStatus();
    }, []);

    function toggleCardView() {
        setCardView(!cardView);
    }

    const AssignmentCard = ({
                                id,
                                title,
                                due_date,
                                due_time,
                                timezone,
                                submitted,
                                is_exam,
                                type,
                                is_grades_published
                            }) => {
        return (
            <Link to={`/sclasses/${props.class_id}/sassignments/${id}/sdetails`}>
                <div
                    className="flex flex-col justify-between p-4 h-36 bg-white sexy-border relative transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow overflow-hidden">
                    <div className="text-base font-medium text-black line-clamp-2">{title}</div>
                    <div className="flex justify-between items-center mt-1">
                        <div className="flex">
                            <div className="text-gray-900 text-smallish truncate mr-1">Due:</div>
                            <DueTimeDisplay dueTime={due_time} dueDate={due_date} timezone={timezone}/>
                        </div>
                        <div className="text-gray-900 text-smallish truncate">
                            {getSubmissionTypeText(is_exam, type)}
                        </div>
                    </div>
                    <div className="flex justify-between items-center space-x-4">
                        <div className={`font-semibold text-sm ${submitted ? 'text-black' : 'text-orange-400'}`}>
                            {submitted ? 'Submitted' : 'Not Submitted'}
                        </div>
                    </div>
                    <div
                        className={`absolute bottom-0 right-0 w-8 h-8 rounded-tl-full ${is_grades_published ? 'bg-orange-200' : 'bg-transparent'}`}
                    ></div>
                </div>
            </Link>
        );
    };

    const AssignmentList = ({assignments}) => {
        const sortedAssignments = assignments?.sort((a, b) => b.id - a.id);
        return (
            <div className="container mx-auto mt-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 min-w-20">
                    {sortedAssignments && sortedAssignments.length > 0 ? (
                        [...sortedAssignments]
                            .map(value => (
                                <AssignmentCard
                                    key={value.id}
                                    id={value.id}
                                    title={value.title}
                                    due_date={value.due_date}
                                    due_time={value.due_time}
                                    timezone={value.timezone}
                                    submitted={value.submitted}
                                    is_exam={value.is_exam}
                                    type={value.type}
                                    is_grades_published={value.is_grades_published}
                                />
                            ))
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div id="HomeStudent" className="flex flex-row p-8 justify-start">
            <div id="Main-Content-container" className="flex flex-col space-y-4 w-full">
                <div id="Overview" className="flex flex-row space-x-full w-full justify-between">
                    <div className="flex flex-row items-center justify-center">
                        <Link to="/sclasses">
                            <span className="text-black text-xl font-medium">My Classes</span>
                        </Link>
                        <Link to="/sclasses">
                            <div className="size-6 flex items-center justify-center">
                                <button>
                                    <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                                </button>
                            </div>
                        </Link>
                        <h1 className="text-black text-xl font-medium ml-4">{className}</h1>
                    </div>
                </div>
                {paymentRequired && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                        <div className="bg-white p-8 rounded-lg max-w-md">
                            <h2 className="text-2xl font-bold mb-4">Payment Required</h2>
                            <p className="mb-6">
                                We are excited to have you join GradeWiz! Please make the payment to access your
                                assignments.
                            </p>
                            <button
                                className="sexy-button transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow"
                                onClick={handlePaymentClick}
                            >
                                Pay Now
                            </button>
                        </div>
                    </div>
                )}
                <div>
                    {error && <div className="text-red-500">{error}</div>}
                    <AssignmentList assignments={assignments}/>
                </div>
            </div>
        </div>
    );
}

export default SAssignments;
