import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import {Loading} from '../../components/Loading';
import DueTimeDisplayTable from '../DueTimeDisplayTable';
import Table from '../Table.js';
import TeacherStudentUpload from './TeacherStudentUpload'; // Import the new component

function TGrade({
                    isLoading,
                    pageNumber,
                    submissionsError,
                    submissions,
                    itemsPerPage,
                    assignment,
                    reviewMode,
                    setReviewMode,
                    updateSearchParams,
                    setUpdateTrigger
                }) {
    const {user} = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [regrades, setRegrades] = useState([]);

    useEffect(() => {
        getRegrades();
    }, []);

    useEffect(() => {
        if (submissionsError) {
            setErrorMessage(submissionsError);
        }
    }, [submissionsError]);

    const getRegrades = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/regrade-requests/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const regradesForTeacher = response.data;
            console.log(regradesForTeacher);
            setRegrades(regradesForTeacher);
        } catch (error) {
            console.log(error);
        }
    };

    const hasRegrade = submission => {
        for (var i = 0; i < regrades.length; i++) {
            if (regrades[i].id === submission.id) {
                return true;
            }
        }
        return false;
    };

    const handleUploadClick = studentId => {
        setSelectedStudentId(studentId);
        setShowUploadModal(true);
    };

    const handleCloseModal = () => {
        setShowUploadModal(false);
        setSelectedStudentId(null);
    };

    function process_string(input_) {
        // Check if input_ is an array
        if (!Array.isArray(input_)) {
            // console.error('Input is not an array');
            return '';
        }

        // Filter out any null or undefined values
        const filteredInput = input_.filter(item => item != null);

        // Join the array elements with ", " (comma and space)
        return filteredInput.join(', ');
    }

    const columnNames = ['Student', 'Status', 'Date Submitted', 'Grade', ' '];

    if (!assignment || !submissions) {
        return <Loading/>;
    }

    const uniqueSubmissions = (submissions?.entities ?? []).reduce((acc, submission) => {
        const email = submission.email;

        if (
            !acc[email] ||
            new Date(submission.submission_date) > new Date(acc[email].submission_date)
        ) {
            acc[email] = submission;
        }

        return acc;
    }, {});

    const filteredSubmissions = Object.values(uniqueSubmissions);

    return (
        <div id="HomeTeacher" className="flex flex-row">
            <div id="Main-Content-container" className="flex flex-col space-y-8 w-full">
                <div className="flex flex-col w-full">
                    <h2 className="text-xl font-bold mb-1">
                        Step {assignment ? (assignment.type === 'essay' ? 2 : assignment.is_exam ? 4 : 2) : 2}:
                        Review grades
                    </h2>
                    <p className="text-sm mb-4">
                        After submission upload, suggested grades will be available within two minutes.
                    </p>
                </div>

                {assignment.type !== 'essay' && (
                    <div className="flex justify-start mb-4">
                        <div className="flex space-x-4">
                            <button
                                className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-300 ${
                                    reviewMode === 'Student'
                                        ? 'bg-orange-200 border-orange-300 text-black shadow-md'
                                        : 'bg-white border-gray-200 text-gray-600 hover:bg-gray-50'
                                } border`}
                                onClick={() => setReviewMode('Student')}
                            >
                                View by Student
                            </button>

                            <button
                                className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-300 ${
                                    reviewMode === 'Question'
                                        ? 'bg-orange-200 border-orange-300 text-black shadow-md'
                                        : 'bg-white border-gray-200 text-gray-600 hover:bg-gray-50'
                                } border`}
                                onClick={() => setReviewMode('Question')}
                            >
                                {`View by ${assignment.type === 'flexible_problem_set' ? 'Question' : 'Sub-Question'}`}
                            </button>
                        </div>
                    </div>
                )}

                <div className="ml-4bg-white ">
                    {errorMessage && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                            {errorMessage}
                        </div>
                    )}
                    <Table
                        isLoading={isLoading}
                        noDataTitle="No submissions found"
                        totalCount={filteredSubmissions.length}
                        itemsPerPage={Number(itemsPerPage)}
                        offset={submissions?.offset}
                        nextOffset={submissions?.nextOffset}
                        pageNumber={Number(pageNumber)}
                        columnNames={columnNames}
                        columnBodies={filteredSubmissions.map(value => (
                            <tr
                                key={`${value.full_name + ' ' + value.studentId}`}
                                className="border-b border-border"
                            >
                                <td className="text-left text-gray-500 custom-16-semi px-6 py-3">
                                    <div className="flex items-center">
                                        {value.is_reviewed && (
                                            <div className="w-2 h-2 bg-orange-400 rounded-full mr-2"></div>
                                        )}

                                        {hasRegrade(value) && (
                                            <div
                                                className={`size-9 flex items-center justify-center rounded-full transition-all duration-300`}
                                            >
                                                <img
                                                    src="/assets/sidebar/quote-request.png"
                                                    alt="Regrade Request"
                                                    className="size-5"
                                                />
                                            </div>
                                        )}

                                        {value.status !== 'Not submitted' && value.status !== 'Grading' ? (
                                            <Link
                                                to={`/tclasses/${assignment.course_id}/tassignments/${assignment.id}/tsubmissions/${value.id}/tdetails`}
                                                className="hover:underline text-black relative group"
                                            >
                                                {value.full_name ?? value.email}
                                                {assignment.is_group_task ? (
                                                    <div
                                                        className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap max-w-xs overflow-hidden text-ellipsis">
                                                        Group: {process_string(value.team_members)}
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                            </Link>
                                        ) : (
                                            <span>
                        {value.full_name ?? value.email}
                                                <div
                                                    className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap max-w-xs overflow-hidden text-ellipsis">
                          Group: {process_string(value.team_members)}
                        </div>
                      </span>
                                        )}
                                    </div>
                                </td>
                                <td className="text-left text-black custom-16-med px-6 py-3">{value.status}</td>
                                <td className="text-left text-black custom-16-med px-6 py-3">
                                    {value.id !== null && value.id !== undefined && !!value.submission_date ? (
                                        <DueTimeDisplayTable
                                            datetimeString={value.submission_date}
                                            timezone={user.user.timezone}
                                        />
                                    ) : (
                                        <span className="text-gray-500">N/A</span>
                                    )}
                                </td>
                                <td className="text-left text-black custom-16-med px-6 py-3">
                                    {value.grade !== null && value.grade !== undefined ? (
                                        `${value.grade}/${assignment.max_score}`
                                    ) : (
                                        <span className="text-gray-500">N/A</span>
                                    )}
                                </td>
                                {assignment.type === 'pdf_problem_set' &&
                                    !assignment.is_group_task &&
                                    assignment.is_assignment_published && (
                                        <td className="text-left text-black custom-16-med px-6 py-3">
                                            <button
                                                className="px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                                                onClick={() => handleUploadClick(value.studentId)}
                                            >
                                                Upload
                                            </button>
                                        </td>
                                    )}
                            </tr>
                        ))}
                        updateSearchParams={updateSearchParams}
                    />
                    {showUploadModal && (
                        <TeacherStudentUpload
                            assignmentId={assignment.id}
                            studentId={selectedStudentId}
                            setUpdateTrigger={setUpdateTrigger}
                            onClose={handleCloseModal}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default TGrade;
