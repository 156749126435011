import React, { useCallback, useEffect, useRef, useState } from 'react';
import { gradeApi } from '../../api.js';
import { useAuth } from '../../auth/AuthContext';
import SideBar from '../../components/SideBar';
import BigStepper from '../../components/Stepper.js';
import BigStepperLine from '../../components/StepperLine.js';
import TEssay from '../../components/Teacher/TEssay.js';
import TGrade from '../../components/Teacher/TGrade';
import ParsePDF from '../../components/Teacher/TParsePDF';
import TopBar from '../../components/TopBar';

const TEssaySubmissions = ({
  assignment_id,
  assignment,
  submissions,
  updateTrigger,
  setUpdateTrigger,
  pullQuestions,
  section,
  setPullQuestions,
  setEdited,
  setSolRecent,
  setSection,
  isSubmissionLoading,
  pageNumber,
  itemsPerPage,
  submissionsError,
  setReviewMode,
  updateSearchParams
}) => {
  const { user } = useAuth();
  const tGradeRef = useRef(null);
  const [topErrorMessages, setTopErrorMessages] = useState('');

  const [essayCriteria, setEssayCriteria] = useState([]);
  const [essayInstructions, setEssayInstructions] = useState('');
  const [showRubric, setShowRubric] = useState(false);
  const [essayGradingInstructions, setEssayGradingInstructions] = useState('');
  const [showScroll, setShowScroll] = useState(false);

  const handleIntersection = useCallback(entries => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      setShowScroll(false);
    } else {
      setShowScroll(true);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1
    });

    const currentRef = tGradeRef.current;

    if (!currentRef) {
      const mutationObserver = new MutationObserver(mutations => {
        if (tGradeRef.current) {
          observer.observe(tGradeRef.current);
          mutationObserver.disconnect();
        }
      });

      mutationObserver.observe(document.body, {
        childList: true,
        subtree: true
      });

      return () => {
        mutationObserver.disconnect();
        observer.disconnect();
      };
    } else {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, [handleIntersection, assignment?.type]);

  const scrollToTGrade = () => {
    tGradeRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const uploadQuestionsEssay = async () => {
    const payload = {
      exam_paper: [
        {
          description: 'Grade this essay',
          subQuestions: essayCriteria.map((criterion, index) => ({
            questionType: 'freeform',
            question: criterion.criterion,
            questionSizing: 50,
            solution: '',
            rubricItems: criterion.rubricItems.map((rubricItem, subIndex) => ({
              descriptor: rubricItem.descriptor,
              value: rubricItem.value
            })),
            mcqOptions: undefined
          }))
        }
      ],
      instructions: essayInstructions,
      model_instructions: essayGradingInstructions,
      show_rubric: showRubric
    };

    // console.log(payload)
    try {
      await gradeApi.post({
        path: `/api/assignment/${assignment.id}/constructor/`,
        body: payload,
        config: { headers: { Authorization: `Bearer ${user.session_token}` } }
      });
      setUpdateTrigger(prev => !prev);
      setPullQuestions(true);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const errorMessage = error.response.data || 'An error occurred';
        setTopErrorMessages([errorMessage]);
      } else if (error.request) {
        // The request was made but no response was received
        setTopErrorMessages(['No response received from server']);
      } else {
        // Something happened in setting up the request that triggered an Error
        const errorMessage = error.message || 'An error occurred';
        setTopErrorMessages([errorMessage]);
      }
      console.error('Error in uploadQuestions:', error);
    }
  };

  const publishAssignment = async () => {
    if (assignment.is_assignment_published) {
      setTopErrorMessages(["You can't unpublish the assignment once it is published."]);
      return;
    }

    if (essayCriteria.length === 0) {
      setTopErrorMessages(['Essay must have least one criterion.']);
      return;
    }
    for (let cri of essayCriteria) {
      if (cri.rubricItems.length === 0) {
        setTopErrorMessages(['Every criterion must have at least one rubric item.']);
        return;
      }

      if (cri.criterion.trim() === '') {
        setTopErrorMessages(['Essay criteria cannot be empty.']);
        return;
      }

      for (let rubricItem of cri.rubricItems) {
        if (String(rubricItem.value).trim() === '' || String(rubricItem.descriptor).trim() === '') {
          setTopErrorMessages(['Each rubric item must have a descriptor and point value.']);
          return;
        }
      }
    }

    await uploadQuestionsEssay();

    try {
      await gradeApi.post({
        path: `/api/assignment/${assignment.id}/publish-assignment/`,
        body: {
          is_assignment_published: true
        },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });

      setTopErrorMessages(['']);
      setUpdateTrigger(prev => !prev);
      window.location.reload();
    } catch (error) {
      // console.log(error);
      if (error.response && error.response.data && error.response.data.error) {
        // Display the specific error message from the backend
        setTopErrorMessages([error.response.data.error]);
      } else {
        // Fallback to a generic error message if no specific message is available
        setTopErrorMessages(['Failed to publish assignment. Please try again.']);
      }
    }
  };

  return (
    // Original essay layout with sidebar
    <div className="HomePageHeader">
      <div id="Main-container" className="flex flex-row">
        <div id="Left-Sidebar" className="fixed">
          <SideBar activePage="Home" />
        </div>
        <div id="Right-content" className="flex ml-side-bar-size pr-[30px] w-full">
          <TopBar
            assignment_in={assignment}
            submissions_in={submissions}
            topErrorMessages={topErrorMessages}
            setTopErrorMessages={setTopErrorMessages}
            section={section}
            setSection={setSection}
            publishAssignment={publishAssignment}
          />
          <div
            className={`flex flex-col mb-32 w-full ml-[30px] ${topErrorMessages ? 'mt-44' : 'mt-36'}`}
          >
            <div className="grid grid-cols-[84px_auto]">
              <BigStepper stepNumber={0} top={5} />
              <ParsePDF
                isEssay
                assignment_id={assignment_id}
                setUpdateTrigger={setUpdateTrigger}
                isPublished={assignment.is_assignment_published}
                setPullQuestions={setPullQuestions}
                setEdited={setEdited}
                setSolRecent={setSolRecent}
              />
            </div>
            <BigStepperLine />
            <div className="grid grid-cols-[84px_auto]">
              <BigStepper stepNumber={1} top={5} />
              <TEssay
                assignment_id={assignment_id}
                updateTrigger={updateTrigger}
                setUpdateTrigger={setUpdateTrigger}
                criteria={essayCriteria}
                setCriteria={setEssayCriteria}
                instructions={essayInstructions}
                setInstructions={setEssayInstructions}
                isPublished={assignment.is_assignment_published}
                pullQuestions={pullQuestions}
                setPullQuestions={setPullQuestions}
                gradingInstructions={essayGradingInstructions}
                setGradingInstructions={setEssayGradingInstructions}
                showRubric={showRubric}
                setShowRubric={setShowRubric}
              ></TEssay>
            </div>
            <BigStepperLine />
            <div ref={tGradeRef} className="grid grid-cols-[84px_auto]">
              <BigStepper stepNumber={2} top={0} ht={'[50px]'} />
              <TGrade
                reviewMode="Student"
                isLoading={isSubmissionLoading}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                submissionsError={submissionsError}
                assignment={assignment}
                submissions={submissions}
                setUpdateTrigger={setUpdateTrigger}
                setReviewMode={setReviewMode}
                updateSearchParams={updateSearchParams}
              />
            </div>
          </div>
          {showScroll && (
            <button
              className="fixed h-[40px] w-[40px] right-[3%] bottom-[5%] z-40
                                rounded-full border-[1px] border-black bg-white"
              onClick={scrollToTGrade}
            >
              <i className="fas fa-chevron-down m-auto" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TEssaySubmissions;
