import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gradeApi } from '../../api.js';
import { useAuth } from '../../auth/AuthContext';
import SideBar from '../../components/SideBar';
import BigStepper from '../../components/Stepper.js';
import BigStepperLine from '../../components/StepperLine.js';
import ExamUpload from '../../components/Teacher/ExamUpload';
import RenderBatch from '../../components/Teacher/RenderBatch';
import TFlexibleAssignment from '../../components/Teacher/TFlexibleAssignment/TFlexibleAssignment';
import TGrade from '../../components/Teacher/TGrade';
import ParsePDF from '../../components/Teacher/TParsePDF'; // New import for PdfUpload component
import {
  defaultGradingInstructions,
  defaultInstructions
} from '../../components/Teacher/TPset/constants.js';
import TQuestionGrade from '../../components/Teacher/TQuestionGrade.js';
import TopBar from '../../components/TopBar';
import { uploadQuestions } from '../../store/flexibleAssignmentSlice';

const TFlexibleSubmissions = ({
  assignment_id,
  assignment,
  submissions,
  pullQuestions,
  reviewMode,
  updateTrigger,
  section,
  setUpdateTrigger,
  setPullQuestions,
  setSection,
  setEdited,
  setSolRecent,
  solRecent,
  isSubmissionLoading,
  pageNumber,
  itemsPerPage,
  submissionsError,
  setReviewMode,
  updateSearchParams
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [topErrorMessages, setTopErrorMessages] = useState('');

  const { data: draftData } = useSelector(state => state?.flexibleAssignmentReducer?.draftData);

  const uploadQuestionsFlexible = async () => {
    const payload = {
      exam_paper: draftData.examPaper.map(question => ({
        ...question,
        mcqOptions: question.questionType === 'mcq' ? question.mcqOptions : undefined,
        rubricItems:
          question.questionType === 'mcq'
            ? [
                { descriptor: 'Correct Answer', value: question.weight.toString() },
                { descriptor: 'Incorrect Answer', value: '0' }
              ]
            : question.rubricItems,
        ...(question.questionType === 'mcq' && { mcqOptions: question.mcqOptions }),
        subQuestions: question.subQuestions.map(subQuestion => ({
          ...subQuestion,
          mcqOptions: subQuestion.questionType === 'mcq' ? subQuestion.mcqOptions : undefined,
          rubricItems:
            subQuestion.questionType === 'mcq'
              ? [
                  { descriptor: 'Correct Answer', value: subQuestion.weight.toString() },
                  { descriptor: 'Incorrect Answer', value: '0' }
                ]
              : subQuestion.rubricItems,
          ...(subQuestion.questionType === 'mcq' && { mcqOptions: subQuestion.mcqOptions })
        }))
      })),
      instructions: draftData.instructions || defaultInstructions,
      model_instructions: draftData.gradingInstructions || defaultGradingInstructions,
      feedback_instructions: draftData.feedbackInstructions,
      show_points: draftData.showPoints,
      allow_pregrade: draftData.allowPregrade
    };

    try {
      const response = await dispatch(
        uploadQuestions({ assignment_id, token: user.session_token, body: payload })
      );

      if (response.type === 'flexibleAssignment/uploadQuestions/fulfilled') {
        setEdited(false);
        setSolRecent(true);
        setUpdateTrigger(prev => !prev);
        setTopErrorMessages(['']);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error || 'An error occurred. Please try again.';
      setTopErrorMessages([errorMessage]);
    }
  };

  const publishAssignment = async () => {
    if (assignment.is_assignment_published) {
      setTopErrorMessages(["You can't unpublish the assignment once it is published."]);
      return;
    }

    await uploadQuestionsFlexible();

    try {
      await gradeApi.post({
        path: `/api/assignment/${assignment.id}/publish-assignment/`,
        body: {
          is_assignment_published: true
        },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });

      setTopErrorMessages(['']);
      setUpdateTrigger(prev => !prev);
      window.location.reload();
    } catch (error) {
      // console.log(error);
      if (error.response && error.response.data && error.response.data.error) {
        // Display the specific error message from the backend
        setTopErrorMessages([error.response.data.error]);
      } else {
        // Fallback to a generic error message if no specific message is available
        setTopErrorMessages(['Failed to publish assignment. Please try again.']);
      }
    }
  };

  return (
    <div className="HomePageHeader">
      <div id="Main-container" className="flex flex-row">
        <div id="Left-Sidebar" className="fixed">
          <SideBar activePage="Home" />
        </div>
        <div id="Right-content" className={'flex ml-side-bar-size pr-[30px] w-full'}>
          <TopBar
            assignment_in={assignment}
            submissions_in={submissions}
            topErrorMessages={topErrorMessages}
            setTopErrorMessages={setTopErrorMessages}
            section={section}
            setSection={setSection}
            publishAssignment={publishAssignment}
          />
          {section === 'create' ? (
            <div
              className={`flex flex-col mb-32 ${assignment.type === 'flexible_problem_set' ? 'w-full' : 'w-[55%]'} ml-[15px] mr-0 ${topErrorMessages ? 'mt-[150px]' : 'mt-28'}`}
            >
              <>
                <div className="grid grid-cols-[84px_auto]">
                  <BigStepper stepNumber={0} top={5} />
                  <ParsePDF
                    assignment_id={assignment_id}
                    setUpdateTrigger={setUpdateTrigger}
                    isPublished={assignment.is_assignment_published}
                    setPullQuestions={setPullQuestions}
                    setEdited={setEdited}
                    setSolRecent={setSolRecent}
                  />
                </div>
                <BigStepperLine />
              </>
              <div className="grid grid-cols-[84px_auto]">
                <BigStepper stepNumber={1} top={5} />
                <TFlexibleAssignment
                  assignment_id={assignment_id}
                  solRecent={solRecent}
                  pullQuestions={pullQuestions}
                  isPublished={assignment.is_assignment_published}
                  setEdited={setEdited}
                  setSolRecent={setSolRecent}
                  updateTrigger={updateTrigger}
                  setPullQuestions={setPullQuestions}
                  setTopErrorMessages={setTopErrorMessages}
                ></TFlexibleAssignment>
              </div>
            </div>
          ) : (
            <div
              className={`flex flex-col mb-32 w-full ml-[30px] ${topErrorMessages ? 'mt-44' : 'mt-36'}`}
            >
              {assignment.is_exam && (
                <>
                  <div className="grid grid-cols-[84px_auto]">
                    <BigStepper stepNumber={3} top={0} />
                    <RenderBatch assignment_id={assignment_id} updateTrigger={updateTrigger} />
                  </div>
                  <BigStepperLine />
                  <div className="grid grid-cols-[84px_auto]">
                    <BigStepper stepNumber={4} top={0} />
                    <ExamUpload assignment_id={assignment_id} setUpdateTrigger={setUpdateTrigger} />
                  </div>
                  <BigStepperLine />
                </>
              )}

              <div className="grid grid-cols-[84px_auto]">
                <BigStepper stepNumber={assignment.is_exam ? 5 : 3} top={0} ht={'[50px]'} />
                {reviewMode === 'Question' ? (
                  <TQuestionGrade
                    assignment_in={assignment}
                    submissions_in={submissions}
                    setUpdateTrigger={setUpdateTrigger}
                    reviewMode={reviewMode}
                    setReviewMode={setReviewMode}
                  />
                ) : (
                  <TGrade
                    reviewMode={reviewMode}
                    isLoading={isSubmissionLoading}
                    itemsPerPage={itemsPerPage}
                    pageNumber={pageNumber}
                    submissionsError={submissionsError}
                    assignment={assignment}
                    submissions={submissions}
                    setUpdateTrigger={setUpdateTrigger}
                    setReviewMode={setReviewMode}
                    updateSearchParams={updateSearchParams}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TFlexibleSubmissions;
