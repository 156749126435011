import {Info} from 'lucide-react';
import React, {useState} from 'react';
import ImportAssignmentModal from './ImportAssignmentModal';

const Modal = ({
                   isModalOpen,
                   setIsModalOpen,
                   handleSubmit,
                   assignmentName,
                   setAssignmentName,
                   format,
                   setFormat,
                   dueDate,
                   setDueDate,
                   dueTime,
                   timezone,
                   setDueTime,
                   studentSubmitted,
                   setStudentSubmitted,
                   errorMessage,
                   setErrorMessage,
                   dateFocused,
                   setDateFocused,
                   timeFocused,
                   setTimeFocused,
                   modalRef,
                   // setIsGroupSubmission,
                   // isGroupSubmission,
                   handleCloneAssignment
               }) => {
    const [mode, setMode] = useState('new');

    return (
        isModalOpen && (
            <div
                className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50 flex justify-center items-center">
                <div
                    ref={modalRef}
                    className="sexy-border relative mx-auto p-6 bg-white w-full max-w-[400px]"
                >
                    <div className="flex justify-start mb-6">
                        <div className="flex items-center bg-grey-700 rounded-full p-2 shadow-md mr-4">
                            <input
                                type="checkbox"
                                id="newAssignmentCheckBox"
                                checked={mode === 'new'}
                                className="sr-only peer"
                                onClick={() => setMode('new')}
                            />
                            <label
                                htmlFor="newAssignmentCheckBox"
                                className="cursor-pointer w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out mr-2"
                            ></label>
                            <label htmlFor="newAssignmentCheckBox" className="text-sm font-medium text-black">
                                Create
                            </label>
                        </div>
                        <div className="flex items-center bg-grey-700 rounded-full p-2 shadow-md">
                            <input
                                type="checkbox"
                                id="importAssignmentCheckBox"
                                checked={mode === 'import'}
                                className="sr-only peer"
                                onClick={() => setMode('import')}
                            />
                            <label
                                htmlFor="importAssignmentCheckBox"
                                className="cursor-pointer w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out mr-2"
                            ></label>
                            <label htmlFor="importAssignmentCheckBox" className="text-sm font-medium text-black">
                                Import
                            </label>
                        </div>
                    </div>

                    {mode === 'new' ? (
                        <div className="space-y-4">
                            <div className="flex flex-row justify-between">
                                <h2 className="custom-title-sm text-black font-bold">Create New Assignment</h2>
                                <button
                                    type="button"
                                    className="desktop:hover:opacity-50 transition-all duration-300"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    <img src="/assets/X.png" alt="Exit" className="w-[20px] h-[20px]"/>
                                </button>
                            </div>
                            <form>
                                <div className="space-y-3">
                                    <div className="flex flex-col mb-5">
                                        <div
                                            className={`flex items-center input-entry-box font-normal bg-white ${errorMessage !== '' ? 'border-red-500' : ''}`}
                                            onFocus={() => {
                                                setErrorMessage('');
                                            }}
                                        >
                                            <input
                                                type="text"
                                                value={assignmentName}
                                                className={`actual-input ml-2.5 mr-2.5 ${errorMessage !== '' ? 'text-red-500' : ''}`}
                                                placeholder="Assignment Name"
                                                onChange={e => {
                                                    setAssignmentName(e.target.value);
                                                    if (e.target.value.length > 70) {
                                                        setErrorMessage('Assignment name must not exceed 70 characters.');
                                                    } else {
                                                        setErrorMessage('');
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className="flex flex-col mt-3">
                                            <div
                                                className="relative flex items-center input-entry-box font-normal bg-white">
                                                <div className="group absolute left-2.5 z-10 flex items-center">
                                                    <Info className="h-4 w-4 text-gray-500"/>
                                                    <div
                                                        className="absolute w-64 p-2 bg-gray-800 text-white text-sm rounded-md opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50 right-6 top-0 translate-x-[-20%]">
                                                        <p className="mb-2">
                                                            <strong>PDF Problem Set: </strong>Question paper that is
                                                            graded with a
                                                            rubric and solutions
                                                        </p>
                                                        <p className="mb-2">
                                                            <strong>Flexible Problem Set: </strong>Online quiz where
                                                            students can
                                                            type answers and upload drawings, graded with rubric and
                                                            solution
                                                        </p>
                                                        <p>
                                                            <strong>Essay: </strong>Graded using grid rubrics with
                                                            multiple
                                                            criterion.
                                                        </p>
                                                    </div>
                                                </div>
                                                <select
                                                    value={format}
                                                    className="pl-8 actual-input text-black placeholder-black w-full"
                                                    onChange={e => {
                                                        setFormat(e.target.value);
                                                        if (e.target.value !== 'pdf-problem_set') {
                                                            setStudentSubmitted('student');
                                                        }
                                                    }}
                                                >
                                                    <option value="" disabled selected>
                                                        Select Format
                                                    </option>
                                                    <option value="pdf_problem_set">PDF Problem Set</option>
                                                    <option value="flexible_problem_set">Flexible Problem Set</option>
                                                    <option value="essay">Essay</option>
                                                </select>
                                            </div>
                                        </div>
                                        {format === 'pdf_problem_set' && (
                                            <div
                                                className="relative flex items-center input-entry-box font-normal bg-white mt-3">
                                                <div className="group absolute left-2.5 z-10 flex items-center">
                                                    <Info className="h-4 w-4 text-gray-500"/>
                                                    <div
                                                        className="absolute w-64 p-2 bg-gray-800 text-white text-sm rounded-md opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50 right-6 top-0 translate-x-[-20%]">
                                                        <p className="mb-2">
                                                            <strong>Student Submission:</strong> Students log into their
                                                            GradeWiz
                                                            accounts and submit work to the platform.
                                                        </p>
                                                        <p>
                                                            <strong>Teacher submission:</strong> Teachers upload student
                                                            work to
                                                            the platform in a large PDF (useful for exams).
                                                        </p>
                                                    </div>
                                                </div>
                                                <select
                                                    value={studentSubmitted}
                                                    className="pl-8 actual-input text-black placeholder-black w-full"
                                                    onChange={e => setStudentSubmitted(e.target.value)}
                                                >
                                                    <option value="" disabled selected>
                                                        Define Submission
                                                    </option>
                                                    <option value="student">Student</option>
                                                    <option value="teacher">Teacher</option>
                                                </select>
                                            </div>
                                        )}

                                        {studentSubmitted == 'student' && (
                                            <div
                                                className={`relative flex items-center mt-3 font-normal bg-white input-entry-box ${errorMessage !== '' ? 'border-red-500' : ''}`}
                                                onFocus={() => {
                                                    setDateFocused(true);
                                                    setErrorMessage('');
                                                }}
                                                onBlur={() => setDateFocused(false)}
                                            >
                                                <div
                                                    className={`flex items-center w-full  ${errorMessage !== '' ? 'border-red-500' : ''}`}
                                                >
                                                    {!dateFocused && dueDate === '' && (
                                                        <div
                                                            className="absolute ml-2.5 pointer-events-none text-gray-400 bg-white w-36"
                                                            style={{userSelect: 'none'}}
                                                        >
                                                            Due Date
                                                        </div>
                                                    )}
                                                    <input
                                                        type="date"
                                                        value={dueDate}
                                                        className={`actual-input ml-2.5 mr-2.5 w-full ${errorMessage !== '' ? 'text-red-500' : ''}`}
                                                        onChange={e => setDueDate(e.target.value)}
                                                        onFocus={() => setErrorMessage('')}
                                                    />
                                                </div>
                                                <div
                                                    className="size-6 flex items-center justify-center pointer-events-none absolute right-0 bg-white mr-2">
                                                    <img
                                                        src={
                                                            errorMessage === ''
                                                                ? '/assets/calendar.png'
                                                                : '/assets/red-calendar.png'
                                                        }
                                                        className={
                                                            dateFocused || errorMessage !== '' ? 'size-5' : 'size-5 opacity-30'
                                                        }
                                                        alt={
                                                            errorMessage === ''
                                                                ? 'Assignment Due Date Icon'
                                                                : 'Error with Assignment Due Date'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {studentSubmitted == 'student' && (
                                            <div
                                                className={`relative flex items-center mt-3 font-normal bg-white input-entry-box ${errorMessage !== '' ? 'border-red-500' : ''}`}
                                            >
                                                <label className="w-full cursor-pointer">
                                                    <div
                                                        className={`flex items-center w-full ${errorMessage !== '' ? 'border-red-500' : ''}`}
                                                        onClick={() => {
                                                            setTimeFocused(true);
                                                            setErrorMessage('');
                                                        }}
                                                    >
                                                        {!timeFocused && dueTime === '' && (
                                                            <div
                                                                className="absolute ml-2.5 pointer-events-none text-gray-400 bg-white w-36"
                                                                style={{userSelect: 'none'}}
                                                            >
                                                                Due Time
                                                            </div>
                                                        )}
                                                        <input
                                                            type="time"
                                                            value={dueTime}
                                                            className={`actual-input ml-2.5 mr-2.5 w-full ${errorMessage !== '' ? 'text-red-500' : ''}`}
                                                            onChange={e => setDueTime(e.target.value)}
                                                            onFocus={() => {
                                                                setTimeFocused(true);
                                                                setErrorMessage('');
                                                            }}
                                                            onBlur={() => setTimeFocused(false)}
                                                        />
                                                        <div
                                                            className="flex items-center absolute right-0 bg-white mr-2">
                                                            <span
                                                                className="text-sm text-gray-500 mr-2">{timezone}</span>
                                                            <div
                                                                className="size-6 flex items-center justify-center pointer-events-none">
                                                                <img
                                                                    src={
                                                                        errorMessage === ''
                                                                            ? '/assets/clock.png'
                                                                            : '/assets/red-clock.png'
                                                                    }
                                                                    className={
                                                                        timeFocused || errorMessage !== ''
                                                                            ? 'size-5'
                                                                            : 'size-5 opacity-30'
                                                                    }
                                                                    alt="Clock icon"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        )}
                                    </div>

                                    {/*{studentSubmitted == 'student' && (*/}
                                    {/*  <div className="flex justify-center">*/}
                                    {/*    <div className="flex items-center">*/}
                                    {/*      <button*/}
                                    {/*        role="switch"*/}
                                    {/*        aria-checked={isGroupSubmission}*/}
                                    {/*        className={`*/}
                                    {/*          relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out mr-2*/}
                                    {/*          ${isGroupSubmission ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}*/}
                                    {/*        `}*/}
                                    {/*        onClick={e => {*/}
                                    {/*          e.preventDefault();*/}
                                    {/*          setIsGroupSubmission(!isGroupSubmission);*/}
                                    {/*        }}*/}
                                    {/*      >*/}
                                    {/*        <span*/}
                                    {/*          className={`*/}
                                    {/*            inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out*/}
                                    {/*            ${isGroupSubmission ? 'translate-x-6' : 'translate-x-1'}*/}
                                    {/*          `}*/}
                                    {/*        />*/}
                                    {/*      </button>*/}
                                    {/*      <label className="text-sm font-medium text-black">*/}
                                    {/*        Allow Group Submission*/}
                                    {/*      </label>*/}
                                    {/*    </div>*/}
                                    {/*  </div>*/}
                                    {/*)}*/}

                                    {errorMessage && <div className="text-red-500 text-sm my-2">{errorMessage}</div>}
                                </div>
                                <div className="flex justify-end space-x-2 mt-8">
                                    <button
                                        type="submit"
                                        disabled={
                                            !assignmentName ||
                                            ((!dueDate || !dueTime) && studentSubmitted == 'student') ||
                                            !format ||
                                            !studentSubmitted
                                        }
                                        className={`sexy-button w-full ${
                                            assignmentName &&
                                            (studentSubmitted !== 'student' || (dueDate && dueTime)) &&
                                            format &&
                                            studentSubmitted
                                                ? ''
                                                : 'cursor-not-allowed'
                                        }`}
                                        onClick={handleSubmit}
                                    >
                                        Create Assignment
                                    </button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <ImportAssignmentModal
                            setErrorMessage={setErrorMessage}
                            errorMessage={errorMessage}
                            onClose={() => setIsModalOpen(false)}
                            onImport={assignmentId => {
                                handleCloneAssignment(assignmentId);
                                setIsModalOpen(false);
                            }}
                        />
                    )}
                </div>
            </div>
        )
    );
};

export default Modal;
