import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext.js';
import SideBar from '../../components/SideBar';
import SPublished from '../../components/Student/SPublished';
import SUnpublishedEssay from '../../components/Student/SUnpublishedEssay.js';
import SUnpublishedFlexibleAssignment
    from '../../components/Student/SUnpublishedFlexibleAssignment/SUnpublishedFlexibleAssignment.js';
import SUnpublishedPset from '../../components/Student/SUnpublishedPset.js';

const SDetailsPage = () => {
    let {assignment_id} = useParams();
    let {class_id} = useParams();
    const {user} = useAuth();
    const [submissionData, setSubmissionData] = useState({});
    const [assignmentData, setAssignmentData] = useState(null);

// Log the right side of the OR
    console.log('Right side of OR:', (assignmentData?.allow_pregrade && submissionData?.submission_count == 1));

// Log final expression
    console.log('Final result:', (assignmentData?.is_grades_published || (assignmentData?.allow_pregrade && submissionData?.submission_count == 1)));
    const [message, setMessage] = useState('');
    const [paymentRequired, setPaymentRequired] = useState(false);

    const handlePaymentClick = async () => {
        try {
            const response = await gradeApi.post({
                path: `/api/payment/create-checkout/${class_id}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            if (response.data.url) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    useEffect(() => {
        const getAssignmentSubmission = async () => {
            try {
                console.log(`Fetching assignment submission for ID: ${assignment_id}`);
                const response = await gradeApi.get({
                    path: `/api/course/${class_id}/assignment/${assignment_id}/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                });
                console.log('Submission response:', response);
                const data = response.data.submissions;  // Access the submissions array

                console.log('Submission data:', data);
                // Find submission with highest ID
                const latestSubmission = data.reduce((latest, current) =>
                    current.id > latest.id ? current : latest
                );

                console.log('Latest submission:', latestSubmission);
                setSubmissionData(latestSubmission);
            } catch (error) {
                setSubmissionData({submitted: false});
                setMessage(error.response?.data?.error || 'An error occurred while fetching the submission.');
            }
        };
        console.log('goony side effect');

        getAssignmentSubmission();
    }, []);

// In your SAssignments component:
    const checkPaymentStatus = async () => {
        console.log('checking payment status');
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${class_id}/payment-check/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            console.log(response.data);
            if (response.data.needs_payment) {
                setPaymentRequired(true);
            }
        } catch (error) {
            console.error('Error checking payment status:', error);
        }
    };

    const getAssignmentDetails = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${class_id}/assignment/${assignment_id}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            console.log(response.data);
            setAssignmentData(response.data);
        } catch (error) {
            console.error('Error fetching assignment details:', error);
            setMessage('Error fetching assignment details. Please try again later.');
        }
    };
    useEffect(() => {
        checkPaymentStatus();
        getAssignmentDetails();
    }, []);

    if (!assignmentData) {
        return <div>loading</div>;
    }

    if (assignmentData?.is_grades_published || (assignmentData?.allow_pregrade && submissionData?.submission_count == 1)) {
        return <SPublished assignment={assignmentData} class_id={class_id}/>;
    } else {
        return (
            <div id="Main-container" className="flex flex-row w-full min-h-screen">
                <div
                    id="Left-Sidebar"
                    className="sticky top-0 h-screen overflow-y-auto w-side-bar-size flex-shrink-0"
                >
                    <SideBar activePage="Home"/>
                </div>
                {paymentRequired && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                        <div className="bg-white p-8 rounded-lg max-w-md">
                            <h2 className="text-2xl font-bold mb-4">Payment Required</h2>
                            <p className="mb-6">
                                We are excited to have you join GradeWiz! Please make the payment to access your
                                assignments.
                            </p>
                            <button
                                className="sexy-button transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow"
                                onClick={handlePaymentClick}
                            >
                                Pay Now
                            </button>
                        </div>
                    </div>
                )}
                <div id="Right-content" className="flex-grow overflow-y-auto">
                    <div className="max-w-full">
                        {assignmentData?.type === 'essay' && (
                            <SUnpublishedEssay assignment={assignmentData} class_id={class_id}/>
                        )}

                        {assignmentData?.type === 'flexible_problem_set' && (
                            <SUnpublishedFlexibleAssignment
                                assignment_id={assignment_id}
                                assignment={assignmentData}
                                class_id={class_id}
                            />
                        )}

                        {assignmentData?.type === 'pdf_problem_set' && (
                            <SUnpublishedPset assignment={assignmentData} class_id={class_id}/>
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

export default SDetailsPage;
