import { CheckIcon, PencilIcon, XIcon } from 'lucide-react';
import React, { useState } from 'react';

const EditableMatchKeyCell = ({ initialValue, studentId, onSave, isOpenToManage }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await onSave(studentId, value);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update match key:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isEditing) {
    return (
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={value}
          className="px-2 py-1 border rounded text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          autoFocus
          onChange={e => setValue(e.target.value)}
        />
        <button
          disabled={isLoading}
          className="text-green-600 hover:text-green-900 disabled:opacity-50"
          onClick={handleSave}
        >
          <CheckIcon className="h-4 w-4" />
        </button>
        <button
          className="text-red-600 hover:text-red-900"
          onClick={() => {
            setIsEditing(false);
            setValue(initialValue);
          }}
        >
          <XIcon className="h-4 w-4" />
        </button>
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-2">
      <span className="text-sm text-gray-500">{value || '-'}</span>
      {isOpenToManage && (
        <button
          className="text-blue-600 hover:text-blue-900 group-hover:opacity-100 transition-opacity"
          onClick={() => setIsEditing(true)}
        >
          <PencilIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};

export default EditableMatchKeyCell;
