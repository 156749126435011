import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import ConfirmationModal from '../ConfirmationModal'; // Correct import path
// import demoVideo from "./demo.mp4";
import {ViewSelectIcon} from '../ViewSelect';

function TClasses() {
    const [className, setClassName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false); // State for confirmation modal
    const [selectedClassId, setSelectedClassId] = useState(null); // State for selected class ID
    const modalRef = useRef();
    const videoModalRef = useRef();
    const [classes, setClasses] = useState([]);
    const {user} = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isExportConfirmationModalOpen, setIsExportConfirmationModalOpen] = useState(false);
    const [selectedClassForExport, setSelectedClassForExport] = useState(null);
    const [exportScoreType, setExportScoreType] = useState('percentage'); // New state for score type

    const handleExportClass = classId => {
        setSelectedClassForExport(classId);
        setIsExportConfirmationModalOpen(true);
    };

    const confirmExportClass = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${selectedClassForExport}/export/`,
                config: {
                    params: {
                        score_type: exportScoreType
                    },
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    },
                    responseType: 'blob' // Important: This tells axios to treat the response as binary data
                }
            });

            // Create a Blob from the response data
            const blob = new Blob([response.data], {type: 'text/csv'});

            // Create a link element, use it to download the blob, and remove it
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = `class_export_${selectedClassForExport}.csv`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            setIsExportConfirmationModalOpen(false);
        } catch (error) {
            setErrorMessage('Failed to export class. Please try again.');
            setIsErrorVisible(true);
        }
    };

    function MoreAfter({toggleBool, id}) {
        return (
            <div className="grid grid-cols-3 items-center gap-5 border border-gray-250 rounded-3xl p-2 max-w-28">
                <div className="size-5 flex items-center justify-center">
                    <button onClick={() => handleDeleteClass(id)}>
                        <img src="/assets/trash.png" className="opacity-70 size-5" alt="Delete Class"/>
                    </button>
                </div>
                <div className="size-5 flex items-center justify-center">
                    <button onClick={() => handleExportClass(id)}>
                        <img src="/assets/export.png" className="opacity-60 size-5" alt="Export Class CSV"/>
                    </button>
                </div>
                <div className="size-5 flex items-center justify-center">
                    <button onClick={toggleBool}>
                        <img src="/assets/more.png" className="opacity-70 size-3" alt="More"/>
                    </button>
                </div>
            </div>
        );
    }

    const getClasses = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/users/${user.user.id}/courses-lite/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setClasses(response.data.courses);
        } catch (error) {
            setErrorMessage('Failed to fetch classes. Please try again.');
            setIsErrorVisible(true);
        }
        // console.log(classes);
    };

    useEffect(() => {
        getClasses();
    }, []);

    useEffect(() => {
        if (user.loginRecent) {
            setIsVideoModalOpen(true);
        }
    }, [user]);

    const handleSubmit = async e => {
        e.preventDefault();

        // Check class name length
        if (className.length > 70) {
            setErrorMessage('Class name must not exceed 70 characters.');
            setIsErrorVisible(true);
            return;
        }

        const classExists = classes.some(c => c.name === className);
        if (classExists) {
            setErrorMessage('Class with this name already exists.');
            setIsErrorVisible(true);
            return;
        }

        try {
            const response = await gradeApi.post({
                path: `/api/courses/`,
                body: {
                    name: className,
                    email: user.user.email  // Add email to the course creation request
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            getClasses();
            setIsModalOpen(false);
        } catch (error) {
            setErrorMessage(`An error has occurred: ${error.response?.data?.error || ''}`);
            setIsErrorVisible(true);
        }
        getClasses();
        setClassName('');
    };

    const handleDeleteClass = classId => {
        setSelectedClassId(classId);
        setIsConfirmationModalOpen(true);
    };

    const confirmDeleteClass = async () => {
        try {
            await gradeApi.delete({
                path: `/api/courses/${selectedClassId}/`,
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                }
            });
            getClasses();
            setIsConfirmationModalOpen(false);
        } catch (error) {
            setErrorMessage('Failed to delete class. Please try again.');
            setIsErrorVisible(true);
        }
    };

    useEffect(() => {
        const handleClickOutside = event => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(false);
            }
            if (videoModalRef.current && !videoModalRef.current.contains(event.target)) {
                setIsVideoModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef, videoModalRef]);

    const handleErrorMessageClick = () => {
        setIsErrorVisible(false);
        setErrorMessage('');
    };

    function MoreBefore({toggleBool}) {
        return (
            <div className="grid grid-cols-3 items-center gap-5 border border-white rounded-3xl p-2 max-w-28">
                <div className="size-5 flex items-center justify-center"></div>
                <div className="size-5 flex items-center justify-center"></div>
                <div className="size-5 flex items-center justify-center">
                    <button onClick={toggleBool}>
                        <img src="/assets/more.png" className="opacity-70 size-3" alt="More"/>
                    </button>
                </div>
            </div>
        );
    }

    function MoreButton({myBool, toggleBool, id}) {
        return myBool ? (
            <MoreBefore toggleBool={toggleBool}/>
        ) : (
            <MoreAfter toggleBool={toggleBool} id={id}/>
        );
    }

    const ClassCard = ({id, isOpenToManage, name, code, num_students}) => {
        console.log(isOpenToManage);
        const [myBool, setmyBool] = useState(true);

        function toggleBool() {
            setmyBool(!myBool);
        }

        const handleCardClick = (e) => {
            // Check if the click originated from the more options area
            if (e.target.closest('.more-options-area')) {
                // If clicked in more options area, prevent navigation
                e.preventDefault();
            }
        };

        return (
            <Link to={`/tclasses/${id}/tassignments`} onClick={handleCardClick}>
                <div
                    className="flex flex-col justify-between p-4 h-32 bg-white sexy-border relative transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow">
                    <div className="flex flex-col items-start text-left">
                        <div className="text-base font-medium text-black line-clamp-2">{name}</div>
                        <div className="text-gray-900 mt-1 text-sm truncate">{code}</div>
                    </div>
                    <div className="flex justify-between items-center space-x-4">
                        <div className="flex items-center space-x-1 truncate">
                            <div className="text-black font-semibold text-sm">{num_students}</div>
                            <div className="text-gray-900 text-xs">students</div>
                        </div>
                        <div className="more-options-area">
                            {isOpenToManage && (
                                <div>
                                    <MoreButton myBool={myBool} toggleBool={toggleBool} id={id}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Link>
        );
    };

    const ClassList = ({classes}) => {
        return (
            <div class="container mx-auto margin-below-topbar">
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 min-w-20">
                    {classes && classes.length > 0 ? (
                        classes.map(classItem => {
                            console.log(classItem);
                            return (
                                <ClassCard
                                    key={classItem.id}
                                    isOpenToManage={classItem.isOpenToManage}
                                    id={classItem.id}
                                    name={classItem.name}
                                    code={classItem.code}
                                    num_students={classItem.num_students}
                                />
                            );
                        })
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>
        );
    };

    const ClassRow = ({classInfo}) => {
        const [myBool, setmyBool] = React.useState(true);

        function toggleBool() {
            setmyBool(!myBool);
        }

        return (
            <div className="hover:border rounded-full py-0.5 px-3 transition-all duration-100 desktop:hover:shadow-sm">
                <div className="grid grid-cols-4 align-middle">
                    {/* <Link to={`/tassignments/${classInfo.id}`} className="classes-table-content"> */}
                    <div className="classes-table-content">{classInfo.name}</div>
                    {/* </Link> */}
                    <div className="classes-table-content">{classInfo.code}</div>
                    <div className="classes-table-content text-right">{classInfo.num_students}</div>
                    {inOpenToManage && (
                        <MoreButton myBool={myBool} toggleBool={toggleBool} id={classInfo.id}/>
                    )}
                </div>
            </div>
        );
    };

    // ClassTable component
    const ClassTable = ({classes, handleDeleteClass}) => {
        return (
            <div className="min-w-full margin-below-topbar">
                <div className="grid grid-cols-4 gap-4 mb-6 p-2 font-bold border-b border-black ">
                    <div className="classes-column-header">Class</div>
                    <div className="classes-column-header">Class Code</div>
                    <div className="classes-column-header text-right">Number of Students</div>
                    <div className="classes-column-header text-right">Actions</div>
                </div>
                {classes ? (
                    <div>
                        {classes.map((value, i) => (
                            <ClassRow key={`${i} + ${Math.random()}`} classInfo={value}/>
                        ))}
                    </div>
                ) : (
                    <div className="text-center">No classes available</div>
                )}
            </div>
        );
    };

    const [cardView, setCardView] = useState(true); // Changed to camelCase to follow convention

    // Corrected typo in function name
    function toggleCardView() {
        setCardView(!cardView);
    }

    // Your changeView function remains unchanged
    const changeView = ({classes, handleDeleteClass}) => {
        return cardView ? (
            <ClassList classes={classes} handleDeleteClass={handleDeleteClass}/>
        ) : (
            <ClassTable classes={classes} handleDeleteClass={handleDeleteClass}/>
        );
    };

    return (
        <div id="HomeTeacher" className="flex flex-row px-6 justify-start">
            <div id="Main-Content-container" className="flex flex-col space-y-4 w-full">
                <div
                    id="Overview"
                    className="flex flex-row space-x-full justify-between fixed top-0 z-10 bg-white topbar-padding left-side-bar-size right-0 px-6"
                >
                    <div className="flex flex-row items-center justify-center space-x-3 h-topbar-font-box-size">
                        {/* <div>
              <ViewSelectIcon cardView={cardView} toggleCardView={toggleCardView} />
            </div> */}
                        <h1 className="text-black topbar-text-size font-medium">My Classes</h1>
                        <button
                            className="group flex items-center gap-2 px-4 py-2 bg-white rounded-full
                                                text-sm text-gray-700 border border-gray-200 hover:border-orange-200
                                                hover:bg-orange-50 transition-all duration-200"
                            onClick={() => window.open('https://www.loom.com/share/df1070dd6a14461a9fe3be31cc6b3001?sid=c89aca65-34f5-4cea-9de5-03c090dbb6db', '_blank')}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                className="w-4 h-4 text-gray-500 group-hover:text-orange-500 transition-colors"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M10 9v6l5-3-5-3z"/>
                                <circle cx="12" cy="12" r="10"/>
                            </svg>
                            Tutorial
                        </button>
                    </div>

                    <button
                        className="sexy-button transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow h-9 w-28"
                        onClick={() => setIsModalOpen(true)}
                    >
                        New Class
                    </button>
                </div>
                <div>{changeView({classes, handleDeleteClass})}</div>
            </div>
            {isModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50"
                    id="my-modal"
                >
                    <div
                        ref={modalRef}
                        className="sexy-border relative mx-auto p-6 bg-white w-full max-w-[400px]"
                    >
                        <div className="space-y-4">
                            <div className="flex flex-row justify-between">
                                <h2 className="custom-title-sm text-black font-bold">Create New Class</h2>
                                <button
                                    type="button"
                                    className="desktop:hover:opacity-50 transition-all duration-300"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    <img src="/assets/X.png" alt="Exit" className="w-[20px] h-[20px]"/>
                                </button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="space-y-3">
                                    <div className="flex flex-col">
                                        <div
                                            className={`flex items-center input-entry-box bg-white font-normal ${errorMessage !== '' ? 'border-red-500' : ''}`}
                                            onFocus={() => {
                                                setErrorMessage('');
                                            }}
                                        >
                                            <input
                                                type="text"
                                                value={className}
                                                className={`actual-input ml-2.5 mr-2.5 ${errorMessage !== '' ? 'text-red-500' : ''}`}
                                                placeholder="Class Name"
                                                onChange={e => setClassName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/* Additional fields can go here */}
                                </div>
                                <div className="flex flex-col items-center space-y-2 mt-8">
                                    <button
                                        type="submit"
                                        disabled={!className}
                                        className={`px-4 py-3 sexy-button transition-all duration-300 desktop:hover:opacity-90 w-full h-9 text-white flex items-center justify-center ${className ? '' : 'cursor-not-allowed'}`}
                                    >
                                        Create Class
                                    </button>
                                    {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            <ConfirmationModal
                isOpen={isExportConfirmationModalOpen}
                message="Are you sure you want to export this class as a CSV? Download will start automatically."
                onClose={() => setIsExportConfirmationModalOpen(false)}
                onConfirm={confirmExportClass}
            >
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Select score type:</label>
                    <select
                        value={exportScoreType}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        onChange={e => setExportScoreType(e.target.value)}
                    >
                        <option value="percentage">Percentage Scores</option>
                        <option value="raw">Raw Scores</option>
                    </select>
                </div>
            </ConfirmationModal>
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                message="Are you sure you want to delete this class? This action cannot be undone."
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={confirmDeleteClass}
            />
        </div>
    );
}

export default TClasses;
