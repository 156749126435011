export const getFieldName = (index, field, fieldName) => `${fieldName}.${index}.${field}`;

export const updateIds = questions => {
  return questions.map((q, index) => ({
    ...q,
    id: index + 1 // Assign new ID based on position
  }));
};

export const calculateTotalExamScore = examPaper => {
  return examPaper.reduce((totalScore, question) => {
    const questionScore = () => {
      if (question.subQuestions?.length > 0) {
        return 0;
      }
      if (question.questionType === 'mcq') {
        // For MCQ, use the weight directly
        return parseInt(question.weight) || 0;
      } else {
        // For image or text, get max score from rubric items
        const maxRubricScore =
          question.rubricItems?.reduce((max, item) => {
            return Math.max(max, parseInt(item.value) || 0);
          }, 0) || 0;
        return maxRubricScore;
      }
    };

    const subQuestionScore = question.subQuestions.reduce((subTotal, subQuestion) => {
      if (subQuestion.questionType === 'mcq') {
        // For MCQ, use the weight directly
        return subTotal + (parseInt(subQuestion.weight) || 0);
      } else {
        // For image or text, get max score from rubric items
        const maxRubricScore =
          subQuestion.rubricItems?.reduce((max, item) => {
            return Math.max(max, parseInt(item.value) || 0);
          }, 0) || 0;
        return subTotal + maxRubricScore;
      }
    }, 0);

    return totalScore + questionScore() + subQuestionScore;
  }, 0);
};
