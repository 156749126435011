import {ChevronDown, ChevronUp, Info} from 'lucide-react';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import {useQueryParams} from '../../shared/hooks/use-query-params.tsx';
import {addStudents} from '../../store/studentSlice.js';
import {getStudents, removeStudent} from '../../store/teacherSlice.js';
import {GRACE_PERIOD_OPTIONS} from '../../utils/constants.ts';
import {DEFAULT_PAGE_NUMBER, ITEMS_PER_PAGE} from '../../utils/pagination.ts';
import ConfirmationModal from '../ConfirmationModal';
import DragAndDropUpload from '../DragDropCSV';
import Table from '../Table.js';
import ColumnMappingModal from './ColumnMappingModal.js';
import EditableMatchKeyCell from './EditableMatchKeyCell.js';
import {ArrowLeftRight} from 'lucide-react';

const AddDropBig = props => {
    const dispatch = useDispatch();
    const {user} = useAuth();
    const [studentEmails, setStudentEmails] = useState([]);
    const [error, setError] = useState('');
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [csv, setCsv] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const {data, isLoading, errorMessage} = useSelector(state => state?.teacherReducer?.students);
    const [isMatchKeyModalOpen, setIsMatchKeyModalOpen] = useState(false);
    const [isUploadOpen, setIsUploadOpen] = useState(false);
    const [isEmailAddOpen, setIsEmailAddOpen] = useState(false);
    const [gracePeriod, setGracePeriod] = useState(0);
    const [isGracePeriodOpen, setIsGracePeriodOpen] = useState(false);
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [studentErrors, setStudentErrors] = useState(null);

    const isOpenToManage = props.course?.isOpenToManage;

    const {
        params: {search, pageNumber, itemsPerPage},
        updateSearchParams
    } = useQueryParams({
        search: '',
        pageNumber: DEFAULT_PAGE_NUMBER,
        itemsPerPage: ITEMS_PER_PAGE.default
    });

    useEffect(() => {
        getAllStudents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, pageNumber, itemsPerPage]);

    useEffect(() => {
        if (props.course?.grace_period !== undefined) {
            setGracePeriod(props.course.grace_period);
        }
    }, [props.course?.grace_period]);

    useEffect(() => {
        if (errorMessage) {
            setError(errorMessage);
        }
    }, [errorMessage]);

    const getAllStudents = () =>
        dispatch(
            getStudents({
                classId: props.class_id,
                token: user.session_token,
                search,
                pageNumber: Number(pageNumber),
                itemsPerPage: Number(itemsPerPage)
            })
        );

    const handleGracePeriodChange = async minutes => {
        try {
            await gradeApi.patch({
                path: `/api/courses/${props.class_id}/grace-period`,
                body: {gracePeriodMinutes: minutes},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setGracePeriod(minutes);
        } catch (error) {
            console.error('Error updating grace period:', error);
            setError('Failed to update grace period');
        }
    };

    const handleFileChange = file => {
        if (file) {
            setCsv(file);
            setError(''); // Clear any previous errors
        } else {
            setError('No file selected or invalid file type.');
        }
    };

    const handleUpload = async () => {
        if (!csv) {
            setError('Please select a CSV file first.');
            return;
        }

        // Parse CSV headers before opening modal
        try {
            const reader = new FileReader();
            reader.onload = async e => {
                const text = e.target.result;
                const lines = text.split('\n');
                if (lines.length > 0) {
                    // Get headers from first line and clean them
                    const headers = lines[0].split(',');

                    setIsMatchKeyModalOpen(true);
                    // Store headers in state for the modal
                    setCsvHeaders(headers);
                } else {
                    setError('CSV file appears to be empty');
                }
            };
            reader.readAsText(csv);
        } catch (error) {
            console.error('Error reading CSV:', error);
            setError('Failed to read CSV file. Please try again.');
        }
    };

    const handleMatchKeyConfirm = async mappings => {
        setIsMatchKeyModalOpen(false);
        setIsUploading(true);
        setError('');

        const formData = new FormData();
        formData.append('file', csv);
        formData.append('classId', props.class_id);
        formData.append('emailColumn', mappings.emailColumn);
        formData.append('hasNameColumns', String(mappings.hasNameColumns));
        if (mappings.hasNameColumns) {
            formData.append('firstNameColumn', mappings.firstNameColumn);
            formData.append('lastNameColumn', mappings.lastNameColumn);
        }
        formData.append('hasStudentId', String(mappings.hasStudentId));
        if (mappings.hasStudentId) {
            formData.append('studentIdColumn', mappings.studentIdColumn);
        }

        try {
            const response = await gradeApi.post({
                path: '/api/upload-csv/',
                body: formData,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            });

            const {success_count, failed_count, failed_emails} = response.data;

            // Always call getAllStudents() after successful upload
            getAllStudents(); // Make sure to wait for this to complete

            if (failed_count > 0) {
                setError(`Failed to add ${failed_count} student(s): ${failed_emails.join(', ')}`);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Failed to process the CSV file. Please try again.');
        } finally {
            setIsUploading(false);
            setError('CSV upload successful!');
            setCsv(null); // Clear the uploaded file
            window.location.reload();
        }
    };

    const handleMatchKeyDecline = async () => {
        setIsMatchKeyModalOpen(false);
        setError('');
    };

    const handleRemove = async id => {
        setSelectedStudentId(id);
        setIsConfirmationModalOpen(true);
    };

    const handleMatchKeyUpdate = async (studentId, newMatchKey) => {
        try {
            await gradeApi.patch({
                path: `/api/courses/${props.class_id}/students/${studentId}/match-key`,
                body: {match_key: newMatchKey},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            getAllStudents(); // Refresh the table data
        } catch (error) {
            console.error('Error updating match key:', error);
            setError('Failed to update match key');
            throw error;
        }
    };

    const addStudentByEmail = async emails => {
        try {
            const response = await dispatch(
                addStudents({
                    classId: props.class_id,
                    token: user.session_token,
                    emails
                })
            );
            setStudentErrors(response.payload);
            getAllStudents();
            setError('');
        } catch (error) {
            console.error('Error adding student:', error);
            setError('Failed to add student. Please check the email and try again.');
        }
    };

    const confirmRemoveStudent = async () => {
        const deleteUser = await dispatch(
            removeStudent({
                classId: props.class_id,
                token: user.session_token,
                studentId: selectedStudentId
            })
        );

        if (deleteUser.error) {
            setError(deleteUser.payload);
        } else {
            getAllStudents();
        }

        setIsConfirmationModalOpen(false);
    };

    const columnNames = ['Name', 'Email', 'Match Key', 'Status', 'Actions'];

    return (
        <div className="add-drop-container">
            <div className="p-6">
                <div className="mb-4">
                    {isOpenToManage && (
                        <button
                            className="w-full flex items-center gap-2 py-2 text-left hover:bg-gray-50 transition-all duration-200 border-b border-gray-200"
                            onClick={() => setIsEmailAddOpen(!isEmailAddOpen)} // Add this state
                        >
                            {isEmailAddOpen ? (
                                <ChevronUp className="h-5 w-5 text-gray-500"/>
                            ) : (
                                <ChevronDown className="h-5 w-5 text-gray-500"/>
                            )}
                            <span className="text-lg font-medium">Add Student by Email</span>
                        </button>
                    )}

                    {isEmailAddOpen && (
                        <div className="mt-4 p-4 bg-white rounded-lg border border-gray-200">
                            <div className="space-y-4">
                                <div className="flex gap-3">
                                    <div className="relative flex-1">
                                        <input
                                            type="email"
                                            placeholder="Enter student email and press 'enter'"
                                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                            onKeyDown={event => {
                                                const selected = event.target.value;
                                                if (event.key === 'Enter' && !!selected) {
                                                    addStudentByEmail([selected]);
                                                    event.target.value = '';
                                                }
                                            }}
                                        />
                                        <ArrowLeftRight
                                            className="absolute right-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400 transition-colors duration-200 peer-focus:text-orange-500"/>
                                    </div>
                                </div>
                                {/* <SelectedOptionsSection
                  isVisible={studentEmails.length > 0}
                  onRemoveAll={() => {
                    setStudentEmails([]);
                  }}
                >
                  {studentEmails.map((option, index) => (
                    <CustomChip
                      key={`${option}-${index}`}
                      label={option}
                      onRemove={() => {
                        setStudentEmails(prev => prev.filter((_, i) => i !== index));
                      }}
                    />
                  ))}
                </SelectedOptionsSection> */}
                                {error && <p className="text-red-500 text-sm">{error}</p>}
                                {studentErrors?.already_added_emails.length > 0 && (
                                    <>
                                        <p className="text-gray-900 text-m">Emails already added:</p>
                                        {studentErrors.already_added_emails.map(error => (
                                            <span key={Math.random()} className="text-red-500 text-sm">
                        {error}
                      </span>
                                        ))}
                                    </>
                                )}
                                {studentErrors?.not_a_student_emails.length > 0 && (
                                    <>
                                        <p className="text-gray-900 text-m">You cannot add teacher(s) as a student:</p>
                                        {studentErrors.not_a_student_emails.map(error => (
                                            <span key={Math.random()} className="text-red-500 text-sm">
                        {error}
                      </span>
                                        ))}
                                    </>
                                )}
                                {studentErrors?.invalid_emails.length > 0 && (
                                    <>
                                        <p className="text-gray-900 text-m">Emails not found:</p>
                                        {studentErrors.invalid_emails.map(error => (
                                            <span key={Math.random()} className="text-red-500 text-sm">
                        {error}
                      </span>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="mb-4">
                    {isOpenToManage && (
                        <button
                            className="w-full flex items-center gap-2 py-2 text-left hover:bg-gray-50 transition-all duration-200 border-b border-gray-200"
                            onClick={() => setIsUploadOpen(!isUploadOpen)}
                        >
                            {isUploadOpen ? (
                                <ChevronUp className="h-5 w-5 text-gray-500"/>
                            ) : (
                                <ChevronDown className="h-5 w-5 text-gray-500"/>
                            )}
                            <span className="text-lg font-medium">Import Roster</span>
                        </button>
                    )}

                    {isUploadOpen && (
                        <div className="mt-4 p-4 bg-white rounded-lg border border-gray-200">
                            <div className="space-y-4">
                                <div className="flex items-center justify-between text-xs text-gray-600">
                                    <div className="flex items-center">
                                        <span>Canvas Instructions</span>
                                        <a
                                            href="https://athelp.sfsu.edu/hc/en-us/articles/14014800586387-Download-a-list-of-student-email-addresses-in-Canvas"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="ml-1"
                                        >
                                            <Info size={14}/>
                                        </a>
                                    </div>
                                </div>
                                <DragAndDropUpload onFileChange={handleFileChange}/>
                                <div className="flex justify-end">
                                    <button
                                        disabled={isUploading}
                                        className={`px-9 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black ${
                                            isUploading ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                        type="button"
                                        onClick={handleUpload}
                                    >
                                        {isUploading ? 'Uploading...' : 'Upload CSV'}
                                    </button>
                                </div>
                            </div>
                            {error && <p className="text-red-500 mt-4">{error}</p>}
                        </div>
                    )}
                </div>

                <div className="mb-4">
                    <button
                        className="w-full flex items-center gap-2 py-2 text-left hover:bg-gray-50 transition-all duration-200 border-b border-gray-200"
                        onClick={() => setIsGracePeriodOpen(!isGracePeriodOpen)}
                    >
                        {isGracePeriodOpen ? (
                            <ChevronUp className="h-5 w-5 text-gray-500"/>
                        ) : (
                            <ChevronDown className="h-5 w-5 text-gray-500"/>
                        )}
                        <span className="text-lg font-medium">Grace Period Settings</span>
                    </button>
                    <div className="mb-4">
                        {isGracePeriodOpen && (
                            <div className="mt-4 p-4 bg-white rounded-lg border border-gray-200">
                                <div className="space-y-4">
                                    <div className="flex items-center gap-4">
                                        <span className="text-sm text-gray-600">Select Grace Period:</span>
                                        <select
                                            disabled={!isOpenToManage}
                                            value={gracePeriod}
                                            className={`rounded-md border border-gray-300 py-2 text-sm focus:outline-none ${!isOpenToManage ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                                            onChange={e => handleGracePeriodChange(Number(e.target.value))}
                                        >
                                            {GRACE_PERIOD_OPTIONS.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                        Grace period allows students to submit assignments after the deadline without
                                        penalty.
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <Table
                        isLoading={isLoading}
                        totalCount={data?.totalCount}
                        offset={data?.offset}
                        nextOffset={data?.nextOffset}
                        pageNumber={Number(pageNumber)}
                        itemsPerPage={Number(itemsPerPage)}
                        columnNames={columnNames}
                        noDataTitle="No students found"
                        columnBodies={(data?.entities ?? []).map(student => (
                            <tr key={`${student.first_name + ' ' + student.id}`} className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    <div
                                        className="truncate max-w-[30ch]"
                                        title={`${student.first_name} ${student.last_name}`}
                                    >
                                        {`${student.first_name} ${student.last_name}`}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    <div className="truncate max-w-[30ch]" title={student.email}>
                                        {student.email}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm group">
                                    <div className="truncate max-w-[30ch]">
                                        <EditableMatchKeyCell
                                            isOpenToManage={isOpenToManage}
                                            initialValue={student.match_key}
                                            studentId={student.id}
                                            onSave={handleMatchKeyUpdate}
                                        />
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {!student.is_verified ? (
                                        <span
                                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                      No account
                    </span>
                                    ) : (
                                        <span
                                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      Account created
                    </span>
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                                    {isOpenToManage && (
                                        <button
                                            className="text-red-600 hover:text-red-900 focus:outline-none"
                                            onClick={() => handleRemove(student.id)}
                                        >
                                            <img
                                                src="/assets/trash.png"
                                                alt="Remove"
                                                className="w-5 h-5 z-5 opacity-70 hover:opacity-100 inline"
                                            />
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                        updateSearchParams={updateSearchParams}
                    />
                </div>

            </div>

            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                message="Are you sure you want to remove this student? This action cannot be undone."
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={confirmRemoveStudent}
            />

            <ColumnMappingModal
                isOpen={isMatchKeyModalOpen}
                csvColumns={csvHeaders} // Your CSV columns here
                onClose={() => handleMatchKeyDecline(false)}
                onConfirm={mappings => {
                    handleMatchKeyConfirm(mappings);
                }}
            />
        </div>
    );
};

export default AddDropBig;
