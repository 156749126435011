import React, {useState, useMemo, useRef} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export const PDFView = ({url, initialPage = 1, onPageChange}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(initialPage);
    const [scale, setScale] = useState(1);
    const containerRef = useRef(null);

    const options = useMemo(() => ({
        cMapUrl: 'https://unpkg.com/pdfjs-dist@4.4.168/cmaps/',
        cMapPacked: true,
    }), []);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        // Scroll to initial page after document loads
        setTimeout(() => scrollToPage(initialPage), 100);
    }

    const scrollToPage = (pageNum) => {
        if (containerRef.current) {
            const pageElement = containerRef.current.querySelector(`[data-page-number="${pageNum}"]`);
            if (pageElement) {
                pageElement.scrollIntoView({behavior: 'instant', block: 'start'});
            }
        }
    };

    const onPrevPage = () => {
        if (pageNumber <= 1) {
            return;
        }
        const newPage = pageNumber - 1;
        setPageNumber(newPage);
        onPageChange?.(newPage);
        scrollToPage(newPage);
    };

    const onNextPage = () => {
        if (pageNumber >= numPages) {
            return;
        }
        const newPage = pageNumber + 1;
        setPageNumber(newPage);
        onPageChange?.(newPage);
        scrollToPage(newPage);
    };

    const handleScroll = (e) => {
        if (containerRef.current) {
            const container = containerRef.current;
            const pages = container.querySelectorAll('.react-pdf__Page');

            let currentPage = 1;
            let minDistance = Infinity;

            pages.forEach((page, index) => {
                const rect = page.getBoundingClientRect();
                const pageMiddle = rect.top + (rect.height / 2);
                const containerMiddle = window.innerHeight / 2;
                const distance = Math.abs(pageMiddle - containerMiddle);

                if (distance < minDistance) {
                    minDistance = distance;
                    currentPage = index + 1;
                }
            });

            if (currentPage !== pageNumber) {
                setPageNumber(currentPage);
                onPageChange?.(currentPage);
            }
        }
    };

    return (
        <div className="pdf-viewer h-full w-full flex flex-col">
            <div className="pdf-container relative flex-1 overflow-x-hidden bg-gray-200">
                {/* Zoom controls overlay */}
                <div className="absolute top-4 left-1/2 -translate-x-1/2 z-50 flex items-center gap-2">
                    <button
                        className="bg-gray-700/50 hover:bg-gray-600/50 text-white rounded-full p-2 transition-all duration-200"
                        onClick={() => setScale(prev => Math.max(prev - 0.2, 0.2))}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round">
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                    </button>

                    <span className="bg-gray-700/50 text-white px-3 py-1 rounded-full min-w-[80px] text-center">
                        {Math.round(scale * 100)}%
                    </span>

                    <button
                        className="bg-gray-700/50 hover:bg-gray-600/50 text-white rounded-full p-2 transition-all duration-200"
                        onClick={() => setScale(prev => Math.min(prev + 0.2, 3))}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                    </button>
                </div>

                {/* Navigation arrows */}
                <div className="absolute inset-0 pointer-events-none">
                    <div className="relative w-full h-full mx-auto">
                        <button
                            disabled={pageNumber <= 1}
                            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700/50 hover:bg-gray-600/50 disabled:opacity-30 disabled:cursor-not-allowed text-white rounded-full p-1 transition-all duration-200 z-50 pointer-events-auto"
                            onClick={onPrevPage}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <path d="M15 18l-6-6 6-6"/>
                            </svg>
                        </button>

                        <button
                            disabled={pageNumber >= numPages}
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700/50 hover:bg-gray-600/50 disabled:opacity-30 disabled:cursor-not-allowed text-white rounded-full p-1 transition-all duration-200 z-50 pointer-events-auto"
                            onClick={onNextPage}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <path d="M9 18l6-6-6-6"/>
                            </svg>
                        </button>

                        <button
                            className="absolute right-4 bottom-8 bg-gray-700/50 hover:bg-gray-600/50 text-white rounded-full p-3 transition-all duration-200 z-50 pointer-events-auto"
                            title="Download PDF"
                            onClick={() => window.open(url, '_blank')}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                                <polyline points="7 10 12 15 17 10"/>
                                <line x1="12" y1="15" x2="12" y2="3"/>
                            </svg>
                        </button>
                    </div>
                </div>

                {/* PDF content */}
                <div
                    ref={containerRef}
                    className="absolute inset-0 overflow-y-auto"
                    onScroll={handleScroll}
                >
                    <div className={`flex flex-col ${scale === 1 ? 'items-center' : 'items-start'} min-h-full px-4`}>
                        <Document
                            file={url}
                            loading={<div className="text-white">Loading PDF...</div>}
                            error={<div className="text-red-500">Error loading PDF!</div>}
                            options={options}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <div key={`page_${index + 1}`} className="mb-4">
                                    <Page
                                        pageNumber={index + 1}
                                        scale={scale}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={false}
                                        loading={<div className="text-white">Loading page...</div>}
                                    />
                                </div>
                            ))}
                        </Document>
                    </div>
                </div>
            </div>
        </div>
    );
};