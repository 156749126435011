import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {Loading} from '../../components/Loading.js';
import SideBar from '../../components/SideBar';
import AddDropBig from '../../components/Teacher/AddDropBig.js';
import AddDropBigTeachers from '../../components/Teacher/AddDropBigTeacher';

const ClassSettings = () => {
    const {class_id} = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [section, setSection] = useState('students');

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                setLoading(true);
                const response = await gradeApi.get({
                    path: `/api/courses/${class_id}/`
                });
                setCourse(response.data);
            } catch (error) {
                console.error('Error fetching course:', error);
                setError('Failed to load course data');
            } finally {
                setLoading(false);
            }
        };

        fetchCourse();
    }, [class_id]);

    return (
        <div className="flex">
            <div className="w-side-bar-size flex-shrink-0">
                <div className="fixed h-screen">
                    <SideBar activePage="Settings"/>
                </div>
            </div>

            <div className="flex-1">
                <div className="flex items-center space-x-2 p-8 pb-4">
                    <Link to="/tclasses" className="text-black text-lg font-medium">
                        My Classes
                    </Link>
                    <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                    <Link
                        to={`/tclasses/${class_id}/tassignments`}
                        className="text-black text-lg font-medium"
                    >
    <span className="text-black text-lg font-medium">
        {course?.name
            ? (course.name.length > 15
                ? `${course.name.substring(0, 15)}...`
                : course.name)
            : 'Class'}
    </span>
                    </Link>
                    <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                    <Link
                        to={`/tclasses/${class_id}/tassignments`}
                        className="text-black text-lg font-medium"
                    >
    <span className="text-black text-lg font-medium">
        {course?.name
            ? (course.name.length > 15
                ? `${course.name.substring(0, 15)}...`
                : course.name)
            : 'Class'} Settings
    </span>
                    </Link>
                </div>

                <div className="flex justify-between items-center gap-2 p-8 pb-4 pt-4">
                    {/* Left side - Student/Teacher selector */}
                    <div className="flex gap-2">
                        {[
                            {label: 'Students', value: 'students'},
                            {label: 'Teachers', value: 'teachers'}
                        ].map(({label, value}) => (
                            <button
                                key={value}
                                type="button"
                                className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 ${
                                    section === value
                                        ? 'bg-orange-50 border-orange-200 hover:bg-orange-100'
                                        : 'text-slate-700 border-slate-200 hover:border-slate-400'
                                }`}
                                onClick={() => setSection(value)}
                            >
                                {label}
                            </button>
                        ))}
                    </div>

                    {/* Right side - Tutorial button */}
                    <button
                        className="group flex items-center gap-2 px-4 py-2 bg-white rounded-full
            text-sm text-gray-700 border border-gray-200 hover:border-orange-200
            hover:bg-orange-50 transition-all duration-200"
                        onClick={() => window.open('https://www.loom.com/share/60c64e7a7e054364a3a5b80a19b96b88?sid=91d7ee98-bcbc-42fd-b6d2-ca7e22780ff8', '_blank')}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-4 h-4 text-gray-500 group-hover:text-orange-500 transition-colors"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M10 9v6l5-3-5-3z"/>
                            <circle cx="12" cy="12" r="10"/>
                        </svg>
                        Tutorial
                    </button>
                </div>

                <div className="px-8">
                    {error && <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-md">{error}</div>}

                    {loading ? (
                        <Loading/>
                    ) : section === 'students' ? (
                        <>
                            <div className="bg-white rounded-lg border border-gray-200">
                                <AddDropBig class_id={class_id} course={course}/>
                            </div>
                        </>
                    ) : (
                        <div className="bg-white rounded-lg border border-gray-200">
                            <AddDropBigTeachers class_id={class_id} course={course}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ClassSettings;
