import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_WS_PROD : process.env.REACT_APP_API_BASE_WS_DEV;

export const socket = token =>
  io(URL, {
    extraHeaders: {
      Authorization: `Bearer ${token}`
    }
  });
