import React, {useEffect, useState} from 'react';
import {apiUrl, gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';

function RenderBatch({assignment_id, updateTrigger}) {
    const [isExam, setIsExam] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [subUrl, setSubUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [iframeKey, setIframeKey] = useState(Date.now());
    const {user} = useAuth();

    const fetchAssignmentDetails = async () => {
        try {
            // console.log(`Fetching assignment details for ID: ${assignment_id}`);
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/only/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log('Assignment details fetched:', response.data);
            const assignment = response.data;
            setIsExam(assignment.is_exam);
            setIsPublished(assignment.is_assignment_published);
            if (assignment.is_exam) {
                setSubUrl(assignment.url);
                setIframeKey(Date.now());
                // console.log('Sub URL set:', assignment.url);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching assignment details:', error);
            setError('Error fetching assignment details');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAssignmentDetails();
    }, [updateTrigger]);

    if (loading) {
        // console.log('Loading...');
        return <div style={{display: 'none'}}/>;
    }

    if (error) {
        console.error('Error:', error);
        return <div style={{display: 'none'}}/>;
    }

    if (!isExam) {
        // console.log('Not an exam, not rendering PDF.');
        return <div style={{display: 'none'}}/>;
    }

    // console.log('Rendering exam content...');
    return (
        <div className="flex">
            <div className="flex flex-col w-full">
                <h2 className="text-xl font-bold mb-1">Step 2: Print your exam</h2>
                {!isPublished ? (
                    <p className="text-gray-600 italic text-sm">
                        Please publish the assignment before printing the exam. <br/>
                        <br/> Once you publish the assignment, a PDF for your students to fill out will appear
                        below. <br/>
                        <br/> This will NOT make your questions visible to students.
                    </p>
                ) : (
                    <>
                        <p className="text-sm">
                            Here's your exam PDF, generated for each student enrolled in the class!
                            <br/>
                            Print it and pass it out to your students to fill out.
                        </p>
                        <div className="w-full h-full flex flex-col items-center mt-10">
                            {subUrl ? (
                                <iframe
                                    key={iframeKey}
                                    src={`${apiUrl}/api/pdf-file/${subUrl}?mode=view`}
                                    title="PDF Viewer"
                                    frameBorder="0"
                                    className="w-3/5 h-[500px] rounded-xl"
                                />
                            ) : (
                                <p className="text-gray-600 italic text-smallish">
                                    No PDF available. Click generate PDF above.
                                </p>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default RenderBatch;
