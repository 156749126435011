import React, {useState, useEffect} from 'react';

const AIRegrade = ({subQuestion, isEssay, onClose, onSubmit}) => {
    const [localRubricItems, setLocalRubricItems] = useState([]);
    const [solution, setSolution] = useState('');
    const [isSolutionVisible, setIsSolutionVisible] = useState(false);
    const [regradeInstructions, setRegradeInstructions] = useState('');

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    useEffect(() => {
        setLocalRubricItems(JSON.parse(JSON.stringify(subQuestion.rubricItems)));
        setSolution(subQuestion.solution || '');
        setRegradeInstructions(subQuestion.regrade_instructions);
    }, [subQuestion]);

    useEffect(() => {
        const isValid = localRubricItems.length >= 2 &&
            localRubricItems.every(item =>
                item.descriptor.trim() !== '' &&
                Number.isInteger(item.value) &&
                item.value >= 0
            );
        setIsSubmitDisabled(!isValid);
    }, [localRubricItems]);

    const handleRubricChange = (index, field, value) => {
        const newRubricItems = [...localRubricItems];
        if (field === 'value') {
            const numValue = parseInt(value);
            newRubricItems[index][field] = isNaN(numValue) ? '' : numValue;
        } else {
            newRubricItems[index][field] = value;
        }
        setLocalRubricItems(newRubricItems);
    };

    const addRubricItem = () => {
        setLocalRubricItems([...localRubricItems, {descriptor: '', value: ''}]);
    };

    const deleteRubricItem = (index) => {
        const newRubricItems = localRubricItems.filter((_, i) => i !== index);
        setLocalRubricItems(newRubricItems);
    };

    const handleSubmit = () => {
        onSubmit(localRubricItems, regradeInstructions, "", solution);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-xl shadow-lg max-w-3xl w-full max-h-[90vh] flex flex-col">
                <h2 className="text-2xl font-bold mb-4">Fine Tune Grading</h2>

                <div
                    className=" bg-yellow-100 border-l-4 border-yellow-400 p-2 z-10 mb-4">
                    <div className={"flex items-start"}>
                        <div className="flex-shrink-0 mr-2">
                            <img
                                src="/assets/tclasses/warning.png"
                                alt="Warning"
                                className="h-4 w-4 text-yellow-400"
                            />
                        </div>
                        <div className={"text-sm font-semibold"}>
                            <p>• Modifying this rubric will trigger AI regrading of this specific subquestion for all
                                students.</p>
                            <p>• Any manual grades you've given for this subquestion will be <em>OVERWRITTEN</em> by the
                                AI
                                regrading.</p>
                            <p>• For best results, explain exactly how this subquestion should be graded to someone
                                unfamiliar
                                with the course content in the Re-Grading Instructions field.</p>
                        </div>
                    </div>
                </div>

                <div className="flex-grow overflow-y-auto">
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Rubric Items</h3>
                        {localRubricItems.map((item, index) => (
                            <div key={index} className="flex mb-2 items-center">
                                <input
                                    type="text"
                                    value={item.descriptor}
                                    className="sexy-border rounded px-2 py-1 flex-grow mr-2"
                                    placeholder="Descriptor"
                                    onChange={(e) => handleRubricChange(index, 'descriptor', e.target.value)}
                                />
                                <input
                                    type="number"
                                    value={item.value}
                                    className="sexy-border rounded px-2 py-1 w-16 mr-2"
                                    placeholder="Pts"
                                    min="0"
                                    step="1"
                                    onChange={(e) => handleRubricChange(index, 'value', e.target.value)}
                                />
                                <button className="p-1" onClick={() => deleteRubricItem(index)}>
                                    <img src="/assets/trash.png" alt="Delete" className="w-4 h-4"/>
                                </button>
                            </div>
                        ))}
                        <button className="less-sexy-button mt-2" onClick={addRubricItem}>
                            Add Rubric Item
                        </button>
                    </div>

                    <div className="flex flex-col w-full mb-5 mt-4">
                        <div className="text-sm font-medium mb-2">Re-Grading Instructions (optional)</div>
                        <div className="p-4 bg-yellow-50 rounded-lg">
    <textarea
        value={regradeInstructions}
        className="w-full h-32 border border-gray-300 rounded-md px-2 py-1 focus:border-black focus:ring-0 text-black bg-white"
        placeholder="Explain exactly how you want this question graded."
        onChange={(e) => setRegradeInstructions(e.target.value)}
    />
                        </div>

                    </div>
                    <button
                        className="flex items-center text-sm mb-2 mt-2"
                        onClick={() => setIsSolutionVisible(!isSolutionVisible)}
                    >
                        <img
                            src="/assets/back_arrow.png"
                            className={`size-5 opacity-60 transform ${isSolutionVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                            alt="Toggle solution"
                        />
                        Edit Solution (optional)
                    </button>

                    {isSolutionVisible && (
                        <textarea
                            value={solution}
                            className="sexy-border rounded w-full h-32 p-2 text-gray-700"
                            placeholder="Enter the solution..."
                            onChange={(e) => setSolution(e.target.value)}
                        />
                    )}

                </div>

                <div className="flex justify-end space-x-2 mt-4">
                    <button className="less-sexy-button" onClick={onClose}>
                        Cancel
                    </button>
                    <button
                        className={`sexy-button ${isSubmitDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isSubmitDisabled}
                        onClick={handleSubmit}
                    >
                        Confirm and Regrade
                    </button>
                </div>

                {isSubmitDisabled && (
                    <p className="text-red-500 text-sm mt-2">
                        Please ensure there are at least 2 rubric items, all descriptors are filled, and all point
                        values are set to whole, non-negative integers.
                    </p>
                )}
            </div>
        </div>
    );
};

export default AIRegrade;