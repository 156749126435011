import {DndContext, rectIntersection} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {arrayMove} from '@dnd-kit/sortable';
import {Field, Form, Formik} from 'formik';
import {default as React, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {isEmpty, isEqual} from 'lodash';
import {useAuth} from '../../../auth/AuthContext.js';
import {FormikSubmitEffect} from '../TPset/FormikSubmitEffect';

import {
    loadDraftData,
    loadQuestions,
    updateDraftData
} from '../../../store/flexibleAssignmentSlice.js';
import {updateIds} from '../TPset/utils.js';
import {TFlexibleAssignmentQuestionColumn} from './TFlexibleAssignmentQuestionColumn.js';

import 'katex/dist/katex.min.css';
import * as Yup from 'yup';
import {
    defaultGradingInstructions,
    defaultInstructions
} from '../TPset/constants.js';

function TFlexibleAssignment({
                                 assignment_id,
                                 isPublished,
                                 pullQuestions,
                                 setPullQuestions,
                                 setEdited,
                                 setSolRecent,
                                 solRecent,
                                 setTopErrorMessages
                             }) {
    const {user} = useAuth();
    const dispatch = useDispatch();
    const [isGradingInstructionsVisible, setIsGradingInstructionsVisible] = useState(false);
    const [showSavedPopup, setShowSavedPopup] = useState(false);
    const [showErrorMessagePopup, setShowErrorMessagePopup] = useState(false);
    const [lastSaved, setLastSaved] = useState(null);
    const [error, setError] = useState('');
    const [viewedQuestions, setViewedQuestions] = useState(new Map());
    const [copiedRubricItems, setCopiedRubricItems] = useState(null);
    const generateRubricsRef = useRef(null);

    const {
        data: draftData,
        isLoading: isDraftDataLoading,
        errorMessage
    } = useSelector(state => state?.flexibleAssignmentReducer?.draftData);

    const disabledStyle = isPublished || isDraftDataLoading ? '!opacity-65' : '';

    useEffect(() => {
        dispatch(loadDraftData({assignment_id, token: user.session_token}));
    }, []);

    useEffect(() => {
        if (errorMessage) {
            setShowErrorMessagePopup(true);
            setTimeout(() => setShowErrorMessagePopup(false), 2000);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (!draftData.edited) {
            if (!solRecent) {
                setEdited(true);
            }
            setSolRecent(false);
        }
    }, [draftData.examPaper]);

    useEffect(() => {
        if (pullQuestions) {
            dispatch(loadQuestions({assignment_id, token: user.session_token}));
            setPullQuestions(false);
            setEdited(false);
        }
    }, [pullQuestions]);

    const TFlexibleAssignmentSchema = Yup.object().shape({
        examPaper: Yup.array()
            .of(
                Yup.object().shape({
                    question: Yup.string().required('This field is required!'),
                    weight: Yup.number().when('subQuestions', {
                        is: val => val.length < 1,
                        then: () =>
                            Yup.number()
                                .min(1, 'Each MCQ subquestion must have a weight of at least 1.')
                                .required('This field is required!')
                    }),
                    mcqOptions: Yup.array().when(['questionType', 'subQuestions'], {
                        is: (questionType, subQuestions) => questionType === 'mcq' && subQuestions.length < 1,
                        then: () =>
                            Yup.array()
                                .of(
                                    Yup.object().shape({
                                        text: Yup.string().required('Each MCQ option must have text.'),
                                        isCorrect: Yup.boolean().required(
                                            'Each MCQ option must specify if it is correct.'
                                        )
                                    })
                                )
                                .required('MCQ options are required')
                                .min(2, 'Minimum of 2 MCQ options')
                                .test(
                                    'one-correct-answer',
                                    'Each MCQ must have exactly one correct answer.',
                                    options => {
                                        if (!options) {
                                            return false;
                                        }
                                        const correctCount = options.filter(option => option.isCorrect).length;
                                        return correctCount === 1;
                                    }
                                )
                    }),
                    rubricItems: Yup.array().when(['questionType', 'subQuestions'], {
                        is: (questionType, subQuestions) =>
                            subQuestions.length < 1 && (questionType === 'text' || questionType === 'image'),
                        then: () =>
                            Yup.array()
                                .of(
                                    Yup.object().shape({
                                        value: Yup.string().required('Must have a point value.'),
                                        descriptor: Yup.string().required('Must have a descriptor value.')
                                    })
                                )
                                .min(1, 'Minimum of 1 Rubric item')
                                .required('Must have Rubric item')
                    }),
                    subQuestions: Yup.array().of(
                        Yup.object().shape({
                            weight: Yup.number()
                                .min(1, 'Each MCQ subquestion must have a weight of at least 1.')
                                .required('This field is required!'),
                            mcqOptions: Yup.array().when('questionType', {
                                is: val => val === 'mcq',
                                then: () =>
                                    Yup.array()
                                        .of(
                                            Yup.object().shape({
                                                text: Yup.string().required('Each MCQ option must have text.'),
                                                isCorrect: Yup.boolean().required(
                                                    'Each MCQ option must specify if it is correct.'
                                                )
                                            })
                                        )
                                        .required('MCQ options are required')
                                        .min(2, 'Minimum of 2 MCQ options')
                                        .test(
                                            'one-correct-answer',
                                            'Each MCQ must have exactly one correct answer.',
                                            options => {
                                                if (!options) {
                                                    return false;
                                                }
                                                const correctCount = options.filter(option => option.isCorrect).length;
                                                return correctCount === 1;
                                            }
                                        )
                            }),
                            rubricItems: Yup.array().when('questionType', {
                                is: val => val === 'text' || val === 'image',
                                then: () =>
                                    Yup.array()
                                        .of(
                                            Yup.object().shape({
                                                value: Yup.string().required('Must have a point value.'),
                                                descriptor: Yup.string().required('Must have a descriptor value.')
                                            })
                                        )
                                        .min(1, 'Minimum of 1 Rubric item')
                                        .required('Must have Rubric item')
                            })
                        })
                    )
                })
            )
            .min(1, 'Minimum of 1 question')
            .required('Must have question')
    });

    const handleChange = async ({fieldName, value}) => {
        if (!isEqual(draftData[fieldName], value)) {
            console.log(value);
            const result = await dispatch(
                updateDraftData({
                    token: user.session_token,
                    assignment_id,
                    dataToSave: {
                        [fieldName]: value
                    }
                })
            );

            if (!result.error) {
                setLastSaved(new Date());
                setShowSavedPopup(true);
                setTimeout(() => setShowSavedPopup(false), 2000);
                setEdited(true);
            }
        }
    };

    const updateViewedQuestions = id => {
        const expandedQ = new Map(viewedQuestions);
        if (viewedQuestions.has(id)) {
            expandedQ.delete(id);
        } else {
            expandedQ.set(id, []);
        }

        return setViewedQuestions(expandedQ);
    };

    const getTaskPos = id => draftData.examPaper.findIndex(question => question.id === id);

    const handleDragEnd = event => {
        const originalExamPaper = draftData.examPaper;
        const {active, over} = event;

        if (!active || !over || !active.id || !over.id || active.id === over.id) {
            return;
        }

        const getNewExamPaper = examPaper => {
            const originalPos = getTaskPos(active.id);
            const newPos = getTaskPos(over.id);

            if (originalPos === -1 || newPos === -1) {
                console.error('Could not find position for active or over:', {
                    active,
                    over,
                    originalPos,
                    newPos
                });
                return examPaper;
            }

            const updatedPaper = arrayMove(examPaper, originalPos, newPos);
            return updateIds(updatedPaper);
        };

        const newExamPaper = getNewExamPaper(originalExamPaper);
        handleChange({value: newExamPaper, fieldName: 'examPaper'});
    };

    const getInitialValues = useCallback(() => {
        if (!isEmpty(draftData)) {
            return {
                ...draftData,
                instructions: draftData.instructions || defaultInstructions,
                gradingInstructions: draftData.gradingInstructions || defaultGradingInstructions,
                feedbackInstructions: draftData.feedbackInstructions || "detailed",
                showPoints: draftData.showPoints || false,
                allowPregrade: draftData.allowPregrade || false
            };
        }

        return {
            examPaper: [
                {
                    id: 1,
                    questionType: 'text',
                    question: '',
                    solution: '',
                    rubricItems: [
                        {descriptor: 'Fully correct', value: '2'},
                        {descriptor: 'Partially correct', value: '1'},
                        {descriptor: 'Incorrect or no work', value: '0'}
                    ],
                    mcqOptions: [
                        {option: 'A', text: '', isCorrect: false},
                        {option: 'B', text: '', isCorrect: false}
                    ],
                    image: null,
                    weight: 1,
                    subQuestions: []
                }
            ],
            instructions: defaultInstructions,
            created: false,
            edited: false,
            teacherPdfUrl: '',
            gradingInstructions: defaultGradingInstructions,
            feedbackInstructions: "concise",
            showPoints: false,
            allowPregrade: false
        };
    }, [isDraftDataLoading, draftData]);

    const handleShowPointsChange = e => {
        handleChange({
            fieldName: 'showPoints',
            value: e.target.checked
        });
    };

    const handleCopyRubricItems = useCallback(rubricItems => {
        console.log('rubricItems', rubricItems);
        setCopiedRubricItems(rubricItems);
    }, []);

    const handlePasteRubricItems = useCallback(
        (values, setFieldValue) => (questionIndex, subQuestionIndex) => {
            console.log(copiedRubricItems);
            console.log(values.examPaper);
            if (copiedRubricItems) {
                const newExamPaper = values.examPaper.map((question, qIndex) => {
                    console.log(qIndex, questionIndex);
                    if (questionIndex === -1 ? qIndex === 0 : qIndex === questionIndex) {
                        return {
                            ...question,
                            subQuestions: question.subQuestions.map((subQuestion, sqIndex) => {
                                console.log(sqIndex, subQuestionIndex);
                                if (sqIndex === subQuestionIndex) {
                                    return {
                                        ...subQuestion,
                                        rubricItems: [...copiedRubricItems]
                                    };
                                }
                                return subQuestion;
                            })
                        };
                    }
                    return question;
                });
                console.log('newExamPaper', newExamPaper);

                setFieldValue('examPaper', newExamPaper);

                handleChange({fieldName: 'examPaper', value: newExamPaper});
            }
        },
        [copiedRubricItems]
    );

    const handleAllowPregradeChange = e => {
        handleChange({
            fieldName: 'allowPregrade',
            value: e.target.checked
        });
    };

    return (
        <div className={`flex-grow container mx-auto pt-8 pl-4 pr-4 ${disabledStyle}`}>
            <Formik
                enableReinitialize
                initialValues={getInitialValues()}
                validationSchema={TFlexibleAssignmentSchema}
                validateOnChange={true}
                validateOnBlur={true}
                validateOnMount={true}
                onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(true);
                    await handleChange({fieldName: 'examPaper', value: values.examPaper});
                    setSubmitting(false);
                }}
            >
                {({values, setFieldValue, isValid, dirty}) => (
                    <Form>
                        <FormikSubmitEffect
                            onSubmitError={async () => {
                                await handleChange({fieldName: 'examPaper', value: values.examPaper});
                            }}
                        />
                        <div className="relative">
                            <div className="flex flex-col w-full mb-5">
                                <h2 className="text-xl font-bold mb-1">Step 1: Build Assignment</h2>
                                <p className="text-sm mb-4">
                                    Enter the questions, preferably in LaTeX, and, optionally, edit rubrics.
                                    <br/> You can enter solutions manually, or upload a GradeWiz solutions PDF later.
                                </p>
                                <p className="text-sm">Exam Instructions:</p>
                            </div>
                            <div className={`sexy-border rounded-xl mb-4 w-full text-sm h-64 `}>
                                <div className="w-full h-full flex flex-col scroll-auto">
                                    <Field
                                        name="instructions"
                                        as="textarea"
                                        disabled={isPublished}
                                        className={`actual-input p-4 ${disabledStyle}`}
                                        onBlur={e =>
                                            handleChange({
                                                fieldName: 'instructions',
                                                value: e.target.value
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full mb-5 mt-8">
                                <button
                                    type="button"
                                    className="flex items-center text-sm mb-2"
                                    onClick={() => setIsGradingInstructionsVisible(!isGradingInstructionsVisible)}
                                >
                                    <img
                                        src="/assets/back_arrow.png"
                                        className={`size-5 opacity-60 transform ${isGradingInstructionsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                        alt="Toggle grading instructions"
                                    />
                                    Grading Instructions (optional)
                                </button>
                            </div>
                            {isGradingInstructionsVisible && (
                                <div className={`sexy-border rounded-xl mb-4 w-full text-sm h-40 `}>
                                    <div className="w-full h-full flex flex-col scroll-auto">
                                        <Field
                                            name="gradingInstructions"
                                            as="textarea"
                                            disabled={isPublished}
                                            className={`actual-input p-4 ${disabledStyle} text-xs text-gray-500`}
                                            onBlur={e =>
                                                handleChange({
                                                    fieldName: 'gradingInstructions',
                                                    value: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-col w-full mb-5 mt-5">
                                <p className="text-sm mb-2">Feedback Type:</p>
                                <div className="flex items-center gap-4">
                                    <div className="flex gap-2">
                                        {[
                                            {label: 'Concise', value: 'concise'},
                                            {label: 'Detailed', value: 'detailed'}
                                        ].map(({label, value}) => (
                                            <button
                                                key={value}
                                                type="button"
                                                className={`px-4 py-2 rounded-3xl border text-sm font-medium transition-all duration-200 ${
                                                    values.feedbackInstructions === value
                                                        ? 'bg-orange-50 border-orange-200 hover:bg-orange-100'
                                                        : 'text-slate-700 border-slate-200 hover:border-slate-400'
                                                }`}
                                                disabled={isPublished}
                                                onClick={() =>
                                                    handleChange({
                                                        fieldName: 'feedbackInstructions',
                                                        value: value
                                                    })
                                                }
                                            >
                                                {label}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {showSavedPopup && (
                                <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded">
                                    Progress saved
                                </div>
                            )}
                            {showErrorMessagePopup && (
                                <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded">
                                    {errorMessage}
                                </div>
                            )}
                            <div className="flex-col w-full h-full mt-2 mb-4 pr-[25px]">
                                {lastSaved && (
                                    <div className="text-sm text-gray-500 mb-2">
                                        Last saved: {lastSaved.toLocaleTimeString()}
                                    </div>
                                )}

                                <div className="flex justify-between items-center w-full">
                                    {/*                <div className="flex items-center justify-start space-x-4">*/}
                                    {/*                    <div className="flex items-center">*/}
                                    {/*                        <div className="relative inline-block mr-2">*/}
                                    {/*                            <input*/}
                                    {/*                                type="checkbox"*/}
                                    {/*                                id="showPointsCheckbox"*/}
                                    {/*                                checked={values.showPoints}*/}
                                    {/*                                disabled={isPublished}*/}
                                    {/*                                className="sr-only peer"*/}
                                    {/*                                onChange={handleShowPointsChange}*/}
                                    {/*                            />*/}
                                    {/*                            <label*/}
                                    {/*                                htmlFor="showPointsCheckbox"*/}
                                    {/*                                className={`${isPublished ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} */}
                                    {/*    relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out*/}
                                    {/*    ${values.showPoints ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}`}*/}
                                    {/*                            >*/}
                                    {/*      <span*/}
                                    {/*          className={`*/}
                                    {/*    inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out*/}
                                    {/*    ${values.showPoints ? 'translate-x-6' : 'translate-x-1'}*/}
                                    {/*`}*/}
                                    {/*      />*/}
                                    {/*                            </label>*/}
                                    {/*                        </div>*/}
                                    {/*                        <label*/}
                                    {/*                            htmlFor="showPointsCheckbox"*/}
                                    {/*                            className="text-sm font-medium text-gray-700"*/}
                                    {/*                        >*/}
                                    {/*                            Make Weights Visible*/}
                                    {/*                        </label>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}

                                    <div className="flex items-center">
                                        <div className="relative inline-block mr-2">
                                            <input
                                                type="checkbox"
                                                id="allowPregradeCheckbox"
                                                checked={values.allowPregrade}
                                                disabled={isPublished}
                                                className="sr-only peer"
                                                onChange={(e) => {
                                                    handleAllowPregradeChange(e);
                                                    setFieldValue('allowPregrade', !values.allowPregrade)
                                                }}
                                            />
                                            <label
                                                htmlFor="allowPregradeCheckbox"
                                                className={`${isPublished ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} 
relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out
${values.allowPregrade ? 'bg-orange-200 border-orange-300' : 'bg-gray-200 hover:bg-gray-300'}`}
                                            >
<span className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out
${values.allowPregrade ? 'translate-x-6' : 'translate-x-1'}`}
/>
                                            </label>
                                        </div>
                                        <label htmlFor="allowPregradeCheckbox"
                                               className="text-sm font-medium text-gray-700">
                                            Allow Pre-Grading
                                        </label>
                                    </div>

                                    <div>
                                        {!!viewedQuestions.size ? (
                                            <button
                                                type="button"
                                                className="ml-2"
                                                onClick={() => {
                                                    setViewedQuestions(prev => new Map(prev.clear()));
                                                }}
                                            >
                                                <img src="/assets/hide.png" className="size-6" alt="View all"/>
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="ml-2"
                                                onClick={() => {
                                                    const allIds = new Map();
                                                    values.examPaper.map(question => allIds.set(question.id, []));
                                                    setViewedQuestions(allIds);
                                                }}
                                            >
                                                <img src="/assets/view.png" className="size-6" alt="View all"/>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <DndContext
                                collisionDetection={rectIntersection}
                                modifiers={[restrictToVerticalAxis]}
                                onDragEnd={handleDragEnd}
                            >
                                <TFlexibleAssignmentQuestionColumn
                                    fieldName={'examPaper'}
                                    examPaper={values.examPaper}
                                    isPublished={isPublished}
                                    disabledStyle={disabledStyle}
                                    isCreated={values.created}
                                    isDraftDataLoading={isDraftDataLoading}
                                    viewedQuestions={viewedQuestions}
                                    setViewedQuestions={setViewedQuestions}
                                    updateViewedQuestions={updateViewedQuestions}
                                    setError={setError}
                                    error={error}
                                    handleChange={handleChange}
                                    copiedRubricItems={copiedRubricItems}
                                    isValid={isValid}
                                    assignmentId={assignment_id}
                                    setTopErrorMessages={setTopErrorMessages}
                                    generateRubricsRef={generateRubricsRef}
                                    onCopyRubricItems={handleCopyRubricItems}
                                    onPasteRubricItems={handlePasteRubricItems(values, setFieldValue)}
                                />
                            </DndContext>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default TFlexibleAssignment;
